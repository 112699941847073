import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  Box,
  Card,
  Modal,
} from "@mui/material";
import { CiSquareRemove } from "react-icons/ci";
import { IoCloseCircleOutline, IoEyeOutline } from "react-icons/io5";
import ViewAssessment from "../../Assessment/ViewAssessment/ViewAssessment";

function AddAssessment({
  courseid,
  refresh,
  setRefresh,
  handleTriggerAddChapter,
}) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const userrole = userInfo.userrole;

  const [data, setData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState([]);
  const [openViewAssessment, setOpenViewAssessment] = useState(false);
  const [id, setId] = useState("");
  // const [assessmentids, setAssessmentids] = useState([]);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/assessment/get`, {
        companyid,
        userrole,
        email,
        userid
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        console.log(res.data, "all data");
      })
      .catch((err) => console.log(err));

      //Get assessment ids for pre checked data
      axios.post(`https://klms.vliv.app/course/getassessment`, {courseid}) 
      .then((res)=>{
        console.log(res.data.assessment, "get assessment ids")
        setSelectedAssessmentId((res.data.assessment || []).map(Number));

      })
      .catch((err)=>console.log(err))
  }, [refresh]);
// console.log(selectedAssessmentId, "selected assessment id")
  const handleCheckboxChange = (id) => {
    
    if (selectedAssessmentId && selectedAssessmentId.length> 0 && selectedAssessmentId.includes(id)) {
      setSelectedAssessmentId(selectedAssessmentId.filter((aid) => aid !== id));
    } else {
      setSelectedAssessmentId([...selectedAssessmentId, id]);
    }
  };

  const handleViewAssessment = (id) => {
    setId(id);
    setOpenViewAssessment(true);
  };

  const handleSubmit = () => {
    if (!courseid) {
      alert("kindly first add chapters");
    }
    // console.log(selectedAssessmentId, "selectedAssessmentId");
    // console.log(courseid, "courseid");
    axios
      .post(`https://klms.vliv.app/course/addassessment`, {
        assessmentid: selectedAssessmentId,
        courseid,
      })
      .then((res) => {
        alert(res.data.message)
        // console.log(res.data, "assessment add data response");
        setRefresh(!refresh)
        handleTriggerAddChapter()

      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <Box>
        <h2>Assessment</h2>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Topic</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>

                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedAssessmentId && selectedAssessmentId.length> 0 && selectedAssessmentId.includes(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </TableCell>

                  <TableCell>{row.topic}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell onClick={() => handleViewAssessment(row.id)}>
                  <IoEyeOutline style={{width:"18px", height:"18px"}} />

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#37668F",
              color: "#FFFF",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={handleSubmit}
            disabled={!selectedAssessmentId}
          >
            <b>Submit</b>
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "grey",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={handleTriggerAddChapter}
          >
            <b>Cancel</b>
          </Button>
        </Box>
      </Box>

      {/* For view assessment modal*/}
      <Modal
        open={openViewAssessment}
        onClose={() => setOpenViewAssessment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <ViewAssessment
            handleTriggerViewAssessmnet={() => setOpenViewAssessment(false)}
            refresh={refresh}
            setRefresh={setRefresh}
            id={id}
          />{" "}
        </Box>
      </Modal>
    </Container>
  );
}

export default AddAssessment;
