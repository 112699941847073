import Cookies from "js-cookie";

const storedToken = Cookies.get('companytoken');
const storedUserData = Cookies.get('userinfo');
// Initial state
const initialState = {
  token: storedToken,
  userData: storedUserData ? JSON.parse(storedUserData) : {},
  errorMessage: null
};

// Reducer function
const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN_SUCCCESS":
      return {
        ...state,
        token: payload.token,
        userData: payload.userData,
        errorMessage: null,
      };

    case "LOGIN_FAILED":
      return {
        ...state,
        token: null,
        userData: null,
        // errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default loginReducer;
