import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
// import "./Sop.css"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import "./Sop.css";
import { Box, Container, TextField } from "@mui/material";
import Cookies from "js-cookie";

function Sop() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  // console.log(userInfo, "userinfo");

  //const [title, setTitle] = useState('');
  const [showAnswerEditors, setShowAnswerEditors] = useState([]);
  const [editorStates, setEditorStates] = useState([]);
  const [headingValues, setHeadingValues] = useState([]);
  const [durationValues, setDurationValues] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxValues1, setCheckboxValues1] = useState([]);
  const [postData, setPostData] = useState([]); // State to store the post data
  const [steps, setSteps] = useState("");
  const location = useLocation();

  // Rework for KLMS product code
  const [title, setTitle] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleEditorStateChange = (index, editorState) => {
    const updatedEditorStates = [...editorStates];
    updatedEditorStates[index] = editorState;
    setEditorStates(updatedEditorStates);
  };

  const handleEditorBlur = (index) => {
    const updatedEditorStates = [...editorStates];
    updatedEditorStates[index] = editorStates[index]; // Update the editor state with the current state
    setEditorStates(updatedEditorStates);
  };

  const handleOpenEditorForAnswer = () => {
    const newEditorState = EditorState.createEmpty();
    setShowAnswerEditors([...showAnswerEditors, true]);
    setEditorStates([...editorStates, newEditorState]);
    setHeadingValues([...headingValues, ""]);
    setDurationValues([...durationValues, ""]);
    setCheckboxValues([...checkboxValues, false]);
    setCheckboxValues1([...checkboxValues1, false]);
    //setStepCount(index++);
    // setStepCount(stepCount + 1);
  };

  const handleHideEditor = (index) => {
    const updatedShowAnswerEditors = [...showAnswerEditors];
    updatedShowAnswerEditors.splice(index, 1); // Remove the closed part from the array

    const updatedEditorStates = [...editorStates];
    updatedEditorStates.splice(index, 1);

    const updatedHeadingValues = [...headingValues];
    updatedHeadingValues.splice(index, 1);

    const updatedDurationValues = [...durationValues];
    updatedDurationValues.splice(index, 1);

    const updatedCheckboxValues = [...checkboxValues];
    updatedCheckboxValues.splice(index, 1);

    const updatedCheckboxValues1 = [...checkboxValues1];
    updatedCheckboxValues1.splice(index, 1);

    setShowAnswerEditors(updatedShowAnswerEditors);
    setEditorStates(updatedEditorStates);
    setHeadingValues(updatedHeadingValues);
    setDurationValues(updatedDurationValues);
    setCheckboxValues(updatedCheckboxValues);
    setCheckboxValues1(updatedCheckboxValues1);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedShowAnswerEditors = Array.from(showAnswerEditors);
    const [removed] = updatedShowAnswerEditors.splice(result.source.index, 1);
    updatedShowAnswerEditors.splice(result.destination.index, 0, removed);

    const updatedEditorStates = Array.from(editorStates);
    const [removedEditorState] = updatedEditorStates.splice(
      result.source.index,
      1
    );
    updatedEditorStates.splice(result.destination.index, 0, removedEditorState);

    const updatedHeadingValues = Array.from(headingValues);
    const [removedHeadingValue] = updatedHeadingValues.splice(
      result.source.index,
      1
    );
    updatedHeadingValues.splice(
      result.destination.index,
      0,
      removedHeadingValue
    );

    const updatedDurationValues = Array.from(durationValues);
    const [removedDurationValue] = updatedDurationValues.splice(
      result.source.index,
      1
    );
    updatedDurationValues.splice(
      result.destination.index,
      0,
      removedDurationValue
    );

    const updatedCheckboxValues = Array.from(checkboxValues);
    const [removedCheckboxValue] = updatedCheckboxValues.splice(
      result.source.index,
      1
    );
    updatedCheckboxValues.splice(
      result.destination.index,
      0,
      removedCheckboxValue
    );

    const updatedCheckboxValues1 = Array.from(checkboxValues1);
    const [removedCheckboxValue1] = updatedCheckboxValues1.splice(
      result.source.index,
      1
    );
    updatedCheckboxValues1.splice(
      result.destination.index,
      0,
      removedCheckboxValue1
    );

    setShowAnswerEditors(updatedShowAnswerEditors);
    setEditorStates(updatedEditorStates);
    setHeadingValues(updatedHeadingValues);
    setDurationValues(updatedDurationValues);
    setCheckboxValues(updatedCheckboxValues);
    setCheckboxValues1(updatedCheckboxValues1);
  };

  const handleHeadingChange = (index, value) => {
    const updatedHeadingValues = [...headingValues];
    updatedHeadingValues[index] = value;
    setHeadingValues(updatedHeadingValues);
  };

  const handleDurationChange = (index, value) => {
    setSteps(index);
    const updatedDurationValues = [...durationValues];
    updatedDurationValues[index] = value;
    setDurationValues(updatedDurationValues);
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxValues = [...checkboxValues];
    updatedCheckboxValues[index] = !updatedCheckboxValues[index];
    setCheckboxValues(updatedCheckboxValues);
  };

  const handleCheckboxChange1 = (index) => {
    const updatedCheckboxValues1 = [...checkboxValues1];
    updatedCheckboxValues1[index] = !updatedCheckboxValues1[index];
    setCheckboxValues1(updatedCheckboxValues1);
  };

  const handleSubmit = () => {
    const postData = showAnswerEditors.map((showAnswerEditor, index) => {
      return {
        heading: headingValues[index],
        duration: durationValues[index],
        internal: checkboxValues[index],
        external: checkboxValues1[index],
        content: JSON.stringify(
          convertToRaw(editorStates[index].getCurrentContent())
        ),
      };
    });

    const dataValue = {
      sopdata: postData,
      title,
      userid,
      username,
      email,
      companyid,
    };

    console.log(dataValue);
  };

  //for draft

  const handleDraft = () => {
    alert("will work on it");
  };

  return (
    <Container maxWidth="100%">
      <Box>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          value={title}
          onChange={handleTitleChange}
        />
      </Box>
      <Box sx={{ padding: "20px" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ overflow: "hidden" }}
              >
                {showAnswerEditors.map(
                  (showAnswerEditor, index) =>
                    showAnswerEditor && (
                      <Draggable
                        key={index}
                        draggableId={`editor-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              marginTop: "10px",
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div className="completeSopEditor">
                              <h2>Step {index + 1}</h2>
                              <div className="editorBox">
                                <div className="headerInput">
                                  <input
                                    type="text"
                                    placeholder="Heading..."
                                    value={headingValues[index]}
                                    onChange={(e) =>
                                      handleHeadingChange(index, e.target.value)
                                    }
                                  />
                                  <div style={{ marginLeft: "10px" }}>
                                    <span>In Minutes</span>
                                    <input
                                      type="number"
                                      placeholder="Time..."
                                      value={durationValues[index]}
                                      onChange={(e) =>
                                        handleDurationChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="checkbox">
                                    <span>Internal</span>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      required
                                    />
                                    <span>External</span>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues1[index]}
                                      onChange={() =>
                                        handleCheckboxChange1(index)
                                      }
                                      required
                                    />
                                  </div>
                                </div>

                                <div
                                  className="custom-wysiwyg-editor"
                                  style={{ background: "white" }}
                                >
                                  <WysiwygEditor
                                    key={index} // Add key prop
                                    editorState={editorStates[index]}
                                    onEditorStateChange={(editorState) =>
                                      handleEditorStateChange(
                                        index,
                                        editorState
                                      )
                                    }
                                    placeholder="Enter answer content"
                                    onBlur={() => handleEditorBlur(index)} // Add onBlur event handler
                                  />
                                </div>
                              </div>
                              <button
                                onClick={() => handleHideEditor(index)}
                                className="buttonCloseSop"
                              >
                                {" "}
                                X{" "}
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="buttonSop">
          <button
            onClick={handleOpenEditorForAnswer}
            className="buttonAddNewSop"
          >
            {" "}
            + Add New{" "}
          </button>
        </div>
        <div className="draftSubmit">
          <button onClick={handleDraft} className="buttonDraftNewSop">
            {" "}
            Draft{" "}
          </button>
          <button onClick={handleSubmit} className="buttonSubmitNewSop">
            {" "}
            Submit{" "}
          </button>
        </div>
      </Box>
    </Container>
  );
}

export default Sop;
