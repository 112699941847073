import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import CourseUpdateDrawer from "./CourseUpdateDrawer";
import { FaExternalLinkAlt, FaPlus } from "react-icons/fa";
import ChapterDrawer from "./ChapterDrawer";
import { Document, Page } from "react-pdf";
import Cookies from "js-cookie";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChpaterItemDrawer from "./ChpaterItemDrawer";
import ChapterItemViewDrawer from "./ChapterItemViewDrawer";
import { CiClock2, CiVideoOn } from "react-icons/ci";
import { IoPeopleSharp } from "react-icons/io5";
import ViewAssessment from "../Assessment/ViewAssessment/ViewAssessment";
import { MyContext } from "../../../MyContext";
import { LiaCertificateSolid } from "react-icons/lia";
import certificateImage from "../../../assets/certificate.webp";
import vlivlogo from "../../../assets/Vliv logo 1.png";
import AddChaptertabs from "./AddChapter/AddChaptertabs";

function ViewCoursesUser() {
  // Cookies data start

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const companyid = userInfo.companyid;
  const username = userInfo.username;
  const userid = userInfo.userid;
  // console.log(companyid, "company id");

  // Cookies data end

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [chapterData, setchapterData] = useState([]);
  const [bigScrnVideo, setBigScrnVideo] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isItemViewDrawerOpen, setIsItemViewDrawerOpen] = useState(false);
  const [itemViewData, setItemViewData] = useState([]);
  const [itemId, setItemId] = useState(0);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [loading1, setLoading1] = useState(true); // State to track loading status
  const [next, setNext] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [accessScreen, setAccessScreen] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedChapterData, setSelectedChapterData] = useState({
    courseid: "",
    chapter: [],
    username: username,
    email: email,
    companyid: companyid.toString(),
  });

  const [completedChapterData, setCompletedChapterData] = useState([]);
  // console.log(completedChapterData, "completed chapter data tracker");
  // For assessment
  const [openViewAssessment, setOpenViewAssessment] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");
  const [courseTrackData, setCourseTrackData] = useState([]);
  //For useContext states
  const [passFailContext, setPassFailContext] = useState("");
  //For the certificate
  const [certificateData, setCertificateData] = useState({
    certificateurl: "",
    courseid: "",
    coursename: "",
    certificateid: "",
    userid,
    username,
    email,
    companyid,
  });
  const [certificate, setCertificate] = useState([]);
  const [openCertificateView, setOpenCertificateView] = useState(false);

  // This is for chapter drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  // This is for chapter item drawer
  const toggleItemDrawer = (id) => {
    // setIsItemDrawerOpen(!isItemDrawerOpen);
    // console.log(id, "id for item drawer")
    setItemId(id);
  };
  const toggleItemViewDrawer = (itm) => {
    // setIsItemDrawerOpen(!isItemDrawerOpen);
    setIsItemViewDrawerOpen(!isItemViewDrawerOpen);
    setItemViewData(itm);
  };
  //   console.log(id, "id");

  useEffect(() => {
    axios
      .post(`/course/getbyid`, { id })
      .then((res) => {
        // console.log(res.data, "view courses screen");
        setData(res.data);
        setchapterData(res.data[0].chapter);
        setLoading(false);
        setLoading1(false);
      })
      .catch((err) => console.log(err));

    //to get the all course tracker data from the table
    axios
      .post(`/course/getalltrack`, { email, companyid: companyid.toString() })
      .then((res) => {
        setCourseTrackData(res.data.length > 0 && res.data);
      })
      .catch((err) => console.log(err));
  }, [loading]);

  useEffect(() => {
    // Get course tracker and remove the checkbox
    axios
      .post(`/course/gettrack`, { courseid: id })
      .then((res) => {
        // console.log(res.data, "course tracker data");
        const filterData =
          res.data.length > 0
            ? res.data.filter(
                (elem) =>
                  elem.courseid == id &&
                  elem.username == username &&
                  elem.companyid == companyid
              )
            : [];
        setCompletedChapterData(filterData);
        // console.log(filterData, "course tracker filter data");
      })
      .catch((err) => console.log(err));
  }, [loading]);

  // For access the add chapter API
  useEffect(() => {
    // console.log("get access use effect");
    axios
      .post(`https://klms.vliv.app/course/getaccess`, {
        id,
        email,
        companyid: companyid.toString(),
      })
      .then((res) => {
        console.log(res.data, "access get api");
        setAccessScreen(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleNext = () => {
    if (next < chapterData.length - 1) {
      setNext(next + 1);
    } else {
      alert("You don't have a next chapter");
    }
  };

  const handlePrevious = () => {
    if (next > 0) {
      setNext(next - 1);
    } else {
      alert("You don't have a previous chapter");
    }
  };

  const handleChapterData = (chapterData) => {
    // Handle the received data from ChapterDrawer
    // setData((prev) => ({
    //   ...prev,
    //   ...chapterData,
    // }));

    // console.log(chapterData.chapterData[0], "chapterData");
    axios

      .post(`/course/addchapter`, {
        id,
        chapterData: chapterData.chapterData[0],
      })
      .then((res) => {
        alert(res.data.message);
        // console.log(res.data, "course added");
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };

  const handleChapterItemSubmit = (itemData) => {
    // console.log(itemData, itemId, "item data")
    axios
      .post(`/course/additem`, {
        id,
        itemData,
        itemid: itemId,
      })
      .then((res) => {
        alert(res.data.message);
        setLoading(true);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
    // alert('chapter item')
  };
  // For pdf view
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleCheckboxChange = (chapterid, courseid) => {
    setSelectedChapterData((prev) => ({
      ...prev,
      chapter: chapterid,
      courseid: courseid.toString(),
      username: username,
      email: email,
      companyid: companyid.toString(),
    }));
    setIsModalOpen(true); // Open modal on checkbox change
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleComplete = () => {
    // console.log(selectedChapterData);

    axios
      .post(`/course/addtrack`, selectedChapterData)
      .then((res) => {
        alert(res.data.message);
        setLoading(true);
      })
      .catch((err) => console.log(err));
    handleCloseModal();
  };

  const handleOpenAssessment = (id) => {
    setOpenViewAssessment(true);
    setAssessmentId(id);
  };

  // For certificate part
  useEffect(() => {
    axios
      .post("https://klms.vliv.app/certificate/get", {
        userid,
        courseid: id,
      })
      .then((res) => {
        console.log(res.data, "certificate");
        setCertificate(res.data);
      })
      .catch((err) => console.log(err));

    const completionPercent = getCompletionPercentage(id, chapterData);

    if (completionPercent == 100.0) {
      const addCertificate = async () => {
        try {
          const response = await axios.post(
            "https://klms.vliv.app/certificate/check",
            {
              userid,
              courseid: id,
            }
          );

          if (!response.data.exists) {
            if (!data[0].assessment || passFailContext === "pass") {
              await axios.post("https://klms.vliv.app/certificate/add", {
                ...certificateData,
                certificateurl:
                  "https://marketplace.canva.com/EAE7srfpYAo/1/0/1600w/canva-brown-decorative-certificate-of-completion-YAwShCEKfVg.jpg",
                courseid: id,
                coursename: data[0].coursename,
                certificateid: `CRTF-${Math.random()
                  .toString(36)
                  .substr(2, 9)}`,
              });
              alert("You are eligible for the certificate");
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      addCertificate();
    }
  }, [chapterData, passFailContext, data]);

  //To calculate the course completion percentage
  const getCompletionPercentage = (courseId, chapters) => {
    const courseTrack =
      courseTrackData &&
      courseTrackData.length > 0 &&
      courseTrackData.find((track) => track.courseid == courseId);
    if (!courseTrack) return 0;
    // console.log(courseTrack, "crs track")
    const completedChapters = courseTrack.chapter;
    const totalChapters = chapters.length;
    const completedCount = chapters.filter((ch) =>
      completedChapters.includes(ch.id.toString())
    ).length;

    // console.log(completedCount, "completedCount")

    return ((completedCount / totalChapters) * 100).toFixed(2);
  };

  // console.log(certificate, "certificate");

  return (
    <Container maxWidth="100%" sx={{ padding: "10px", width: "100%", background:"#F0F9FF" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          gap: 3,
          paddingTop: "20px",
          height: "90vh",
          // position:"relative"
        }}
      >
        {loading1 ? (
          <Box sx={{ width: "70%" }}>
            {Array.from({ length: 1 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: "70%",
              flex: "1 1 auto",
              overflowY: "auto",
              paddingBottom: "10px",
               background:"#FDFDFD"
            }}
          >
            <Box
              sx={{
                // boxShadow:
                //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                borderRadius: "10px",
                flex: "1 1 auto",
                overflowY: "auto",
                paddingBottom: "10px",
                border: "solid 1px #d4d6d9",
              }}
            >
              {chapterData &&
              chapterData.length > 0 &&
              chapterData[next].fileUrl.includes(".pdf") ? (
                <Box
                  sx={{
                    flex: "1 1 auto",
                    overflowY: "auto",
                    paddingBottom: "10px",
                    maxHeight: "60vh", // Set the maximum height
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                  url={chapterData[next].fileUrl}
                  width="100%"
                >
                  {chapterData[next].fileUrl.includes("https://") ? (
                    <Document
                      style={{
                        padding: "15px",
                        borderRadius: "5px",
                        height: "50px",
                      }}
                      url={chapterData[next].fileUrl}
                      width="100%"
                      file={chapterData[next].fileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  ) : (
                    <Document
                      style={{
                        padding: "15px",
                        borderRadius: "5px",
                        height: "50px",
                      }}
                      url={chapterData[next].fileUrl}
                      width="100%"
                      file={`https://` + chapterData[next].fileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  )}
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                  <Box>
                    <Button onClick={() => setPageNumber(pageNumber - 1)}>
                      prev
                    </Button>
                    <Button onClick={() => setPageNumber(pageNumber + 1)}>
                      next
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    // Disable right click
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ padding: "15px", borderRadius: "5px" }}
                    url={
                      chapterData &&
                      chapterData.length > 0 &&
                      (chapterData[next].fileUrl.startsWith("http")
                        ? chapterData[next].fileUrl
                        : "https://" + chapterData[next].fileUrl)
                      //  if (!imgUrl.startsWith('http')) {
                      //   imgUrl = "https://"+imgUrl
                      // }
                    }
                    width="100%"
                    height="500px"
                    controls
                    // onClick={() => setBigScrnVideo(chapterData.fileUrl)}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              {/* For complete chapter logic */}
              <Typography
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {completedChapterData.length <= 0 ? (
                  <Button
                    sx={{
                      border: "solid 1px grey",
                      backgroundColor: "#FFFF",
                      color: "#252729",
                      width: "100px",
                      borderRadius: "5px",
                    }}
                    onClick={() =>
                      handleCheckboxChange(chapterData[next].id, data[0].id)
                    }
                  >
                    <b>Complete</b>
                  </Button>
                ) : !completedChapterData[0].chapter.includes(
                    chapterData[next].id.toString()
                  ) ? (
                  <Button
                    sx={{
                      border: "solid 1px grey",
                      backgroundColor: "#FFFF",
                      color: "#252729",
                      width: "100px",
                      borderRadius: "5px",
                    }}
                    onClick={() =>
                      handleCheckboxChange(chapterData[next].id, data[0].id)
                    }
                  >
                    <b>Complete</b>
                  </Button>
                ) : (
                  <span style={{ marginRight: "10px", fontSize: "18px" }}>
                    ✔
                  </span> //here is right tick ✔✔✔✔✔✔✔✔✔✔✔✔✔✔✔✔
                )}
              </Typography>

              <Button
                sx={{
                  border: "solid 1px grey",
                  backgroundColor: "#FFFF",
                  color: "#252729",
                  width: "100px",
                  borderRadius: "5px",
                }}
                onClick={handlePrevious}
              >
                <b>Previous</b>
              </Button>
              <Button
                sx={{
                  backgroundColor: "#37668F",
                  "&:hover": {
                    backgroundColor: "#37668F", // Prevent background color change on hover
                  },
                  color: "white",
                  width: "100px",
                  border: "solid 1px grey",
                  borderRadius: "5px",
                }}
                onClick={handleNext}
              >
                <b>Next</b>
              </Button>
            </Box>
            <Box
              sx={{
                padding: "7px",
              }}
            >
              <h2>
                Title:{" "}
                {chapterData &&
                  chapterData.length > 0 &&
                  chapterData[next].title}
              </h2>
              <Typography>
                Description:{" "}
                {chapterData &&
                  chapterData.length > 0 &&
                  chapterData[next].description}
              </Typography>
            </Box>
          </Box>
        )}

        {loading ? (
          <Box
            sx={{
              width: "30%",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={20} />
                  <Skeleton variant="rectangular" height={20} />
                  <Skeleton variant="rectangular" height={20} />
                </Box>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: "30%",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              padding: "10px 10px 0 10px",
              display: "flex",
              flexDirection: "column",
              height: "88vh",
              borderRadius: "10px",
              border: "solid 1px #d4d6d9",
               background:"#FDFDFD"
            }}
          >
            <Box
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                paddingBottom: "10px",
              }}
            >
              {/* will remove in future by react video player */}
              <Card width="100%">
                <img
                  src={data.length > 0 && data[0].thumbnail}
                  style={{
                    width: "100%",
                    height: "300px", // Set a fixed height for the thumbnail
                    objectFit: "cover", // This will crop the image to fit the height
                  }}
                />
              </Card>
              <Box
                sx={{
                  padding: "5px",
                  mt: 1,
                }}
              >
                <h2>{data.length > 0 && data[0].coursename} </h2>
                <Typography sx={{ mt: 0.3 }}>
                  {data.length > 0 && data[0].coursedesction}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                
                  justifyContent: "space-between",
                  padding: "20px 5px 20px 5px",
                  width:"100%"
                  
                  
                }}
              >
                <Typography sx={{ display: "flex", gap: "5px", justifyContent:"center", alignItems:"center" }}>
                  <CiVideoOn
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#37668F",
                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {chapterData && chapterData.length} Chapters
                  </span>
                </Typography>
                {/* For duration commneted */}
                {/* <Typography sx={{ display: "flex", gap: "5px" }}>
                  <CiClock2
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "orange",
                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {data.length > 0 && data[0].couseduration}
                  </span>
                </Typography> */}
                <Typography sx={{ display: "flex", gap: "5px", justifyContent:"center", alignItems:"center" }}>
                  <IoPeopleSharp
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#db6618",
                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {(data.length > 0 &&
                      data[0].assign &&
                      data[0].assign.length) ||
                      0}{" "}
                    Enrolled
                  </span>
                </Typography>
              </Box>
              <hr />

              <Box sx={{ marginTop: "10px" }}>
                {chapterData &&
                  chapterData.length > 0 &&
                  chapterData.map((item, index) => (
                    <Accordion key={index} sx={{ marginTop: "10px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        {/* <Typography>{item.title}</Typography> */}

                        {/* This below functionality basically hide the check box and show the course completion */}
                        <Typography
                          component="div"
                          sx={{ display: "flex", alignItems: "center", fontSize:"12px", fontWeight:"550" }}
                        >
                          {/* {completedChapterData.length <= 0 ? (
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleCheckboxChange(item.id, data[0].id)
                              }
                              style={{ marginRight: "10px" }}
                            />
                          ) : !completedChapterData[0].chapter.includes(
                              item.id.toString()
                            ) ? (
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleCheckboxChange(item.id, data[0].id)
                              }
                              style={{ marginRight: "10px" }}
                            />
                          ) : (
                            <span style={{ marginRight: "10px" }}>✔</span> //here is right tick ✔✔✔✔✔✔✔✔✔✔✔✔✔✔✔✔
                          )} */}
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                        {item.description.substring(0, 35) + "...."}
                        </Typography>
                        {item.itemdata.map((itm, index) => (
                          <Typography key={index}>
                            <Button onClick={() => toggleItemViewDrawer(itm)}>
                              {itm.title}
                            </Button>
                          </Typography>
                        ))}
                        {accessScreen.length > 0 &&
                          accessScreen[0].id == id && (
                            <Button
                              onClick={() => toggleItemDrawer(item.id)}
                              sx={{
                                backgroundColor: "#37668F",
                                "&:hover": {
                                  backgroundColor: "#37668F", // Prevent background color change on hover
                                },
                                color: "white",
                                width: "100px",
                                border: "solid 1px grey",
                                borderRadius: "5px",
                                width: "50%",
                              }}
                            >
                              {" "}
                              <FaPlus />
                              Add Item
                            </Button>
                          )}
                      </AccordionDetails>
                    </Accordion>
                  ))}

                {data[0].assessment &&
                  data[0].assessment.map((item, index) => (
                    <Box
                      sx={{
                        padding: "10px 10px 10px 15px",
                        marginTop: 1.5,
                        borderBottom: "solid 1px #A8A8A8",
                      }}
                      key={index}
                    >
                      <Typography
                        sx={{}}
                        onClick={() => handleOpenAssessment(item)}
                      >
                        Assessment{" "}
                        <FaExternalLinkAlt
                          style={{
                            color: "#37668F",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      </Typography>
                    </Box>
                  ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {certificate && (
                    <Box sx={{ padding: 1, marginLeft: 1, marginTop: 1.5 }}>
                      <Typography onClick={() => setOpenCertificateView(true)}>
                        <LiaCertificateSolid
                          style={{ width: "30px", height: "30px" }}
                        />
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {accessScreen.length > 0 && accessScreen[0].id == id && (
              <Box
                sx={{
                  flexShrink: 0,
                  width: "100%",
                  background: "white",
                  borderTop: "1px solid #ccc",
                  padding: "10px 0",
                  textAlign: "center",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#37668F",
                    "&:hover": {
                      backgroundColor: "#37668F", // Prevent background color change on hover
                    },
                    color: "white",
                    width: "100px",
                    border: "solid 1px grey",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  onClick={toggleDrawer}
                >
                  {" "}
                  <FaPlus />
                  Add Chapter
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* Below the drawer will be open to add the new chapter */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <Container sx={{ width: 650 }}>
          <AddChaptertabs
            courseid={id}
            setRefresh={setRefresh}
            refresh={refresh}
            onChapterSubmit={handleChapterData}
            handleTriggerAddChapter={toggleDrawer}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* This below drawer is for open the drawer item to add the new item */}

      <Drawer anchor="right" open={itemId} onClose={() => setItemId(0)}>
        <Container sx={{ width: 550 }}>
          <ChpaterItemDrawer onChapterItemSubmit={handleChapterItemSubmit} />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      <Drawer
        anchor="right"
        open={isItemViewDrawerOpen}
        onClose={toggleItemViewDrawer}
      >
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 700 }}>
          <ChapterItemViewDrawer data={itemViewData} />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* Modal to confirm action */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm Action
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to complete this chapter?
          </Typography>
          <Button
            onClick={handleComplete}
            sx={{ mt: 2, backgroundColor: "black", color: "white" }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>

      {/* For view assessment modal*/}
      <Modal
        open={openViewAssessment}
        onClose={() => setOpenViewAssessment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "100vh",
            height: "90vh",
          }}
        >
          <MyContext.Provider value={{ passFailContext, setPassFailContext }}>
            <ViewAssessment
              handleTriggerViewAssessmnet={() => setOpenViewAssessment(false)}
              refresh={refresh}
              setRefresh={setRefresh}
              id={assessmentId}
            />{" "}
          </MyContext.Provider>
        </Box>
      </Modal>

      {/* To view the certificates */}

      <Modal
        open={openCertificateView}
        onClose={() => setOpenCertificateView(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "100vh",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${certificateImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 10,
                mr: 5,
              }}
            >
              <img src={vlivlogo} width="100" height="30" />
            </Box>
            <Box sx={{ padding: 10, mt: -8 }}>
              <Typography sx={{ fontSize: "22px" }}>
                Certificate Of Complition
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                Congratulation, {username}
              </Typography>
              <Typography sx={{ fontSize: "26px", fontWeight: "bold", mt: 4 }}>
                {data.length > 0 && data[0].coursename}
              </Typography>
              <Typography sx={{}}>
                Course completed on{" "}
                {new Date(
                  certificate && certificate.createdAt && certificate.createdAt
                ).toDateString()}
              </Typography>
              <Typography sx={{ mt: 3, fontSize: "14px" }}>
                By continuing to learn, you have expanded your perspective,
                sharpened your skills, and made yourself even more in demand.
              </Typography>

              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontWeight: "bold" }}>Signatory</Typography>
                <Typography>{username}</Typography>
              </Box>
              <Box sx={{ mt: 7 }}>
                <Typography sx={{}}>
                  Certificate Id -{" "}
                  {certificate &&
                    certificate.certificateid &&
                    certificate.certificateid}
                </Typography>
                <Typography sx={{}}>
                  This is auto generated certificate
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <img
      src={
        certificate &&
        certificate.certificateurl &&
        certificate.certificateurl.length > 0 &&
        certificate.certificateurl[0]
      }
      style={{ width: "100%", height: "100%" }}
    /> */}
        </Box>
      </Modal>
    </Container>
  );
}

export default ViewCoursesUser;
