import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import UploadVideoDrawer from "../../../Videos/UploadVideoDrawer";
import Cookies from "js-cookie";
import AddChaptertabs from "../AddChapter/AddChaptertabs";
import { useNavigate } from "react-router-dom";

function CourseDrawer({ refresh, setRefresh, toggleDrawerC }) {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const companyname = userInfo.companyname;
  const userid = userInfo.userid;
  const username = userInfo.username;
  const companyemail = userInfo.companyemail;

  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [data, setData] = useState({
    vid: "",
    coursename: "",
    couseduration: "",
    coursedesction: "",
    thumbnail: "",
    category: "",
    tag: [],
    user: username,
    userid: userid,
    company: companyname,
    companyid: companyid,
  });
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [tabValue, setTabValue] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(()=>{
    axios.post('https://klms.vliv.app/master/get', {companyid})
    .then((res)=>{
      setCategoryData(res.data)
    })
    .catch((err)=>console.log(err))
  },[])

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setData((prev) => ({
          ...prev,
          tag: [...tags, newTag],
        }));
      }
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
    setData((prev) => ({
      ...prev,
      tag: tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleCheckboxChange = (videoId) => {
    const isSelected = selectedVideos.includes(videoId);
    if (isSelected) {
      setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
      const checkbox = selectedVideos.filter((id) => id !== videoId);
      setData((prev) => ({
        ...prev,
        vid: checkbox,
      }));
    } else {
      setSelectedVideos([...selectedVideos, videoId]);
      const checkbox = [...selectedVideos, videoId];
      setData((prev) => ({
        ...prev,
        vid: checkbox,
      }));
    }
  };

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      return;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    //  console.log("course thumbnail")

    axios
      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          thumbnail: res.data.file,
        }));
        setLoading(false);
        console.log(res.data.file, "for course thumbnail");

        //To add size file inside storage table
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e, name) => {
    setData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (
      data.coursename === "" ||
      data.coursedesction === "" ||
      data.thumbnail === "" ||
      data.category === ""
    ) {
      return alert("You have missing fields");
    }
    axios
      .post(`/course/add`, data)
      .then((res) => {
        alert(res.data.message);
        // toggleDrawerC();
        setRefresh(!refresh);
        navigate(`/lms/admin/courses/view/${res.data.id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleChapterData = (chapterData) => {
    setData((prev) => ({
      ...prev,
      ...chapterData,
    }));
    if (chapterData) {
      setIsDrawerOpen(!isDrawerOpen);
    }
  };

  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Box>
        <Typography
          variant="h5"
          sx={{
            marginBottom: "10px",
            color: "#37668F",
            fontWeight: "bold",
            padding: "5px 35px 0px 45px",
            // backgroundColor: "#F2F4F7",
            width: "100%",
          }}
        >
          Add Course
        </Typography>
        <Divider />
      </Box>

      <Container
        sx={{
          // padding: "5px 30px 5px 30px",
          margin: "5px 20px 5px 20px",
          borderRadius: "8px",
          width: 600,
          alignItems: "center",
        }}
      >
        <>
          <Box
            sx={{
              backgroundColor: "#F2F4F7",
              padding: "5px 20px 5px 20px",
              borderRadius: "5px",
              height: 600,
              mt: 3,
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="coursename"
              label="Course name"
              name="coursename"
              value={data.coursename}
              onChange={(e) => handleChange(e, "coursename")}
              sx={{ marginBottom: "8px", background: "#FFFF"  }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="coursedesction"
              label="Description"
              name="coursedesction"
              multiline
              rows={4}
              value={data.coursedesction}
              onChange={(e) => handleChange(e, "coursedesction")}
              sx={{ marginBottom: "8px", background: "#FFFF"  }}
            />
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={data.category}
                onChange={(e) => handleChange(e, "category")}
                label="Category"
                sx={{ marginBottom: "8px", background: "#FFFF"  }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              { categoryData.length>0 && categoryData.map((item, index)=>(
                <MenuItem value={item.category}>{item.category}</MenuItem>
              ))}
                
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="tag"
              label="Tag"
              name="tag"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyPress={handleTagInputKeyPress}
              // helperText="Press Enter or ',' to add a tag"
              sx={{ marginBottom: "4px", background: "#FFFF" }}
            />
            <Typography
              color="textSecondary"
              sx={{ marginBottom: "8px", ml: 0.5 }}
            >
              Press Enter or ',' to add a tag
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              sx={{ marginBottom: "8px" }}
            >
              {tags.length > 0 &&
                tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={handleDeleteTag(tag)}
                    color="primary"
                    sx={{ margin: 0.5, background:"#37668F" }}
                  />
                ))}
            </Stack>

            <Typography
              color="textSecondary"
              sx={{ marginBottom: "8px", ml: 0.5 }}
            >
            Upload Thumbnail
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 4,
                alignItems: "center",
                marginBottom: "8px",
                ml: 0.5
              }}
            >
               
              <FormControl sx={{ margin: "13px 0px" }} required>
                {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="video-file1"
                  type="file"
                  onChange={handleFileChange1}
                />

                <label htmlFor="video-file1">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ background: "#37668F",  mr: 5 }}
                  >
                    Upload
                  </Button>
                </label>
              </FormControl>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginLeft: "18px", flexGrow: 2, fontSize: "1rem" }}
              >
                Upload a picture that shows what's in your video. A good
                thumbnail stands out and draws viewer's attention.
              </Typography>
            </Box>

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              data.thumbnail && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <img
                    src={data.thumbnail}
                    width="220"
                    height="100"
                    style={{ borderRadius: "10px" }}
                  />
                </Box>
              )
            )}
          </Box>
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Button
            variant="outlined"
            onClick={toggleDrawer}
            sx={{ color: "#1976d2", borderColor: "#1976d2", marginY: "4px" }}
          >
            + Add Chapter
          </Button>
        </Box> */}
          <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
            <Container sx={{ width: 550 }}>
              <AddChaptertabs
                setRefresh={setRefresh}
                refresh={refresh}
                onChapterSubmit={handleChapterData}
                handleTriggerAddChapter={toggleDrawer}
              />
            </Container>
          </Drawer>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button onClick={toggleDrawerC} className="cancelBtn2">
              Cancel
            </Button>
            <Button
              variant="contained"
              className="saveBtn2"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </>
      </Container>
    </Box>
  );
}

export default CourseDrawer;
