import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  CircularProgress,
  Stack,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import Cookies from "js-cookie";

function ChpaterItemDrawer({ onChapterItemSubmit }) {



  const [refresh, setRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Item" />
        {/* <Tab label="Assessment" /> */}
      </Tabs>
      <Box
        sx={{
          padding: 0.5,
          backgroundColor: "#f5f8fa",
          height: "75vh",
          borderRadius: "5px",
        }}
      >
        {selectedTab === 0 && (
          <AddItems onChapterItemSubmit={onChapterItemSubmit} />
        )}
        {selectedTab === 1 && <AddAssessments />}
      </Box>
    </Box>
  );
}

const AddItems = ({ onChapterItemSubmit }) => {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;

  const [title, setTitle] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleFileChange = (event) => {
    setUploadBtnLoad(true);
    // setSelectedFile(e.target.files[0]);

    const file = event.target.files[0];

    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploadBtnLoad(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]); // Assuming input type is file and accepts only one image

    axios

      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFileUrl(res.data.file);
        console.log(res.data.file, "inside fileChange response");
        setUploadBtnLoad(false);
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onChapterItemSubmit({
      itemData: [
        {
          title,
          fileUrl,
        },
      ],
    });
  };

  return (
    <Box>
      <Typography component="h1" variant="h5">
        Add Chapter Item
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          value={title}
          onChange={handleTitleChange}
        />

        {uploadBtnLoad ? (
          <CircularProgress />
        ) : (
          <FormControl fullWidth margin="normal" required>
            {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

            <input
              // accept="video/*"
              style={{ display: "none" }}
              id="video-file"
              type="file"
              onChange={handleFileChange}
            />

            <label htmlFor="video-file">
              <Button variant="contained" color="primary" component="span">
                Upload File
              </Button>
            </label>
          </FormControl>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={uploadBtnLoad}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

const AddAssessments = () => {
  return (
    <>
      <p>Will work on that</p>
    </>
  );
};
export default ChpaterItemDrawer;
