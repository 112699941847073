import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import axios from "axios";
import { useContext } from "react";
import { MyContext } from "../../../../MyContext";

function ViewAssessment({
  handleTriggerViewAssessmnet,
  setRefresh,
  refresh,
  id,
}) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const userrole = userInfo.userrole;

  //Use context to send pass faild data to view course user
  const { passFailContext, setPassFailContext } = useContext(MyContext);
  const { complianceid, setComplianceid } = useContext(MyContext);
  console.log(complianceid, "compliance id inside view assessment");

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [data, setData] = useState({});
  const [questionData, setQuestionData] = useState([]);
  const [correctAns, setCorrectAns] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [answerType, setAnswerType] = useState([]);
  const [ansFrmScoreTable, setAnsFrmScoreTable] = useState([]);
  const [isPass, setIsPass] = useState(false);
  const [passFail, setPassFail] = useState("");
  const [attempts, setAttempts] = useState("");

  useEffect(() => {
    if (questionData.length > 0) {
      questionData.forEach((question, index) => {
        axios
          .post(`https://klms.vliv.app/assessment/getscore`, {
            assessmentid: question.id,
            email,
            companyid,
          })
          .then((res) => {
            if (res.data.length > 0) {
              setDisabled(true);
            }

            setAnswerType((prev) => [
              ...prev,
              {
                answertype: res.data[0]?.answertype,
                assessmentid: res.data[0]?.assessmentid,
              },
            ]);

            setAnsFrmScoreTable((prev) => [
              ...prev,
              {
                ans: res.data[0]?.ans,
                assessmentid: res.data[0]?.assessmentid,
              },
            ]);

            console.log(res.data, "score get data", index);
          })
          .catch((err) => console.log(err));
      });
    }
  }, [questionData]);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/assessment/getbyid`, { id })
      .then((res) => {
        console.log(res.data, "assessment get by id");
        setData(res.data);
        const parsedQuestions = res.data.questions.map((q) => JSON.parse(q));
        setQuestionData(parsedQuestions);
        setCorrectAns(new Array(parsedQuestions.length).fill(undefined));
      })
      .catch((err) => console.log(err));

    //Pass Fail api get
    axios
      .post(`https://klms.vliv.app/assessment/getpass`, {
        assessmentid: id,
        userid,
      })
      .then((res) => {
        setPassFail(res.data.length > 0 && res.data[0].passfail);
        setAttempts(res.data.length > 0 && res.data[0].attempts)
        if (userrole != "masteradmin" && userrole != "HR" && userrole != "Manager") {
          setPassFailContext(res.data.length > 0 && res.data[0].passfail);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleRadioChange = (index, value) => {
    const newCorrectAns = [...correctAns];
    newCorrectAns[index] = value;
    setCorrectAns(newCorrectAns);
  };

  // To get pass or fails
  const passingPercent = parseFloat(
    data && data.passingpercent && data.passingpercent
  ); // Convert passingpercent to a number
  const passingThreshold = Math.ceil(
    (passingPercent / 100) * questionData.length
  );

  const handleSubmit = () => {
    if (correctAns.includes(undefined)) {
      alert("Kindly choose all options");
    } else {
      let correctCount = 0;

      questionData.forEach((question, index) => {
        const answertype =
          correctAns[index] === question.correctans ? "correct" : "wrong";

        if (answertype === "correct") {
          correctCount++;
        }

        if (userrole !== "masteradmin") {
          axios
            .post(`https://klms.vliv.app/assessment/addscore`, {
              assessmentid: question.id,
              answertype,
              companyid,
              userid,
              username,
              email,
              ans: correctAns[index],
            })
            .then((res) => {
              console.log(res.data, "score data");
              setRefresh(!refresh);
              handleTriggerViewAssessmnet();
            })
            .catch((err) => console.log(err));

          // alert("not master")
        }
      });

      // Determine pass or fail
      if (correctCount >= passingThreshold) {
        setIsPass(true);
        axios
          .post(`https://klms.vliv.app/assessment/addpass`, {
            passfail: "pass",
            assessmentid: id,
            companyid,
            userid,
            username,
            email,
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => console.log(err));

        // Add pass inside compliance pass column
        if (complianceid) {
          axios
            .post(`https://klms.vliv.app/compliance/pass`, {
              email,
              id: complianceid,
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        }
      } else {
        setIsPass(false);
        axios
          .post(`https://klms.vliv.app/assessment/addpass`, {
            passfail: "fail",
            assessmentid: id,
            companyid,
            userid,
            username,
            email,
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => console.log(err));

        // Add fail inside compliance pass column
        if (complianceid) {
          axios
            .post(`https://klms.vliv.app/compliance/fail`, {
              email,
              id: complianceid,
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        }
      }

      // To add how many users have completed
      axios
        .post(`https://klms.vliv.app/assessment/completedby`, { id, email })
        .then((res) => {
          console.log(res.data, "completed by");
        })
        .catch((err) => console.log(err));

      alert("Completed");
    }
  };

  // console.log(answerType, ansFrmScoreTable, "answerType", "ansFrmScoreTable");

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ padding: "5px", display: "flex", justifyContent: "flex-end" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginRight: "-2vw",
            marginTop: "-2vw",
          }}
          onClick={handleTriggerViewAssessmnet}
        />
      </Box>
      <Box
        sx={{
          padding: 0.5,
          backgroundColor: "#f5f8fa",
          height: "75vh",
          borderRadius: "5px",
        }}
      >
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label={data && data.type} />
        </Tabs>
        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Box>
            <h2>Topic</h2>
            <h3
              style={{
                marginBottom: 2,
                width: "100%",
                mt: 1,
              }}
            >
              {data && data.topic}
            </h3>
          </Box>

          {userrole != "masteradmin" && (
            <Box>
              <Typography>
                Score:{" "}
                {answerType.filter((elem) => {
                  return elem.answertype == "correct";
                }).length +
                  "/" +
                  answerType.length}
              </Typography>

              {passFail == "pass" ? (
                <Typography style={{ color: "green" }}>Pass</Typography>
              ) : passFail == "fail" ? (
                <Typography style={{ color: "red" }}>Fail</Typography>
              ) : (
                <Typography></Typography>
              )}

              <Typography>Attempts- {+attempts}/3</Typography>
              
            </Box>
          )}
        </Box>
        <Box
          sx={{
            marginTop: -1.5,
            height: "58vh",
            overflowY: "auto",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: 2,
            borderRadius: 3,
          }}
        >
          {questionData.map((question, index) => (
            <Box key={index}>
              <Box sx={{ marginBottom: 1, width: "100%" }}>
                {/* <h2>Question</h2> */}
                <h3
                  style={{
                    marginBottom: 2,
                    width: "100%",
                    mt: 1,
                  }}
                >
                  Q. {question.question}
                </h3>
              </Box>

              {passFail == "pass" ? (
                <RadioGroup
                  aria-label="options"
                  value={correctAns[index]}
                  onChange={(e) => handleRadioChange(index, e.target.value)}
                >
                  <FormControlLabel
                    value="ansa"
                    control={<Radio />}
                    label={question.ansa}
                    disabled={passFail == "pass"}
                    checked={
                      ansFrmScoreTable.find(
                        (item) => item.assessmentid == question.id
                      )?.ans == "ansa"
                    }
                  />
                  <FormControlLabel
                    value="ansb"
                    control={<Radio />}
                    label={question.ansb}
                    disabled={passFail == "pass"}
                    checked={
                      ansFrmScoreTable.find(
                        (item) => item.assessmentid == question.id
                      )?.ans == "ansb"
                    }
                  />
                  <FormControlLabel
                    value="ansc"
                    control={<Radio />}
                    label={question.ansc}
                    disabled={passFail == "pass"}
                    checked={
                      ansFrmScoreTable.find(
                        (item) => item.assessmentid == question.id
                      )?.ans == "ansc"
                    }
                  />
                  <FormControlLabel
                    value="ansd"
                    control={<Radio />}
                    label={question.ansd}
                    disabled={passFail == "pass"}
                    checked={
                      ansFrmScoreTable.find(
                        (item) => item.assessmentid == question.id
                      )?.ans == "ansd"
                    }
                  />
                </RadioGroup>
              ) : (
                <RadioGroup
                  aria-label="options"
                  value={correctAns[index]}
                  onChange={(e) => handleRadioChange(index, e.target.value)}
                >
                  <FormControlLabel
                    value="ansa"
                    control={<Radio />}
                    label={question.ansa}
                    checked={correctAns[index] === "ansa"}
                  />
                  <FormControlLabel
                    value="ansb"
                    control={<Radio />}
                    label={question.ansb}
                    checked={correctAns[index] === "ansb"}
                  />
                  <FormControlLabel
                    value="ansc"
                    control={<Radio />}
                    label={question.ansc}
                    checked={correctAns[index] === "ansc"}
                  />
                  <FormControlLabel
                    value="ansd"
                    control={<Radio />}
                    label={question.ansd}
                    checked={correctAns[index] === "ansd"}
                  />
                </RadioGroup>
              )}
              {answerType.find((item) => item.assessmentid == question.id)
                ?.answertype === "correct" && (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Typography>
                    Answer Type:{" "}
                    <b
                      style={
                        answerType.find(
                          (item) => item.assessmentid == question.id
                        )?.answertype === "correct" && { color: "green" }
                      }
                    >
                      {
                        answerType.find(
                          (item) => item.assessmentid == question.id
                        )?.answertype
                      }
                    </b>
                  </Typography>
                  {/* {answerType.find((item) => item.assessmentid == question.id)
                    ?.answertype === "wrong" && (
                    <Typography>
                      Correct Answer:{" "}
                      <b>
                        {question.correctans === "ansa"
                          ? "Option A"
                          : question.correctans === "ansb"
                          ? "Option B"
                          : question.correctans === "ansc"
                          ? "Option C"
                          : question.correctans === "ansd"
                          ? "Option D"
                          : ""}
                      </b>
                    </Typography>
                  )} */}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-end",
          padding: 1,
        }}
      >
        {userrole != "masteradmin" && (
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#37668F",
              color: "#FFFF",
              width: "100px",
              borderRadius: "5px",
              marginTop: 2,
            }}
            onClick={handleSubmit}
            disabled={passFail == "pass" || attempts == 3}
          >
            <b>Submit</b>
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ViewAssessment;
