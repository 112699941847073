import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Sop from "../../../SOP/Sop";
import TextEditor from "../TextEditor";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../MyContributes.css";
import { CiCloud } from "react-icons/ci";
import Cookies from "js-cookie";
import axios from "axios";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import JoditEditor from "jodit-react";

function CreateContribute({
  handleTriggerCreateContribute,
  setRefresh,
  refresh,
  promptValue,
  setPromptvalue,
}) {
  const editor = useRef(null);

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;

  const [data, setData] = useState({
    topic: "",
    module: "",
    category: "",
    content: "",
    videourl: "",
    fileurl: "",
    access: [],
    status: "Pending",
    department,
    companyid,
    userid,
    username,
    email,
  });

  const [moduleData, setModuleData] = useState([]);
  const [categoryData, setCategoryData] = useState([
    { value: "SOP", label: "SOP" },
    { value: "FAQ", label: "FAQ" },
    { value: "GUIDES", label: "GUIDES" },
  ]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);
  const [fileName, setFileName] = useState(""); // State variable for file name
  const [faqData, setFaqData] = useState([
    {
      question: "",
      answer: "",
      topic: "",
      topicid: "",
      module: "",
      companyid,
      userid,
      username,
      email,
    },
  ]);

  // For auto insert in text editor
  useEffect(() => {
    // const contentState = ContentState.createFromText(promptValue);
    // const newEditorState = EditorState.createWithContent(contentState);
    // setEditorState(newEditorState);
    // const rawContent = convertToRaw(contentState);
    setData((prevData) => ({
      ...prevData,
      content: promptValue,
    }));
  }, [promptValue]);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/getmodule`, {
        department,
        companyid,
      })
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        setModuleData(data);
      })
      .catch((err) => console.log(err));
  }, [department]);

  const handleModuleSelectChange = (selectedOption) => {
    setData((prevData) => ({
      ...prevData,
      module: selectedOption.value,
    }));
  };

  const handleCategorySelectChange = (selectedOption) => {
    setData((prevData) => ({
      ...prevData,
      category: selectedOption.value,
    }));
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    setData((prevData) => ({
      ...prevData,
      content: rawContent,
    }));
  };

  const handleDescriptionChange = (newContent) => {
    setData((prevValues) => ({
      ...prevValues,
      content: newContent,
    }));
  };

  const handleFaqEditorStateChange = (index, newEditorState) => {
    setFaqData((prevFaqData) => {
      const newFaqData = [...prevFaqData];
      newFaqData[index].answer = newEditorState;
      return newFaqData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFaqInputChange = (index, e) => {
    const { name, value } = e.target;
    setFaqData((prevFaqData) => {
      const newFaqData = [...prevFaqData];
      newFaqData[index][name] = value;
      return newFaqData;
    });
  };

  const handleFileChange = (event) => {
    setUploadBtnLoad(true);
    const file = event.target.files[0];
    setFileName(file.name);

    // For get the GB and add the limit
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB
    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploadBtnLoad(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          fileurl: res.data.file,
        }));
        setUploadBtnLoad(false);

        //Add GB data in storageplan table
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(contentState));
    const faqContent = faqData.map((faq) => ({
      question: faq.question,
      answer: faq.answer,
      topic: "",
      topicid: "",
      module: "",
      companyid,
      userid,
      username,
      email,
    }));
    const submitData = {
      ...data,
      // content: rawContent,
      faqContent,
    };

    console.log(submitData, "submitData");

    if (data.topic === "" && data.module === "" && data.category === "") {
      return alert("You have missing Fields");
    } else if (data.topic === "") {
      return alert("You have missing Topic");
    } else if (data.module === "") {
      return alert("You have missing module");
    } else if (data.category === "") {
      return alert("You have missing category");
    }

    axios
      .post(`https://klms.vliv.app/contribution/add`, submitData)
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
        handleTriggerCreateContribute();
        // console.log(res.data, "add respose")

        // console.log({...faqData}, "faqData")
      })
      .catch((err) => console.log(err));
  };

  const handleAddFaq = () => {
    setFaqData((prevFaqData) => [
      ...prevFaqData,
      { question: "", answer: "" },
    ]);
  };

  const handleRemoveFaq = (index) => {
    setFaqData((prevFaqData) => prevFaqData.filter((_, i) => i !== index));
  };

  return (
    <Container sx={{ width: "100%" }}>
      <Box sx={{ marginTop: 1, maxHeight: "65vh", overflowY: "auto" }}>
        <Box sx={{ marginBottom: 1, width: "50%" }}>
          <label style={{ marginBottom: 1 }}>Repo</label>
          <Select
            options={moduleData}
            onChange={handleModuleSelectChange}
            placeholder="Repository"
          />
        </Box>

        <Box sx={{ marginBottom: 1, width: "50%" }}>
          <label style={{ marginBottom: 1 }}>Category</label>
          <Select
            options={categoryData}
            onChange={handleCategorySelectChange}
            placeholder="Category"
            styles={{ height: "30px" }}
          />
        </Box>

        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label>Topic</label>
          <TextField
            placeholder="Topic"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
            name="topic"
            value={data.topic}
            onChange={handleInputChange}
          />
        </Box>

        {data.category !== "FAQ" && (
          <Box sx={{ marginBottom: 1, width: "100%" }}>
            <label>Content</label>
            {/* <WysiwygEditor
  editorState={editorState}
  onEditorStateChange={handleEditorStateChange}
  toolbarClassName="fixed-toolbar"
  editorClassName="editor-content"
  placeholder="Enter text here..."
/> */}

            <JoditEditor
              ref={editor}
              value={data.content}
              tabIndex={1}
              onBlur={handleDescriptionChange}
              onChange={() => {}}
            />
          </Box>
        )}
        {data.category === "FAQ" && (
          <Box sx={{ background: "#D8D8D8", padding: 2, borderRadius: 2 }}>
            {faqData.map((faq, index) => (
              <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <IoCloseCircleOutline
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#f03a52",
                    }}
                    onClick={() => handleRemoveFaq(index)}
                  />
                </Box>

                <TextField
                  placeholder="Question"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, width: "100%", background: "#ffff" }}
                  name="question"
                  value={faq.question}
                  onChange={(e) => handleFaqInputChange(index, e)}
                />
                {/* <WysiwygEditor
                  editorState={faq.answer}
                  onEditorStateChange={(newEditorState) =>
                    handleFaqEditorStateChange(index, newEditorState)
                  }
                  placeholder="Enter answer here..."
                /> */}

                <JoditEditor
                  ref={editor}
                  value={faq.answer}
                  tabIndex={1}
                  onBlur={(newEditorState) =>
                    handleFaqEditorStateChange(index, newEditorState)
                  }
                  onChange={() => {}}
                />
              </Box>
            ))}
            {data.category === "FAQ" && (
              <Button
                variant="contained"
                onClick={handleAddFaq}
                sx={{
                  border: "solid 1px grey",
                  backgroundColor: "#37668F",
                  color: "#FFFF",
                  width: "120px",
                  borderRadius: "5px",
                }}
              >
                <FaPlus />
                <span> Add New</span>
              </Button>
            )}
          </Box>
        )}
        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label>Video</label>
          <TextField
            placeholder="Video Url"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: "100%", mt: 1 }}
            name="videourl"
            value={data.videourl}
            onChange={handleInputChange}
          />
        </Box>

        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label style={{}}>File</label>
          <br></br>
          {uploadBtnLoad ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                variant="contained"
                component="label"
                startIcon={<CiCloud />}
                sx={{ background: "#37668F", mt: 1 }}
              >
                Upload File
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              {fileName && <p>{fileName}</p>} {/* Display file name */}
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "white",
            color: "grey",
            width: "100px",
            border: "solid 1px grey",
            borderRadius: "5px",
          }}
          onClick={handleTriggerCreateContribute}
        >
          <b>Cancel</b>
        </Button>

        <Button
          sx={{
            border: "solid 1px grey",
            backgroundColor: "#37668F",
            "&:hover": {
              backgroundColor: "#37668F", // Prevent background color change on hover
            },
            color: "#FFFF",
            width: "100px",
            borderRadius: "5px",
          }}
          onClick={handleSubmit}
        >
          <b>Submit</b>
        </Button>
      </Box>
    </Container>
  );
}

export default CreateContribute;
