import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button } from "@mui/material";

function AssignCourseDrawer({id}) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);

  useEffect(() => {
    axios
      .post(`/course/allusers`, { companyid })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  const handleCheckboxChange = (email) => {
    setSelectedEmail((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((e) => e !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  console.log(selectedEmail, "selected email")

  const handleSubmit = ()=>{
    // console.log(selectedEmail, "selected email")
    console.log(id, "id")
    axios.post(`/course/assigncourse`, {id, email:selectedEmail})
    .then((res)=>{
       alert(res.data.message)
    })
    .catch((err)=>console.log(err))

  }

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Checkbox</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedEmail && selectedEmail.includes(row.email)}
                    onChange={() => handleCheckboxChange(row.email)}
                  />
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {selectedEmail && (
        <div>
          <p>Selected Email: {selectedEmail}</p>
        </div>
      )} */}

      <Button onClick={handleSubmit}>Submit</Button>
    </Container>
  );
}

export default AssignCourseDrawer;
