import { Box, Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Select from "react-select";
import { IoCloseCircleOutline } from "react-icons/io5";
import "./Module.css"
function AddModuleDrawer({setRefresh, refresh, handleTriggerModule}) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department
//  console.log(userInfo, "userInfo");

  const [data, setData] = useState({
    name: "",
    department: department,
    access: [],
    companyid,
    username,
    userid,
    email,
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  // useEffect(() => {
  //   axios
  //     .post(`https://klms.vliv.app/repomodule/departments`, { companyid })
  //     .then((res) => {
  //       const uniqueDepartments = Array.from(
  //         new Set(res.data.map((item) => item.department))
  //       ).map((department) => ({
  //         value: department,
  //         label: department,
  //       }));
  //       setDepartmentData(uniqueDepartments);
      
  //       // console.log(res.data, "repo departments")

  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // Departmet select
  // const handleDepartmentSelectChange = (selectedOption) => {
  //   setData((prev) => ({
  //     ...prev,
  //     department: selectedOption.value,
  //   }));


  // };

  // Employee select
  useEffect(()=>{
        // To get that employee we have to pass the department
        axios
        .post(`https://klms.vliv.app/repomodule/employee`, {
          companyid,
          department: department,
        })
        .then((res) => {
          // console.log(res.data, "employee data");
          const mappedData = res.data.map((item) => ({
            value: item.email,
            label: item.username,
          }));
          setEmployeeData(mappedData);
        })
        .catch((err) => console.log(err));
  },[department])

  const handleEmployeeSelectChange = (selectedOptions) => {
    const selectedEmails = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setData((prev) => ({
      ...prev,
      access: selectedEmails,
    }));

  
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = ()=>{
    if(data.name == ""){
      return alert("Please enter module name");    
    }
    // alert("submit button will work soon")
    axios.post(`https://klms.vliv.app/repomodule/add`, data)
    .then((res)=>{
      alert(res.data.message)
      handleTriggerModule()
      setRefresh(!refresh)
    })
    .catch((err)=>console.log(err))
  }

  console.log(data);

  return (
    <>
      
      <Container >
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={handleTriggerModule}
        />
      </Box>

        <Box>
          <h2>Add Repository</h2>
        </Box>
        <Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Repo Name"
            name="name"
            value={data.name}
            onChange={handleChange}
          />
          {/* <Box sx={{ marginBottom: 1 }}>
            <Select
              options={departmentData}
              onChange={handleDepartmentSelectChange}
              placeholder="Select Department"
            />
          </Box> */}
          <Box sx={{ marginBottom: 1 }}>
            <Select
             isMulti
              options={employeeData}
              onChange={handleEmployeeSelectChange}
              placeholder="Select Employees"
            />
          </Box>
        </Box>
        <Box>
        <Button
             sx={{   backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
             color: "white",
             width: "100px",
             border: "solid 1px grey",
             borderRadius: "5px", width: "100%" }}
             onClick={handleSubmit}
           >
           
             Submit
           </Button>
        </Box>
      </Container>
    </>
  );
}

export default AddModuleDrawer;
