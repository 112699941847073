import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import Cookies from "js-cookie";

function ChpaterItemDrawer({ onChapterItemSubmit }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;


  const [showUpload, setShowUpload] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);
  const [radiovalue, setRadiovalue] = useState("");
  const [radioForOption, setRadioForOption] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [description, setDescription] = useState("");

  //   Fetch videos
  //   useEffect(() => {
  //     axios

  //       .post(`/video/get`, { companyid: "x", userid: "x" })

  //       .then((res) => {
  //         setVideos(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }, [refresh]);

  const handleShowUploadView = () => {
    setShowUpload(true);
  };
  const handleHideUploadView = () => {
    setShowUpload(false);
  };
  const handleRadioToggle = (e) => {
    setRadiovalue(e.target.value);
  };
  const handleRadioFileVdoToggle = (e) => {
    setRadioForOption(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleFileChange = (event) => {
    setUploadBtnLoad(true);
    // setSelectedFile(e.target.files[0]);
    const file = event.target.files[0];

    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB
    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploadBtnLoad(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]); // Assuming input type is file and accepts only one image

    axios

      .post("https://klms.vliv.app/video/upload", formData, {

        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFileUrl(res.data.file);
        console.log(res.data.file, "inside fileChange response");
        setUploadBtnLoad(false);

        // To store file size inside storage table
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onChapterItemSubmit({
   itemData: [
        {
          title,
          fileUrl
        },
      ],
    });

    // axios

    //   .post(`/video/add`, {

    //     title: title,
    //     src: fileUrl,
    //     category: category,
    //     tag: tags,
    //     duration: "0",
    //     user: "x",
    //     userid: "x",
    //     company: "x",
    //     companyid: "x",
    //   })
    //   .then((res) => {
    //     console.log(res.data, "submit response");
    //     alert(res.data.message);
    //     setTitle("");
    //     setCategory("");
    //     setTags("");
    //     setFileUrl("");
    //     setRefresh(!refresh)
    //   })
    //   .catch((err) => console.log(err));
  };

  console.log(fileUrl, "file url");

  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box>
          <Typography component="h1" variant="h5">
            Add Chapter Item
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={handleTitleChange}
            />

            {uploadBtnLoad ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth margin="normal" required>
                {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                <input
                  // accept="video/*"
                  style={{ display: "none" }}
                  id="video-file"
                  type="file"
                  onChange={handleFileChange}
                />

                <label htmlFor="video-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload File
                  </Button>
                </label>
              </FormControl>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={uploadBtnLoad}
            >
              Submit
            </Button>
          </form>
        </Box>

        {/* <Box>
              <TiDeleteOutline
                onClick={handleHideUploadView}
                style={{ width: "30px", height: "30px" }}
              />
            </Box> */}
      </Box>
    </Container>
  );
}

export default ChpaterItemDrawer;
