// File: /path_to_component/Repository.js

import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import AllContributes from './AllContributes/AllContributes';
import MyContributes from './MyContributes/MyContributes';

function Contributions() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Contribution List" />
        <Tab label="My Contribution" />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {selectedTab === 0 && <AllContributes />}
        {selectedTab === 1 && <MyContributes />}
      </Box>
    </Box>
  );
}

export default Contributions;
