import React, { useState } from 'react';
import { Grid, Divider, Box, Typography, Avatar, Button, TextField, FormControl, InputLabel, styled, InputBase, alpha, MenuItem } from '@mui/material';
import noimage from "../../../assets/no-profile.png";
import { useDispatch, useSelector } from "react-redux";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
function Profile() {
  //redux
  const { token, userData } = useSelector(state => state.loginReducer);
  console.log("userData", userData);

  //state
  const [showformfeilds, setFormFeilds] = useState(false);
  const [data, setData] = useState({
    username: "",
    userphone: "",
    designation: "",
    department: "",
    clientemail: "",
    password: "",
    age: "",
    gender: "",
    typeofdocument: "",
    documentvalue: "",
    userrole: "",
    employeeid: "",
    language: "",
    linkedin: "",
    pan: "",
    twitter: "",
    userid: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    address2: "",

    // ,
    // ,
    // ,
    // clientemail,
    // clientimage,
    // clientname,
    // companyemail,
    // companygst,
    // companyid,
    // companyimage,
    // companyname,
    // companyregno,
    // ,
    // countrycode,
    // createdAt,
    // designation,
    // email,
    // ,
    // gender,
    // id,
    // ,
    // linkedin,
    // ,
    // password,
    // pf,
    // ,
    // reportmanager,
    // signature,
    // ,
    // status,
    // timezone,
    // ,
    // documentvalue,
    // companyaddress,
    // ,
    // updatedAt,
    // ,
    // usermodules: [],
    // username,
    // userphone,
    // userprofile,
  })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h5" mt={2}>
            User Profile
          </Typography>
          <Divider />
        </Box>

      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Box className="user-container">
            <Avatar alt="User Image Not available" src={userData.clientimage ? userData.clientimage : noimage} className='profileImage' width="20" />
            <Box sx={{ my: 2 }}>
              <Typography variant='h5'>{userData.username}</Typography>
              <Typography variant='subtitle1'>{!userData.designation ? "--" : userData.designation}</Typography>
              <Typography variant='subtitle2'>{userData.email}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box className="user-container">
            <Avatar alt="User Image Not available" src={userData.companyimage ? userData.companyimage : noimage} className='profileImage' />
            <Box sx={{ my: 2 }}>
              <Typography variant='h5'>{userData.companyname}</Typography>
              <Typography variant='subtitle1'>Reporting Manager : {!userData.reportmanager ? "--" : userData.reportmanager}</Typography>
              <Typography variant='subtitle2'>{userData.companyemail}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ my: 1 }}>
        <Grid item xs={12}>
          <Divider>PERSONAL DETAILS</Divider>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Name :</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter name' value={userData.username} name='username' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.username ? "--" : userData.username}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Mobile No.:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='number' placeholder='Enter mobile no.' value={userData.userphone} name='userphone' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.userphone ? "--" : userData.userphone}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Designation:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter designation' value={userData.designation} name='designation' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.designation ? "--" : userData.designation}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Department:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter department' value={userData.department} name='department' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.department ? "--" : userData.department}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Email ID:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='email' placeholder='Enter Email' value={userData.clientemail} name='clientemail' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.clientemail ? "--" : userData.clientemail}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Password:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Password' value={userData.password} name='password' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.password ? "--" : userData.password}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Age:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='number' placeholder='Enter Age' value={userData.age} name='age' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.age ? "--" : userData.age}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Gender:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Gender' value={userData.gender} name='gender' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.gender ? "--" : userData.gender}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Type Of Document:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <TextField
                id="filled-select-currency"
                select
                sx={{ width: "57%" }}
                variant="outlined"
                label="Select Document Type"
              >
                <MenuItem value="ICA">
                  ICA
                </MenuItem>
                <MenuItem value="SAID">
                  SAID
                </MenuItem>
                <MenuItem value="Addhar">
                  Addhar
                </MenuItem>
                <MenuItem value="SSN">
                  SSN
                </MenuItem>

              </TextField>
              :
              <Typography variant='span' className='labelvalue'>{!userData.typeofdocument ? "--" : userData.typeofdocument}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Addhar No.:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Document ' value={userData.documentvalue} name='documentvalue' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.documentvalue ? "--" : userData.documentvalue}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>User Role:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Role' value={userData.userrole} name='userrole' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.userrole ? "--" : userData.userrole}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Employee ID:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Employee id' value={userData.employeeid} name='employeeid' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.employeeid ? "--" : userData.employeeid}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Language:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Employee id' value={userData.language} name='language' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.language ? "--" : userData.language}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>LinkedIn :</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter Linkedin id' value={userData.linkedin} name='linkedin' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.linkedin ? "--" : userData.linkedin}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Pan:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter pan' value={userData.pan} name='pan' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.pan ? "--" : userData.pan}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Twitter:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter twitter' value={userData.twitter} name='twitter' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.twitter ? "--" : userData.twitter}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>User ID:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter userid' value={userData.userid} name='userid' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.userid ? "--" : userData.userid}</Typography>
          }
        </Grid>
      </Grid>

      <Grid container sx={{ my: 1 }}>
        <Grid item xs={12}>
          <Divider>ADDRESS DETAILS</Divider>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Country :</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter country' value={userData.country} name='country' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.country ? "--" : userData.country}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>State:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter state' value={userData.state} name='state' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.state ? "--" : userData.state}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>City:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter city' value={userData.city} name='city' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.city ? "--" : userData.city}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Pin Code:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter pincode' value={userData.pincode} name='pincode' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.pincode ? "--" : userData.pincode}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Address 1:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter address' value={userData.address} name='address' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.address ? "--" : userData.address}, {!userData.city ? "--" : userData.city}, {!userData.state ? "--" : userData.state}, {!userData.pincode ? "--" : userData.pincode}, {!userData.country ? "--" : userData.country} </Typography>
          }


        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Address 2:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter address' value={userData.address2} name='address2' />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.address ? "--" : userData.address}, {!userData.city ? "--" : userData.city}, {!userData.state ? "--" : userData.state}, {!userData.pincode ? "--" : userData.pincode}, {!userData.country ? "--" : userData.country}</Typography>
          }


        </Grid>

      </Grid>

      <Grid container sx={{ my: 3 }}>
        <Grid item xs={12}>
          <Divider>COMPANY DETAILS</Divider>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company Name:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter companyname' value={userData.companyname} name='companyname' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companyname ? "--" : userData.companyname}</Typography>
          }


        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company Email:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='email' placeholder='Enter companyemail' value={userData.companyemail} name='companyemail' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companyemail ? "--" : userData.companyemail}</Typography>
          }


        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company ID:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter companyid' value={userData.companyid} name='companyid' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companyid ? "--" : userData.companyid}</Typography>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company GST:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter companygst' value={userData.companygst} name='companygst' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companygst ? "--" : userData.companygst}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company Reg. no:</Typography>
        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='text' placeholder='Enter companyregno' value={userData.companyregno} name='companyregno' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companyregno ? "--" : userData.companyregno}</Typography>
          }

        </Grid>
        <Grid item xs={3}>
          <Typography variant='span' className='labelname'>Company Address:</Typography>

        </Grid>
        <Grid item xs={3}>
          {
            showformfeilds ?
              <FormControl variant="standard">
                <BootstrapInput type='email' placeholder='Enter companyregno' value={userData.companyregno} name='companyregno' disabled />
              </FormControl>
              :
              <Typography variant='span' className='labelvalue'>{!userData.companyregno ? "--" : userData.companyregno}</Typography>
          }
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Divider />
        </Grid>

      </Grid>

      <Grid container>
        <Grid item>
          <Button variant="contained" onClick={() => setFormFeilds(!showformfeilds)}>Update</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Profile;