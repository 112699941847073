import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

function AssignCourseDrawer({ id, setId }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    axios
      .post(`/course/allusers`, { companyid })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));

    //Get course by id
    axios
      .post(`/course/getbyid`, { id })
      .then((res) => {
        // console.log(res.data, "get by id data")
        setCourseData(res.data);
        setSelectedEmail(
          res.data.length > 0 && res.data[0].assign != null
            ? res.data[0].assign
            : []
        );
      })
      .catch((err) => console.log(err));
  }, [companyid, refresh]);

  const handleCheckboxChange = (email) => {
    setSelectedEmail((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((e) => e !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  // console.log(selectedEmail, "selected email")

  const handleSubmit = () => {
    // console.log(selectedEmail, "selected email")
    // console.log(id, "id")
    axios
      .post(`/course/assigncourse`, { id, email: selectedEmail, assign: true })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
        setId(0);
      })
      .catch((err) => console.log(err));
  };

  // To filter by searchValue for both username and email
  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? item.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.email?.toLowerCase().includes(searchValue.toLowerCase())
        : true
    );
  };

  const filteredData = filterData();

  return (
    <Container>
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={() => setId(0)}
        />
      </Box>
      <Box>
        <h2>Assign course to user</h2>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400, marginRight: 2 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={
                        selectedEmail && selectedEmail.includes(row.email)
                      }
                      onChange={() => handleCheckboxChange(row.email)}
                    />
                  </TableCell>

                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.username}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2, display: "block", mx: "auto" }}
          disabled={!selectedEmail}
        >
          Submit
        </Button> */}

        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#37668F",
              color: "#FFFF",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={handleSubmit}
            disabled={!selectedEmail}
          >
            <b>Submit</b>
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "grey",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={() => setId(0)}
          >
            <b>Cancel</b>
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default AssignCourseDrawer;
