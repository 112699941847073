import { Box, Card, Container, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaRegFileAlt, FaRegHandshake } from "react-icons/fa";
import { LuFileCheck2, LuFileClock } from "react-icons/lu";
import { FaFileCircleXmark } from "react-icons/fa6";
import { DataGrid } from "@mui/x-data-grid";
import { MdEdit, MdOutlineAssessment, MdOutlineRemoveRedEye } from "react-icons/md";
import ContributeModalTabsUpdate from "../Contributions/MyContributes/UpdateContribution/ContributeModalTabsUpdate";
import { useNavigate } from "react-router-dom";
import { GiGraduateCap } from "react-icons/gi";
import { GoTag } from "react-icons/go";

import Blue from "../../../assets/Blue.png";
import Green from "../../../assets/Green.png";
import Purple from "../../../assets/Purple.png";
import Yellow from "../../../assets/Yellow.png";
function DashboardView() {
  const navigate = useNavigate();

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  // For kms user Dashboard use states
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [approve, setApprove] = useState(0);
  const [pending, setPending] = useState(0);
  const [reject, setReject] = useState(0);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/get`, {
        companyid, userid
      })
      .then((res) => {
        console.log(res.data, "my contribution data")
        setData(res.data.length > 0 ? res.data : []);
        // To get Appved data
        const approveData = res.data.filter((elem)=>{return elem.status == "Approved"}).length
        const pendingData = res.data.filter((elem)=>{return elem.status == "Pending"}).length
        const rejectData = res.data.filter((elem)=>{return elem.status == "Rejected"}).length

        setApprove(approveData)
        setPending(pendingData)
        setReject(rejectData)

        // console.log(approveData, "approve data")
      })
      .catch((err) => console.log(err));
  }, []);


  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      
    },
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      
    },
      {
        field: "createdAt",
        headerName: "Date",
        flex: 1,
        
      },
      // {
      //   field: "status",
      //   headerName: "Status",
      //   flex: 1,
      //   
      // },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        
        renderCell: (params) => {
          let color;
          let background;
          let borderRadius;
          let padding;
          switch (params.value) {
            case 'Pending':
              color = '#B54706';
              background="#FDB022"
              borderRadius="5px"
              padding = "2px"
              break;
            case 'Approved':
              color = '#05804C';
              background="#32D583"
              borderRadius="5px"
              padding = "2px"
              break;
            default:
              color = '#700110';
              background="#D92D20"
              borderRadius="5px"
              padding = "2px"
              break;
          }
          return (
            <span style={{ color, background, borderRadius, padding }}>
              {params.value}
            </span>
          );
        }
      },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
      <> 
         <MdOutlineRemoveRedEye
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleViewButton(params.row.id);
          }}
          style={{width:"17px", height:"17px", marginRight:"5px"}}
        />

      <MdEdit
        variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditButtonClick(params.row.id);
          }} 
          style={{width:"17px", height:"17px"}}
          />
          
       
        </> 
      ),
    },
  ];


  const handleTriggerUpdateContribute = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleEditButtonClick = (id) => {
    setSelectedModuleId(id);
    setOpenEditModal(true);
  };
  
  const handleViewButton = (id) => {
    navigate(`/kms/contributions/view/${id}`)
      };

      return (
    <>
          <Grid container maxWidth="100%" padding="1%" sx={{  background:"#F0F9FF",}}>
            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#B9E6FE",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      position: "relative",
                      padding: 2,
                      width: "40vh",
                      height: "25vh",
                    }}
                  >
                    <FaRegHandshake
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "20%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "140px",
                        opacity: 0.2,
                      }}
                    />
                    <Typography sx={{ fontSize: "20px", mt: 1 }}>
                      My Contributions
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <FaRegFileAlt
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "#0086C9",
                        }}
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                      {+data.length}
                    </Typography>
                  </Box>
                </Grid>
      
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#A6F4C5",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      position: "relative",
                      padding: 2,
                    }}
                  >
                    <GoTag
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "20%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "100px",
                        opacity: 0.2,
                      }}
                    />
                    <Typography sx={{ fontSize: "20px", mt: 1 }}>
                      Approve
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <LuFileCheck2
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "#12B76A",
                        }}
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                      {+approve}
                    </Typography>
                  </Box>
                </Grid>
      
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#FEDF89",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      position: "relative",
                      padding: 2,
                    }}
                  >
                    <LuFileClock
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "20%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "100px",
                        opacity: 0.2,
                      }}
                    />
                    <Typography sx={{ fontSize: "20px", mt: 1 }}>
                      Pending
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <LuFileClock
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "#F79009",
                        }}
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                      {+pending}
                    </Typography>
                  </Box>
                </Grid>
      
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#FECDCA",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      position: "relative",
                      padding: 2,
                    }}
                  >
                    <FaFileCircleXmark
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "20%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "100px",
                        opacity: 0.2,
                      }}
                    />
                    <Typography sx={{ fontSize: "20px", mt: 1 }}>
                      Rejected
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <FaFileCircleXmark
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "#D92D20",
                        }}
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                      {+reject}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}


<Grid container spacing={2} sx={{ justifyContent:"center" }}>
  <Grid item xs={12} sm={6} md={3}>
  
      <Box
        sx={{
          background: "#F4EBFF",
          width: "100%",
          height: "18vh", // Adjusted for smaller size
          textAlign: "center",
          borderRadius: "15px",
          position: "relative",
          overflow: "hidden",

           border:"solid 1px #dbdbd9"
        }}
      >
        <FaRegHandshake
          style={{
            position: "absolute",
            top: "50%",
            left: "20%",
            transform: "translate(-50%, -50%)",
            fontSize: "100px", // Adjust size as needed
            opacity: 0.2, // Adjust opacity as needed
          }}
        />
        <img
          src={Purple}
          style={{
            width: "70%", // Make image responsive
            position: "absolute",
            top: "40%",
            right: "0%", // Align to the right
            transform: "translate(0, -50%)",
            opacity: 0.8, // Adjust opacity as needed
          }}
        />
        <Typography sx={{ fontSize: "16px", mt: 1 }}>My Contributions</Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: "28px", mt: 1 }}>{+data.length}</Typography>
      </Box>
  
  </Grid>

  <Grid item xs={12} sm={6} md={3}>
    <Box
      sx={{
        background: "#FEF0C7",
        width: "100%",
        height: "18vh", // Adjusted for smaller size
        textAlign: "center",
        borderRadius: "15px",
        position: "relative",
        overflow: "hidden",
         border:"solid 1px #dbdbd9"
      }}
    >
      <GoTag
        style={{
          position: "absolute",
          top: "50%",
          left: "20%",
          transform: "translate(-50%, -50%)",
          fontSize: "100px", // Adjust size as needed
          opacity: 0.2, // Adjust opacity as needed
        }}
      />
      <img
        src={Yellow}
        style={{
          width: "70%", // Make image responsive
          position: "absolute",
          top: "40%",
          right: "0%", // Align to the right
          transform: "translate(0, -50%)",
          opacity: 0.8, // Adjust opacity as needed
        }}
      />
      <Typography sx={{ fontSize: "16px", mt: 1 }}>Approve</Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: "28px", mt: 1 }}>{+approve}</Typography>
    </Box>
  </Grid>

  <Grid item xs={12} sm={6} md={3}>
    <Box
      sx={{
        background: "#D1FADF",
        width: "100%",
        height: "18vh", // Adjusted for smaller size
        textAlign: "center",
        borderRadius: "15px",
        position: "relative",
        overflow: "hidden",
         border:"solid 1px #dbdbd9"
      }}
    >
      <LuFileClock
        style={{
          position: "absolute",
          top: "50%",
          left: "20%",
          transform: "translate(-50%, -50%)",
          fontSize: "100px", // Adjust size as needed
          opacity: 0.2, // Adjust opacity as needed
        }}
      />
      <img
        src={Green}
        style={{
          width: "70%", // Make image responsive
          position: "absolute",
          top: "40%",
          right: "0%", // Align to the right
          transform: "translate(0, -50%)",
          opacity: 0.8, // Adjust opacity as needed
        }}
      />
      <Typography sx={{ fontSize: "16px", mt: 1 }}>Pending</Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: "28px", mt: 1 }}>{+pending}</Typography>
    </Box>
  </Grid>

  <Grid item xs={12} sm={6} md={3}>
    <Box
      sx={{
        background: "#FEE2E2",
        width: "100%",
        height: "18vh", // Adjusted for smaller size
        textAlign: "center",
        borderRadius: "15px",
        position: "relative",
        overflow: "hidden",
         border:"solid 1px #dbdbd9"
      }}
    >
      <FaFileCircleXmark
        style={{
          position: "absolute",
          top: "50%",
          left: "20%",
          transform: "translate(-50%, -50%)",
          fontSize: "100px", // Adjust size as needed
          opacity: 0.2, // Adjust opacity as needed
        }}
      />
      <img
        src={Purple}
        style={{
          width: "70%", // Make image responsive
          position: "absolute",
          top: "40%",
          right: "0%", // Align to the right
          transform: "translate(0, -50%)",
          opacity: 0.8, // Adjust opacity as needed
        }}
      />
      <Typography sx={{ fontSize: "16px", mt: 1 }}>Rejected</Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: "28px", mt: 1 }}>{+reject}</Typography>
    </Box>
  </Grid>
</Grid>



      
            <Grid item xs={12} md={12} sx={{  justifyContent:"space-between" }}>
              <Box
                sx={{
                  // width: "99%",
                  background: "#FDFDFD",
                  // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  borderRadius: "10px",
                  padding: 2,
                   border:"solid 1px #dbdbd9",
                  mt:1
                }}
              >
                <Grid container justifyContent="space-between">
                  <Typography sx={{ mt: 2, mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}>
                    <MdOutlineAssessment />
                    <span> My Contributions</span>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      mb: 2,
                      fontWeight: "bold",
                      color: "#026AA2",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/kms/contributions`)}
                  >
                    <span> View All</span>
                  </Typography>
                </Grid>
                <Box sx={{ borderRadius: 5 }}>
                  <DataGrid
                //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
      
            <Modal
              open={openEditModal}
              onClose={handleTriggerUpdateContribute}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "150vh",
                  height: "90vh",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <ContributeModalTabsUpdate
                  id={selectedModuleId}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  handleTriggerUpdateContribute={handleTriggerUpdateContribute}
                />
              </Box>
            </Modal>
          </Grid>
          </>
      );
      
}

export default DashboardView;
