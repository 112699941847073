import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
// import CourseDrawer from "./CourseDrawer"; // Importing CourseDrawer component
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import { AiOutlineGlobal } from "react-icons/ai";
import { TiUserAdd } from "react-icons/ti";
import Cookies from "js-cookie";
// import AssignCourseDrawer from "./AssignCourseDrawer";
import { IoAddOutline } from "react-icons/io5";
import { LuVideo } from "react-icons/lu";
import { FaCheck, FaHeart, FaRegBookmark } from "react-icons/fa";
import { IoIosHeartEmpty } from "react-icons/io";

function Explore() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const userid = userInfo.userid;
  const company = userInfo.companyname;
  const user = userInfo.username;
  // const userrole = userInfo.userrole
  // console.log(email, "email from user info");

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenForAccess, setIsDrawerOpenForAccess] = useState(false);

  const [isLayerOpen, setIsLayerOpen] = useState(false); // State to manage the visibility of the layer
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [id, setId] = useState(0);
  const [courseTrackData, setCourseTrackData] = useState([]);
  // Fetch videos
  useEffect(() => {
    axios
      .post(`/course/getpublic`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleViewCoursespage = (id) => {
    navigate(`/lms/view/courses/${id}`);
  };

  const handleAddCourseToYourList = (id) => {
    // console.log([email])
    axios
      .post(`/course/assigncourse`, { id, email: [email] })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh)
      })
      .catch((err) => console.log(err));
  };

  //For favourite
  const handleAddFavourite = (id) => {
    axios
      .post(`https://klms.vliv.app/course/addfavourite`, {
        id,
        email,
        user,
        userid,
        company,
        companyid,
      })
      .then((res) => {
        setRefresh(!refresh);
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxWidth="100%">
      <Box sx={{ padding: "10px" }}>
        {/* This below box is just given to give margin same as course in course we 
        have button so we can see the gap between navbar and videos dashboard */}
        <Box
          sx={{
            color: "white",
            marginBottom: "5px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "4px",
          }}
        >
          <Button></Button>
        </Box>

        {loading ? (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            {data &&
              data.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2.4}
                  key={index}
                  sx={{ position: "relative" }}
                >
                  <Box
                    sx={{
                      background: "#FFFF",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "24vh",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleViewCoursespage(item.id)}
                    />
                    <Box sx={{padding:1}}>
                      <Box
                        sx={{
                          width: "16vw",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 0px 5px 5px",
                          mt: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                          >
                            {item.coursename.length > 25
                              ? item.coursename.substring(0, 25) + "..."
                              : item.coursename}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 0 3px 3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          created at - {new Date(item.createdAt).toDateString()}
                        </Typography>

                        <Box sx={{ display: "flex", gap: 1 }}>
                          {userrole != "masteradmin" && userrole != "HR" && userrole != "Manager" && (
                            <>
                              {item &&
                              item.assign &&
                              item.assign.includes(email) ? (
                                <Typography
                                  title="Already have this course"
                                  sx={{
                                    backgroundColor: "grey",
                                    borderRadius: "10px",
                                    marginBottom: 1,
                                    width: "25px",
                                    height: "25px",
                                  }}
                                >
                                  <FaCheck
                                    style={{
                                      color: "white",
                                      width: "20px",
                                      height: "25px",
                                      borderRadius: 1,
                                      padding: 1,
                                      // marginBottom: -5,
                                      cursor: "pointer",
                                    }}
                                    onClick={()=>alert("This course is already available inside your courses")}
                                  />
                                </Typography>
                              ) : (
                                <Typography
                                  title="Add to course"
                                  sx={{
                                    backgroundColor: "grey",
                                    borderRadius: "10px",
                                    marginBottom: 1,
                                    width: "25px",
                                    height: "25px",
                                  }}
                                >
                                  <IoAddOutline
                                    onClick={() =>
                                      handleAddCourseToYourList(item.id)
                                    }
                                    style={{
                                      color: "white",
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: 1,
                                      padding: 1,
                                      // marginBottom: -5,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Typography>
                              )}
                            </>
                          )}
                          {item &&
                          item.favourite &&
                          item.favourite.includes(email) ? (
                            <Box
                              title="Make favourite"
                              sx={{
                                borderRadius: "10px",
                                marginRight: 1,
                                marginBottom: 1,
                              }}
                            >
                              <FaHeart
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                  color: "#eb4034",
                                  borderRadius: 1,
                                  padding: 1,
                                }}
                                onClick={() => handleAddFavourite(item.id)}
                              />
                            </Box>
                          ) : (
                            <Box
                              title="Make favourite"
                              sx={{
                                borderRadius: "10px",
                                marginRight: 1,
                                marginBottom: 1,
                              }}
                            >
                              <IoIosHeartEmpty
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                  color: "#2D3747",
                                  borderRadius: 1,
                                  padding: 1,
                                }}
                                onClick={() => handleAddFavourite(item.id)}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
}

export default Explore;
