import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Sop from "../../SOP/Sop";
import TextEditor from "../MyContributes/TextEditor";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CiCloud } from "react-icons/ci";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WestIcon from "@mui/icons-material/West";

function ViewContribute() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;

  const [data, setData] = useState([]);
  const [faqData, setfaqData] = useState([]);

  const [moduleData, setModuleData] = useState([]);
  const [categoryData, setCategoryData] = useState([
    { value: "SOP", label: "SOP" },
    { value: "FAQ", label: "FAQ" },
    { value: "GUIDES", label: "GUIDES" },
  ]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateFaq, setEditorStateFaq] = useState(
    EditorState.createEmpty()
  );

  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/getbyid`, { id })
      .then((res) => {
        const fetchedData = res.data;
        setData({
          ...fetchedData,
          module:
            moduleData.find((module) => module.value === fetchedData.module) ||
            fetchedData.module,
          category:
            categoryData.find(
              (category) => category.value === fetchedData.category
            ) || fetchedData.category,
        });
        // setEditorState(
        //   EditorState.createWithContent(
        //     convertFromRaw(JSON.parse(fetchedData.content))
        //   )
        // );
      })
      .catch((err) => console.log(err));

    // To get faq data if there are any then
    axios
      .post(`https://klms.vliv.app/contribution/getfaqs`, { id: id })
      .then((res) => {
        setfaqData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id, moduleData, categoryData]);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/getmodule`, {
        department,
        companyid,
      })
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        setModuleData(data);
      })
      .catch((err) => console.log(err));
  }, [department]);

  const handleSubmit = () => {};

  console.log(data, "data");
  return (
    <Container maxWidth="xl" height="100vh" sx={{}}>
      {/* <Box>
        <Button onClick={() => navigate("/kms/contributions")}>
          <IoMdArrowRoundBack
            style={{ width: "30px", height: "30px", color: "black" }}
          />
        </Button>
      </Box> */}
      <Button
        sx={{ }}
        onClick={() => navigate("/kms/contributions")}
        startIcon={<WestIcon />}
        className="cancelBtn"
        // variant="outlined"
      >
        Back
      </Button>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Typography
          sx={{
            display: "flex",
            gap: 3,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            background: "#F4FEFE",
            padding: 1,
            // border:"solid 1px black",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            Repository
          </Typography>
          <Typography sx={{}}>
            {(data && data.module && data.module.label) ||
              (data && data.module)}
          </Typography>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            gap: 3,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            background: "#F4FEFE",
            padding: 1,
            // border:"solid 1px black",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            Category
          </Typography>
          <Typography
            sx={{
              background: "pink",
              color: "red",
              borderRadius: 3,
              padding: 0.5,
            }}
          >
            {(data && data.category && data.category.label) ||
              (data && data.category)}
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: 1,
          // padding: 2,
          // backgroundColor: "#f5f8fa",
        }}
      >
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
        
          <Box>
            <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Category
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {(data && data.category && data.category.label) ||
                (data && data.category)}
            </Typography>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            background: "#F4FEFE",
          }}
        >
          {/* <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
            Topic
          </Typography> */}
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {data && data.topic}
          </Typography>
        </Box>
        {data && data.category && data.category.value == "FAQ" ? (
          <Box sx={{ borderRadius: 2, background: "#F4FEFE" }}>
            {/* <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Content
            </Typography> */}
            {faqData.map((faq, index) => (
              <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
                <Accordion key={index} sx={{ marginTop: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <WysiwygEditor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(JSON.parse(faq.answer))
                      )}
                      readOnly
                      toolbarHidden
                    /> */}
                    <Box
                      sx={{ bgcolor: "#FFFF", borderRadius: 5 }}
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            {/* <Typography sx={{ fontWeight: "bold" }} >
              Content
            </Typography> */}
            {/* <WysiwygEditor editorState={editorState} readOnly toolbarHidden /> */}
            <Box
              sx={{ padding: 2, bgcolor: "#FFFF", borderRadius: 5 }}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ background: "#F4FEFE", mt: 2, padding: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Video</Typography>
        {data.videourl ? (
          <Typography sx={{ marginBottom: 2, fontSize: "12px" }}>
            <a
              href={data.videourl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: "14px" }}
            >
              Click here
            </a>
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            No video URL provided
          </Typography>
        )}

        <Typography sx={{ fontWeight: "bold" }}>File</Typography>
        {data.fileurl ? (
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, fontSize: "12px" }}
          >
            <a
              href={data.fileurl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: "14px" }}
            >
              Click here
            </a>
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            No file URL provided
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ViewContribute;
