import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";

function ChapterItemViewDrawer(data) {
  // console.log(data, "item view data");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Container sx={{ width: 700, padding: 2 }}>
      <Box>
        Chapter item view
        <Typography>
          {" "}
          <b>Title</b>:{data && data.data.title}
        </Typography>
      </Box>

      <Box
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          padding: 2,
          borderRadius: 1,
          textAlign: "center",
        }}
      >
        {data &&
        data.data &&
        data.data.fileUrl &&
        data.data.fileUrl.includes(".pdf") ? (
          <Box
            style={{ padding: "1px", borderRadius: "5px", width: "200px" }}
            url={data && data.data.fileUrl}
          >
            {data && data.data.fileUrl.includes("https://") ? (
              <Document
                style={{ padding: "1px", borderRadius: "5px" }}
                url={data && data.data.fileUrl}
                file={data && data.data.fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            ) : (
              <Document
                style={{ padding: "1px", borderRadius: "5px" }}
                url={data && data.data.fileUrl}
                file={`https://` + data && data.data.fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            )}
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <Button onClick={() => setPageNumber(pageNumber - 1)}>prev</Button>
            <Button onClick={() => setPageNumber(pageNumber + 1)}>next</Button>
          </Box>
        ) : (
          <img
            src={data && data.data && data.data.fileUrl && data.data.fileUrl}
            alt="test demo"
            style={{ width: "100%", borderRadius: "5px" }}
          />
        )}
      </Box>
    </Container>
  );
}

export default ChapterItemViewDrawer;
