// App.js or MainContent.js

import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Components/Home/Home";
import PageNotFound from "../Constrant/PageNotFount";

import Courses from "../Components/LMS/Courses/Courses";
import ViewCourses from "../Components/LMS/Courses/ViewCourse/ViewCourses";

import ViewCoursesUser from "../Components/LMS/CoursesUsers/ViewCoursesUser";

import Login from "../Components/Authentication/Login/Login";
import ProtectRoute from "./ProtectRoute";
import Forgotpassword from "../Components/Authentication/Forgotpassword/Forgotpassword";
import Resetpassword from "../Components/Authentication/Resetpassword/Resetpassword";
import Navbar from "../Components/Navbar/Navbar";
import Explore from "../Components/LMS/Explore/Explore";
import Favourite from "../Components/LMS/Favourite/Favourite";

import Cookies from "js-cookie";
import Settings from "../Components/Navbar/Settings/Settings";
import Profile from "../Components/Navbar/Profile/Profile";
import LMS from "../Components/LMS/LMS";
import Dashboard from "../Components/LMS/Dashboard/Dashboard";

import TabButtons from "../Components/Navbar/TabButtons";
import Sop from "../Components/KMS/SOP/Sop";
import Repository from "../Components/KMS/Repository/Repository";
import Contributions from "../Components/KMS/Contributions/Contributions";
import ViewContribute from "../Components/KMS/Contributions/ViewContribution/ViewContribute";
import Assessment from "../Components/LMS/Assessment/Assessment";
import CoursesUser from "../Components/LMS/CoursesUsers/CoursesUser";
import MisDashboard from "../Components/LMS/MisDashboard/MisDashboard";
import MisDashboardKms from "../Components/KMS/MisDashboard/MisDashboardKms";
import MisUserDashboard from "../Components/LMS/MisUserDashboard/MisUserDashboard";
import MisUserKmsDashboard from "../Components/KMS/MisUserDashboard/MisUserKmsDashboard";
import Compliance from "../Components/LMS/Compliance/Compliance";
import AddCompliance from "../Components/LMS/Compliance/AddCompliance/AddCompliance";
import ViewCompliance from "../Components/LMS/Compliance/ViewCompliance/ViewCompliance";
import UpdateCompliance from "../Components/LMS/Compliance/UpdateCompliance/UpdateCompliance";
import MisAdminDashboard from "../Components/LMS/MisAdminDashboard/MisLmsAdminDashboard";
import MisLmsAdminDashboard from "../Components/LMS/MisAdminDashboard/MisLmsAdminDashboard";
import MisKmsAdminDashboard from "../Components/KMS/MisAdminDashboard/MisKmsAdminDashboard";
import Certificate from "../Components/LMS/Certificate/Certificate";
import Auth from "../Components/Auth/Auth";
import PreviewCourse from "../Components/LMS/Courses/PreviewCourse/PreviewCourse";
import PlanAccess from "../Constrant/PlanAccess/PlanAccess";
import axios from "axios";
import HrmsLoading from "../Constrant/HrmsLoading/HrmsLoading";
import CourseMaster from "../Components/Masters/CourseMaster/CourseMaster";

const AllRoutes = () => {
  // const userInfo = JSON.parse(Cookies.get("userinfo"));
  // const userrole = userInfo.userrole;

  let userInfo = null;
  let userrole = null;
  let companyemail = null
  // let planname = null;

  const userInfoCookie = Cookies.get("userinfo");

  if (userInfoCookie) {
    try {
      userInfo = JSON.parse(userInfoCookie);
      userrole = userInfo?.userrole;
      companyemail = userInfo?.companyemail
      // planname = userInfo?.planname;

      // console.log(userInfo, "userinfo from all routes")
    } catch (error) {
      console.error("Error parsing userinfo cookie:", error);
    }
  }
 const plannameLocal = localStorage.getItem("planname") || ""
  const [planname, setPlanname] = React.useState(plannameLocal);

  React.useEffect(()=>{
    axios.post(`https://klms.vliv.app/api/v1/plancheck`, {companyemail} )
    .then((res)=>{
      console.log(res.data.planname, "plan name resposnse")
      setPlanname(res.data.planname)
      localStorage.setItem("planname", res.data.planname)
    })
    .catch((err)=>console.log(err))
  },[companyemail])


  // For basic plan
  if ((userInfo && !planname) || (userInfo && planname == null) || planname == "Basic") {
    return (
      <>
        <Routes>
          <Route exact path="*" element={<PlanAccess />} />
        </Routes>
      </>
    );
  }
  if (planname == "Standard") {
    return (
      <>
        <Routes>
          <Route exact path="/signin" element={<Auth />} />
          <Route exact path="/forgotpassword" element={<Forgotpassword />} />
          <Route exact path="/resetpassword" element={<Resetpassword />} />

          <Route exact path="/" element={<ProtectRoute />}>
            <Route
              exact
              path="/"
              element={
                userrole == "masteradmin" ? (
                  <MisDashboardKms />
                ) : userrole == "HR" || userrole == "Manager" ? (
                  <MisKmsAdminDashboard />
                ) : (
                  <MisUserKmsDashboard />
                )
              }
            />

            <Route
              exact
              path="kms/dashboard"
              element={
                userrole == "masteradmin" ? (
                  <MisDashboardKms />
                ) : userrole == "HR" || userrole == "Manager" ? (
                  <MisKmsAdminDashboard />
                ) : (
                  <MisUserKmsDashboard />
                )
              }
            />

            {<Route exact path="kms/repository" element={<Repository />} />}
            <Route exact path="kms/contributions" element={<Contributions />} />
            <Route
              exact
              path="kms/contributions/view/:id"
              element={<ViewContribute />}
            />

            <Route exact path="profile" element={<Profile />} />
            <Route exact path="settings" element={<Settings />} />

            {/* Below Sop Route start */}
            <Route exact path="kms/sop" element={<Sop />} />

            <Route  path="/lms/dashboard" element={<PlanAccess />} />

            <Route exact path="/hrms" element={<HrmsLoading />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </>
    );
  }

  if (planname == "Premium") {
    return (
      <>
        <Routes>
          <Route exact path="/signin" element={<Auth />} />
          <Route exact path="/forgotpassword" element={<Forgotpassword />} />
          <Route exact path="/resetpassword" element={<Resetpassword />} />

          <Route exact path="/" element={<ProtectRoute />}>
            <Route
              exact
              path="/"
              element={
                userrole == "masteradmin" ? (
                  <MisDashboard />
                ) : userrole == "HR" || userrole == "Manager" ? (
                  <MisLmsAdminDashboard />
                ) : (
                  <MisUserDashboard />
                )
              }
            />

            <Route
              exact
              path="kms/dashboard"
              element={
                userrole == "masteradmin" ? (
                  <MisDashboardKms />
                ) : userrole == "HR" || userrole == "Manager" ? (
                  <MisKmsAdminDashboard />
                ) : (
                  <MisUserKmsDashboard />
                )
              }
            />

            {<Route exact path="kms/repository" element={<Repository />} />}
            <Route exact path="kms/contributions" element={<Contributions />} />
            <Route
              exact
              path="kms/contributions/view/:id"
              element={<ViewContribute />}
            />

            <Route
              exact
              path="lms/dashboard"
              element={
                userrole == "masteradmin" ? (
                  <MisDashboard />
                ) : userrole == "HR" || userrole == "Manager" ? (
                  <MisLmsAdminDashboard />
                ) : (
                  <MisUserDashboard />
                )
              }
            />
            <Route exact path="lms/explore" element={<Explore />} />
            <Route exact path="lms/favourite" element={<Favourite />} />
            <Route exact path="lms/assessment" element={<Assessment />} />
            <Route exact path="/lms/compliance" element={<Compliance />} />
            {(userrole == "masteradmin" ||
              userrole == "HR" ||
              userrole == "Manager") && (
              <Route
                exact
                path="/lms/addcompliance"
                element={<AddCompliance />}
              />
            )}
            {(userrole == "masteradmin" ||
              userrole == "HR" ||
              userrole == "Manager") && (
              <Route
                exact
                path="/lms/updatecompliance/:id"
                element={<UpdateCompliance />}
              />
            )}

            <Route
              exact
              path="/lms/viewcompliance/:id"
              element={<ViewCompliance />}
            />
            {<Route exact path="/lms/certificate" element={<Certificate />} />}

            <Route exact path="profile" element={<Profile />} />
            <Route exact path="settings" element={<Settings />} />

            {/* For lms course routes */}
            {(userrole == "masteradmin" ||
              userrole == "HR" ||
              userrole == "Manager") && (
              <Route exact path="lms/admin/courses" element={<Courses />} />
            )}
            {(userrole == "masteradmin" ||
              userrole == "HR" ||
              userrole == "Manager") && (
              <Route
                exact
                path="lms/admin/courses/view/:id"
                element={<ViewCourses />}
              />
            )}
            <Route exact path="lms/view/courses" element={<CoursesUser />} />
            <Route
              exact
              path="lms/view/courses/:id"
              element={<ViewCoursesUser />}
            />
            <Route
              exact
              path="lms/preview/courses/:id"
              element={<PreviewCourse />}
            />

          <Route exact path="/settings/course" element={<CourseMaster />} />

            {/* Below Sop Route start */}
            <Route exact path="kms/sop" element={<Sop />} />

            <Route exact path="/hrms" element={<HrmsLoading />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </>
    );
  }

  return (
    <>
      <Routes>
        {/* <Route exact path="/" element={<Auth />} /> */}

        <Route exact path="/signin" element={<Auth />} />
        {/* <Route exact path="/" element={<Navigate to="/login" replace />} /> */}
        <Route exact path="/forgotpassword" element={<Forgotpassword />} />
        <Route exact path="/resetpassword" element={<Resetpassword />} />
        <Route exact path="/hrms" element={<HrmsLoading />} />
        <Route exact path="/" element={<ProtectRoute />}>
          <Route path="*" element={<PageNotFound />} />
     

        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
