// File: /path_to_component/Repository.js

import React, { useState } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import ComplianceDashboard from "./ComplianceDashboard";
import Report from "./Report/Report";
import Cookies from "js-cookie";

function Compliance() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const userrole = userInfo.userrole;

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Compliance" />
        {userrole == "masteradmin" &&  <Tab label="Report" />}
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {selectedTab === 0 && <ComplianceDashboard />}
        {userrole == "masteradmin" && selectedTab === 1 && <Report />}
      </Box>
    </Box>
  );
}

export default Compliance;
