import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import UploadVideoDrawer from "../../Videos/UploadVideoDrawer";
import ChapterDrawer from "./ChapterDrawer";
import Cookies from "js-cookie";

function CourseDrawer() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;

  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [data, setData] = useState({
    vid: "",
    coursename: "",
    couseduration: "",
    coursedesction: "",
    thumbnail: "",
    category: "",
    tag: [],
    user: "x",
    userid: "x",
    company: "x",
    companyid: "x",
  });
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  //   console.log(selectedVideos, "selectedVideos");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  //   const [selectedFile, setSelectedFile] = useState(null);
  //   const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const [radiovalue, setRadiovalue] = useState("");

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        //
        setData((prev) => ({
          ...prev,
          tag: [...tags, newTag],
        }));
        //
      }
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
    //
    setData((prev) => ({
      ...prev,
      tag: tags.filter((tag) => tag !== tagToDelete),
    }));
    //
  };

  // Fetch videos
  // useEffect(() => {
  //   axios

  //     .post(`/video/getall`, { companyid: "x" })

  //     .then((res) => {
  //       setVideos(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const handleCheckboxChange = (videoId) => {
    // Check if the video is already in the selectedVideos array
    const isSelected = selectedVideos.includes(videoId);
    if (isSelected) {
      // If it is, remove it
      setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
      const checkbox = selectedVideos.filter((id) => id !== videoId);
      setData((prev) => ({
        ...prev,
        vid: checkbox,
      }));
    } else {
      // If it's not, add it
      setSelectedVideos([...selectedVideos, videoId]);

      const checkbox = [...selectedVideos, videoId];
      setData((prev) => ({
        ...prev,
        vid: checkbox,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB
    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]); // Assuming input type is file and accepts only one image

    axios

      .post("https://klms.vliv.app/video/upload", formData, {

        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data.file, "thumbnail data");

        setData((prev) => ({
          ...prev,
          thumbnail: res.data.file,
        }));

        // To store file size inside storage table
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e, name) => {
    setData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    axios


      .post(`/course/add`, data)
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };


  const handleChapterData = (chapterData) => {
    // Handle the received data from ChapterDrawer
    setData((prev) => ({
      ...prev,
      ...chapterData,
    }));
    if(chapterData){
      setIsDrawerOpen(!isDrawerOpen)
    }
  };
  console.log(data, "data in course drawer");

  return (
    <Container sx={{ padding: "5px" }}>
      <Button
        sx={{ bgcolor: "black", color: "white", padding: "5px" }}
        onClick={handleSubmit}
      >
        Add course
      </Button>
      <Box sx={{ marginBottom: "10px" }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="coursename"
          label="Course Name"
          name="coursename"
          value={data.coursename}
          onChange={(e) => handleChange(e, "coursename")}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="couseduration"
          label="Course Duration"
          name="couseduration"
          value={data.couseduration}
          onChange={(e) => handleChange(e, "couseduration")}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="coursedesction"
          label="Description"
          name="coursedesction"
          multiline
          value={data.coursedesction}
          onChange={(e) => handleChange(e, "coursedesction")}
        />
        <FormControl variant="outlined" fullWidth margin="normal" required>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={data.category}
            onChange={(e) => handleChange(e, "category")}
            label="Category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="category1">Category 1</MenuItem>
            <MenuItem value="category2">Category 2</MenuItem>
            <MenuItem value="category3">Category 3</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="tag"
          label="Tag"
          name="tag"
          value={tagInput}
          onChange={handleTagInputChange}
          onKeyPress={handleTagInputKeyPress}
          helperText="Press 'Enter' or ',' to add a tag"
        />
        <Stack
          direction="row"
          spacing={1}
          flexWrap="wrap"
          sx={{ marginBottom: "5px" }}
        >
          {tags.length > 0 &&
            tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={handleDeleteTag(tag)}
                color="primary"
                sx={{ margin: 0.5 }}
              />
            ))}
        </Stack>

        <label>Upload Thumbnail</label>
        <br />
        <input type="file" onChange={handleFileChange} />
      </Box>
      <Box>
        {/* this is video get funtionality toshow in drawer */}


        {/* <Grid container spacing={2}>
          {videos.map((video) => (
            <Grid item xs={12} sm={6} md={6} key={video.id}>
              <Box sx={{ padding: "10px", borderRadius: "4px" }}>
            
                <Card
                  sx={{
                    width: "100%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "8px",
                  }}
                >
                  <ReactPlayer controls url={video.src} width="100%" />
                </Card>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(video.id)}
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {video.title}
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid> */}
     <Button onClick={toggleDrawer} sx={{background:"black", color:"white"}}>Add Chapter</Button>
     <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <Container sx={{ width: 550 }}>
          <ChapterDrawer onChapterSubmit={handleChapterData} /> {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>
      
      </Box>
    </Container>
  );
}

export default CourseDrawer;
