import { Box, Card, Container, Grid, Modal, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaRegFileAlt, FaRegHandshake } from "react-icons/fa";
import { LuFileCheck2, LuFileClock } from "react-icons/lu";
import { FaFileCircleXmark } from "react-icons/fa6";
import { DataGrid } from "@mui/x-data-grid";
import {
  MdEdit,
  MdOutlineAssessment,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import ContributeModalTabsUpdate from "../Contributions/MyContributes/UpdateContribution/ContributeModalTabsUpdate";
import { useNavigate } from "react-router-dom";
import { GoTag } from "react-icons/go";
import CanvasJSReact from "@canvasjs/react-charts";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DashboardView() {
  const navigate = useNavigate();

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  // For kms user Dashboard use states
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [approve, setApprove] = useState(0);
  const [pending, setPending] = useState(0);
  const [reject, setReject] = useState(0);
  const [greeting, setGreeting] = useState("");

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [yearlyData, setYearlyData] = useState([]);

  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good Morning!");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon!");
    } else {
      setGreeting("Good Evening!");
    }

    axios
      .post(`https://klms.vliv.app/contribution/get`, {
        companyid,
        userid,
      })
      .then((res) => {
        console.log(res.data, "my contribution data");
        setData(res.data.length > 0 ? res.data : []);
        // To get Appved data
        const approveData = res.data.filter((elem) => elem.status === "Approved").length;
        const pendingData = res.data.filter((elem) => elem.status === "Pending").length;
        const rejectData = res.data.filter((elem) => elem.status === "Rejected").length;

        setApprove(approveData);
        setPending(pendingData);
        setReject(rejectData);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const year = new Date(item.createdAt).getFullYear();
      return year === selectedYear;
    });

    const monthlyCounts = new Array(12).fill(0);
    filteredData.forEach((item) => {
      const month = new Date(item.createdAt).getMonth();
      monthlyCounts[month]++;
    });

    const formattedData = monthlyCounts.map((count, index) => ({
      label: new Date(0, index).toLocaleString('default', { month: 'short' }),
      y: count,
    }));

    setYearlyData(formattedData);
  }, [data, selectedYear]);

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      
    },
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      
      renderCell: (params) => {
        let color;
        let background;
        let borderRadius;
        let padding;
        switch (params.value) {
          case "Pending":
            color = "#B54706";
            background = "#FDB022";
            borderRadius = "5px";
            padding = "2px";
            break;
          case "Approved":
            color = "#05804C";
            background = "#32D583";
            borderRadius = "5px";
            padding = "2px";
            break;
          default:
            color = "#700110";
            background = "#D92D20";
            borderRadius = "5px";
            padding = "2px";
            break;
        }
        return (
          <span style={{ color, background, borderRadius, padding }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
        <>
          <MdOutlineRemoveRedEye
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleViewButton(params.row.id);
            }}
            style={{ width: "17px", height: "17px", marginRight: "5px" }}
          />

          <MdEdit
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditButtonClick(params.row.id);
            }}
            style={{ width: "17px", height: "17px" }}
          />
        </>
      ),
    },
  ];

  const handleTriggerUpdateContribute = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleEditButtonClick = (id) => {
    setSelectedModuleId(id);
    setOpenEditModal(true);
  };

  const handleViewButton = (id) => {
    navigate(`/kms/contributions/view/${id}`);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const chartOptions = {
    title: {
      text: `Contributions in ${selectedYear}`
    },
    axisX: {
      title: "Months"
    },
    axisY: {
      title: "Number of Contributions"
    },
    data: [{
      type: "bar",
      dataPoints: yearlyData
    }]
  };

  const years = Array.from(new Set(data.map(item => new Date(item.createdAt).getFullYear()))).sort();

  return (
    <Box sx={{ width: "100%", height: "100%", padding: 2 }}>
      <Box sx={{ width: "100%", display: "flex", gap: "1%" }}>
      <Box sx={{ width: "100%", position: "relative" }}>
            <Box>
              {greeting == "Good Morning!" ? (
                <img
                  src={require("../../../assets/morning.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : greeting == "Good Afternoon!" ? (
                <img
                  src={require("../../../assets/afternoon.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : (
                <img
                  src={require("../../../assets/night.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "25px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 30,
                // left: "10vh",
                color: "#FFFF",
                fontWeight: "bold",
                zIndex: 1,
                background:"black",
                padding:"10px 10px 10px 50px",
                opacity:0.7,
                borderRadius:"10px"
              }}
            >
              <Typography
                variant="h4"
                sx={{
                //   position: "absolute",
                //   top: 30,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                //   zIndex: 1,
                }}
              >
                {greeting}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                //   position: "absolute",
                //   top: 60,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                  fontSize: "20px",
                //   zIndex: 1,
                }}
              >
                Howdy, {username}
              </Typography>
            </Box>
          </Box>
      </Box>

      <Grid container sx={{}}>
  <Grid item lg={7} md={7} sm={12} xs={12} p={1} >
    <Box sx={{height:"40vh"}}>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
      <Typography variant="h6">Contributions by Year</Typography>
      <FormControl variant="outlined" sx={{ }}>
        <InputLabel>Year</InputLabel>
        <Select value={selectedYear} onChange={handleYearChange} label="Year">
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Box>
      <Box >
      <CanvasJSChart options={chartOptions} containerProps={{ width: "100%", height: "30vh" }}/>
    </Box>
    </Box>
   
  </Grid>

  <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
    <Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1%" }}>
        <Box
          sx={{
            background: "#B9E6FE",
            width: "100%",
            textAlign: "center",
            borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "20vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 1,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <FaRegHandshake
              style={{
                position: "absolute",
                top: "69%",
                left: "12%",
                transform: "translate(-50%, -50%)",
                fontSize: "140px",
                opacity: 0.2,
              }}
            />
            <Typography sx={{ fontSize: "17px", mt: 1 }}>
              My Contributions
            </Typography>
            <Box>
              <Typography sx={{ padding: 1 }}>
                <FaRegFileAlt
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#0086C9",
                  }}
                />
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "35px", mt: 1 }}
              >
                {+data.length}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#A6F4C5",
            width: "100%",
            textAlign: "center",
            borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "20vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 1,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <GoTag
              style={{
                position: "absolute",
                top: "69%",
                left: "12%",
                transform: "translate(-50%, -50%)",
                fontSize: "100px",
                opacity: 0.2,
              }}
            />
            <Typography sx={{ fontSize: "17px", mt: 1 }}>
              Approve
            </Typography>
            <Box>
              <Typography sx={{ padding: 1 }}>
                <LuFileCheck2
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#12B76A",
                  }}
                />
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "35px", mt: 1 }}
              >
                {+approve}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#FEDF89",
            width: "100%",
            textAlign: "center",
            borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "20vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 1,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <LuFileClock
              style={{
                position: "absolute",
                top: "69%",
                left: "12%",
                transform: "translate(-50%, -50%)",
                fontSize: "100px",
                opacity: 0.2,
              }}
            />
            <Typography sx={{ fontSize: "17px", mt: 1 }}>
              Pending
            </Typography>
            <Box>
              <Typography sx={{ padding: 1 }}>
                <LuFileClock
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#F79009",
                  }}
                />
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "35px", mt: 1 }}
              >
                {+pending}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#FECDCA",
            width: "100%",
            textAlign: "center",
            borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "20vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 1,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <FaFileCircleXmark
              style={{
                position: "absolute",
                top: "69%",
                left: "12%",
                transform: "translate(-50%, -50%)",
                fontSize: "100px",
                opacity: 0.2,
              }}
            />
            <Typography sx={{ fontSize: "17px", mt: 1 }}>
              Rejected
            </Typography>
            <Box>
              <Typography sx={{ padding: 1 }}>
                <FaFileCircleXmark
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#D92D20",
                  }}
                />
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "35px", mt: 1 }}
              >
                {+reject}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>


      {/* For table */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#FDFDFD",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "10px",
          padding: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ mt: 2, mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
          >
            <MdOutlineAssessment />
            <span> My Contributions</span>
          </Typography>{" "}
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              fontWeight: "bold",
              color: "#026AA2",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/kms/contributions`)}
          >
            <span> View All</span>
          </Typography>{" "}
        </Box>

        <Box sx={{ borderRadius: 5 }}>
          <DataGrid
           //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
          />
        </Box>
      </Box>

      {/* For Update modal */}
      <Modal
        open={openEditModal}
        onClose={handleTriggerUpdateContribute}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <ContributeModalTabsUpdate
            id={selectedModuleId}
            refresh={refresh}
            setRefresh={setRefresh}
            handleTriggerUpdateContribute={handleTriggerUpdateContribute}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default DashboardView;
