import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios';
import Cookies from "js-cookie";

function CourseMaster() {
    const userInfo = JSON.parse(Cookies.get("userinfo"));
    const companyid = userInfo.companyid;
    const email = userInfo.email;
    const username = userInfo.username;
    const userid = userInfo.userid;
    const userrole = userInfo.userrole;
    const department = userInfo.department;

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://klms.vliv.app/master/get', {companyid});
      console.log(response.data, "get cat")
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const addCategory = async () => {
    if (!category) return;
    //  console.log(category)
    setLoading(true);
    try {
      const response = await axios.post('https://klms.vliv.app/master/add', { category, companyid, email, username, userid, userrole })
    //   setCategories([...categories, response.data]);
    alert(response.data.message)
     setRefresh(!refresh)
      setCategory('');
    } catch (error) {
      console.error('Error adding category:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async (id) => {
    setLoading(true);
    try {
      await axios.post(`/master/delete`, {id}); // Replace with your API endpoint
      setCategories(categories.filter((cat) => cat.id !== id));
    } catch (error) {
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [refresh]);

  return (
    <Container maxWidth="xl">
      <Box
        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        padding="10px"
        marginTop="20px"
        borderRadius="15px"
      >
        <Typography className="colored-heading">Course Categories</Typography>
        <Divider />
        <br />
        <Stack direction="row" gap={5} justifyContent="space-between">
          <Box padding="10px" marginTop="20px" width="40%">
            <h4 style={{ marginBottom: '5px' }}>Add Category</h4>
            <TextField
              sx={{ width: '70%' }}
              fullWidth
              className="formInputs"
              placeholder="Create Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={addCategory}>
                      <AddCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              border="1px solid black"
              padding="10px"
              marginTop="20px"
            >
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                categories.length > 0 &&
                categories.map((elem) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    padding="10px"
                    key={elem.id}
                  >
                    {elem.category}
                    <IconButton
                      sx={{
                        color: 'red',
                        height: '2px',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteCategory(elem.id);
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

export default CourseMaster;
