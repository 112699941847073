import { Box, Button, Typography } from "@mui/material";
import React from "react";
import accessgif from "../../assets/accessgif.gif";
import Cookies from "js-cookie";



function PlanAccess() {
  // const userInfo = JSON.parse(Cookies.get("userinfo"));
  // const companyid = userInfo.companyid;
  // const userrole = userInfo.userrole;

  let userInfo = null;
let userrole = null;
let planname = null;
let companyemail = null

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
    // planname = userInfo?.planname;
    companyemail = userInfo?.companyemail

    // Get planname from locak storage
    planname = localStorage.getItem("planname")
    console.log(planname, "planname");
    console.log(userrole, "user role")
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "90vh",
          mt: -1,
          paddingTop: 4,
          paddingBottom: 2,
          gap: 0.5,
        }}
      >
        <Box>
          <img
            src={accessgif}
            alt="No Gif"
            width="450"
            height="400"
            style={
              {
                // border: "solid 1px black"
              }
            }
          />
        </Box>

        {userrole == "masteradmin" ? (
          <Box sx={{ display: "flex", flexDirection: "column" , justifyContent:"center", alignItems:"center"}}>
            <Typography
              sx={{ fontSize: "22px", color: "#37668F", fontWeight: "600" }}
            >
              You don't have access
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              Kindly upgrade your plan
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                sx={{
                  border: "solid 1px grey",
                  backgroundColor: "#37668F",
                  color: "#FFFF",
                  // width: "100px",
                  borderRadius: "5px",
                  // height:'40px',
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "4px 16px 4px 16px",
                }}
                //   onClick={handleSubmit}
                href="https://vliv.app/frontend/settings"
              >
                Upgrade
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column",  justifyContent:"center", alignItems:"center" }}>
            <Typography
              sx={{ fontSize: "22px", color: "#37668F", fontWeight: "600" }}
            >
              You don't have access
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              Kindly contact to your boss
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default PlanAccess;
