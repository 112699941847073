import { Box, Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Select from "react-select";
import { IoCloseCircleOutline } from "react-icons/io5";

function EditModuleDrawer({ setRefresh, refresh, handleTriggerModule, id }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  // console.log(id, "id");

  const [data, setData] = useState({
    name: "",
    department: "",
    access: [],
    companyid,
    username,
    userid,
    email,
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    // get data by id
    axios
      .post(`https://klms.vliv.app/repomodule/getbyid`, { id })
      .then((res) => {
        setData(res.data[0]);

        // Fetch employees for the retrieved department
        const department = res.data[0].department;
        if (department) {
          axios
            .post(`https://klms.vliv.app/repomodule/employee`, {
              companyid,
              department,
            })
            .then((employeeRes) => {
              const mappedEmployeeData = employeeRes.data.map((item) => ({
                value: item.email,
                label: item.username,
              }));
              setEmployeeData(mappedEmployeeData);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));

    // To get department (update 19-07-2024 Now department is removed it is auto selected and comes from add repo button no need this below function for now)
    axios
      .post(`https://klms.vliv.app/repomodule/departments`, { companyid })
      .then((res) => {
        const uniqueDepartments = Array.from(
          new Set(res.data.map((item) => item.department))
        ).map((department) => ({
          value: department,
          label: department,
        }));
        setDepartmentData(uniqueDepartments);
      })
      .catch((err) => console.log(err));
  }, [companyid, id]);

  // Department select
  const handleDepartmentSelectChange = (selectedOption) => {
    setData((prev) => ({
      ...prev,
      department: selectedOption.value,
    }));

    // To get that employee we have to pass the department
    axios
      .post(`https://klms.vliv.app/repomodule/employee`, {
        companyid,
        department: selectedOption.value,
      })
      .then((res) => {
        const mappedData = res.data.map((item) => ({
          value: item.email,
          label: item.username,
        }));
        setEmployeeData(mappedData);
      })
      .catch((err) => console.log(err));
  };

  // Employee select
  const handleEmployeeSelectChange = (selectedOptions) => {
    const selectedEmails = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setData((prev) => ({
      ...prev,
      access: selectedEmails,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (data.name === "") {
      return alert("Please enter module name");
    }
    axios
      .post(`https://klms.vliv.app/repomodule/update`, data)
      .then((res) => {
        alert(res.data.message);
        handleTriggerModule();
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  // console.log(data);

  return (
    <>
      <Container>
        <Box sx={{ padding: "5px" }}>
          <IoCloseCircleOutline
            style={{
              width: "30px",
              height: "30px",
              color: "#f03a52",
              marginLeft: "-3vw",
            }}
            onClick={handleTriggerModule}
          />
        </Box>
        <Box>
          <h2>Update Repository</h2>
        </Box>
        <Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Repo Name"
            name="name"
            value={data.name}
            onChange={handleChange}
          />
          {/* <Box sx={{ marginBottom: 1 }}>
            <Select
              options={departmentData}
              onChange={handleDepartmentSelectChange}
              placeholder="Select Department"
              value={
                departmentData.find(
                  (option) => option.value === data.department
                ) || null
              }
            />
          </Box> */}
          <Box sx={{ marginBottom: 1 }}>
            <Select
              isMulti
              options={employeeData}
              onChange={handleEmployeeSelectChange}
              placeholder="Select Employees"
              value={employeeData.filter((option) =>
                data.access.includes(option.value)
              )}
            />
          </Box>
        </Box>
        <Box>
          <Button
            sx={{
              backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
              color: "white",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
              width: "100%",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default EditModuleDrawer;
