import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Box,
  Card,
  Typography,
  TextField,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

function AccessModuleDrawer({ id, setIdAccess, handleTriggerModule }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [moduleData, setModuleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch all users to extract name from the email
    axios
      .post(`/course/allusers`, { companyid: companyid.toString() })
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => console.log(err));

    // Fetch repo module by id
    axios
      .post(`/repomodule/getbyid`, { companyid: companyid.toString(), id })
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [companyid, id, refresh]);

  // Helper function to get name by email
  const getNameByEmail = (email) => {
    const user = userData.find((user) => user.email === email);
    return user ? user.username : email;
  };

  // Filter moduleData access by search query
  const filteredAccess = moduleData.length > 0 && moduleData[0].access
    ? moduleData[0].access.filter((email) => {
        const username = getNameByEmail(email).toLowerCase();
        return username.includes(searchQuery.toLowerCase());
      })
    : [];

    // To remove email from the access
    const handleRemove = (email)=>{
      console.log(email, id)
      axios.post(`https://klms.vliv.app/repomodule/removeemail`, {id, email})
      .then((res)=>{
        alert(res.data.message)
        handleTriggerModule()
      })
      .catch((err)=>console.log(err))
    }

  return (
    <Container>
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={handleTriggerModule}
        />
      </Box>
      <Box sx={{ mb: 4 }}>
        <h2>Access Control</h2>
      </Box>
      <TextField
        label="Search by username"
        variant="outlined"
        fullWidth
        sx={{ mb: 1 }}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}>
        {filteredAccess.length > 0
          ? filteredAccess.map((email, index) => (
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
              <Card key={index} sx={{ padding: "10px", display: "flex", alignItems: "center", gap: "10px", width:"100%" }}>
                <Typography>{getNameByEmail(email)}</Typography>
              </Card>
              <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={() => handleRemove(email)}
        />
              </Box>
            ))
          : <Typography>No users found</Typography>
        }
      </Box>
    </Container>
  );
}

export default AccessModuleDrawer;
