import { useState } from 'react';
import { Avatar, Button, TextField, Paper, Box, Grid, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import venturesathilogo from "../../../assets/venturesathi-logo.png";
import { Link, useNavigate } from "react-router-dom";
import Copyright from '../../../Constrant/Copyright';
import { useFormik } from "formik";
import { forgotpasswordSchema, loginSchema } from '../../../schemas';
// import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { notifySuccess } from '../../../Constrant/toastAlert';
import { userLogin } from '../../../redux/actions/login.action';
import { useDispatch } from 'react-redux';
import loginimage from "../../../assets/venturesathi-logo.png"

function Forgotpassword() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "test@gmail.com",
      },
      validationSchema: forgotpasswordSchema,
      onSubmit: (values, action) => {
        // dispatch(userLogin(values, navigate));
        console.log("values", values);
        action.resetForm();
      },
    });

  return (
    <Grid container component="main" sx={{ height: '100vh', padding: "0.5rem" }}>
      <Grid item xs={false} sm={4} md={6}
        sx={{
          backgroundImage: `url(${require('../../../assets/signup-image.png')})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',

        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box sx={{ mx: 4, my: 2 }}>
          <img
            src={venturesathilogo}
            alt="Venturesathi Logo"
            className='venturesathi_logo'
          />
        </Box>
        <Box
          sx={{
            my: 2,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main', fontSize: '5rem' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className='loginwelcometext'>
            Welcome
          </Typography>
          <Typography component="h1" variant="h5" className='productname'>
            Knowledge And Learning Management System
          </Typography>
          <Typography component="h1" variant="h6" className='logintext'>
            Please Provide your email to Reset Password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={Boolean(touched.email) && errors.email}
            />


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              className='commonBtn'
            >
              Forget Password
            </Button>

            <Grid container>
              <Grid item xs>
                {/* <Typography variant="span" >
                  <Link to="/forgotpassword" className='linktext'>Forgot password?</Link>
                </Typography> */}
              </Grid>
              <Grid item>
                <Typography variant="span" >
                  Remember password ?
                  <Link to="/login" className='linktext'>&nbsp;Login</Link>
                </Typography>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 4 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Forgotpassword;