import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import Cookies from "js-cookie";
import { CiLink } from "react-icons/ci";
import {
  VideoLibrary as VideoLibraryIcon,
  InsertDriveFile as FileIcon,
} from "@mui/icons-material";
import {
  CloudUpload as UploadIcon,
  Link as UrlIcon,
} from "@mui/icons-material";

function UpdateChapter({
  courseid,
  chapterItem,
  setRefresh,
  refresh,
  isUpdateDrawerOpen,
  setIsUpdateDrawerOpen,
}) {
  // console.log(courseid, "course id")
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;

  // console.log(companyemail, "companyemail");

  const [showUpload, setShowUpload] = useState(false);
  const [title, setTitle] = useState(chapterItem.title || "");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState(chapterItem.tags || []);
  const [tagInput, setTagInput] = useState("");
  const [fileUrl, setFileUrl] = useState(chapterItem.fileUrl || "");
  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);
  const [radiovalue, setRadiovalue] = useState("");
  const [radioForOption, setRadioForOption] = useState("");

  // const [refresh, setRefresh] = useState(false);
  const [description, setDescription] = useState(chapterItem.description || "");
  const [load, setLoad] = useState(false);
  //   Fetch videos
  //   useEffect(() => {
  //     axios
  //       .post(`/video/get`, { companyid: "x", userid: "x" })
  //       .then((res) => {
  //         setVideos(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }, [refresh]);

  const handleShowUploadView = () => {
    setShowUpload(true);
  };
  const handleHideUploadView = () => {
    setShowUpload(false);
  };
  const handleRadioToggle = (e) => {
    setRadiovalue(e.target.value);
  };
  const handleRadioFileVdoToggle = (e) => {
    setRadioForOption(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleFileChange = (event) => {
    setUploadBtnLoad(true);
    console.log("handle file change inside add chapter");
    // setSelectedFile(e.target.files[0]);
    const file = event.target.files[0];

    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploadBtnLoad(false);
      return;
    }

    if (file.type !== "video/mp4") {
      alert("Only .mp4 files are allowed. Please upload a valid .mp4 file.");
      setUploadBtnLoad(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFileUrl(res.data.file);
        console.log(res.data.file, "Video Uploaded url");
        setUploadBtnLoad(false);

        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFileChangeForFile = (event) => {
    setUploadBtnLoad(true);
    console.log("handle file change inside add chapter");
    // setSelectedFile(e.target.files[0]);
    const file = event.target.files[0];

    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploadBtnLoad(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFileUrl(res.data.file);
        console.log(res.data.file, "Video Uploaded url");
        setUploadBtnLoad(false);

        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  // To replace youtube url with embed
  const handleUrlFileChange = (e) => {
    const url = e.target.value;
    if (url.includes("watch?v=")) {
      let newUrl = url.replace("watch?v=", "embed/");
      const splitUrl = newUrl.split("=");
      newUrl = `${splitUrl[0]}`;
      setFileUrl(newUrl);

      console.log(newUrl, "new url");
    } else {
      setFileUrl(url);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (fileUrl == "") {
      return alert("kindly add video");
    }
    setLoad(true);
    const updatedChapterData = {
      courseid: courseid,
      id: chapterItem.id,
      title,
      description,
      tags,
      fileUrl,
    };

    axios
      .post("/course/updatechapter", updatedChapterData)
      .then((res) => {
        console.log(res.data, " update response");
        alert(res.data.message);
        setRefresh(!refresh);
        setIsUpdateDrawerOpen(!isUpdateDrawerOpen);
        setLoad(false);
      })
      .catch((err) => console.log(err));

    console.log({
      courseid: courseid,
      id: chapterItem.id,
      title,
      description,
      tags,
      fileUrl,
    });
  };

  // console.log(fileUrl, "file url");

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-around", padding:3, background:"#F4FEFE" }}>
        <Box>
          <Typography component="h1" variant="h5">
            Update Chapter
          </Typography>
          <form onSubmit={handleUpdate}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              sx={{ background: "#FFFF" }}
              onChange={handleTitleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={description}
              sx={{ background: "#FFFF" }}
              onChange={handleDescriptionChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="tag"
              label="Tag"
              name="tag"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyPress={handleTagInputKeyPress}
              // helperText="Press 'Enter' or ',' to add a tag"
              sx={{ background: "#FFFF" }}
            />
            <Typography
              color="textSecondary"
              sx={{ marginBottom: "8px", ml: 0.5 }}
            >
              Press Enter or ',' to add a tag
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              sx={{ marginBottom: "5px" }}
            >
              {tags.length > 0 &&
                tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={handleDeleteTag(tag)}
                    color="primary"
                    sx={{ margin: 0.5 }}
                  />
                ))}
            </Stack>

            {/* <hr /> */}

            <FormControl sx={{ mt: 0.5, mb: 0.5 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={handleRadioFileVdoToggle}
                orientation="horizontal"
              >
                <FormControlLabel
                  value="video"
                  control={
                    <Radio
                      icon={<VideoLibraryIcon />}
                      checkedIcon={<VideoLibraryIcon />}
                    />
                  }
                  label="Video"
                />
                <FormControlLabel
                  value="file"
                  control={
                    <Radio icon={<FileIcon />} checkedIcon={<FileIcon />} />
                  }
                  label="File"
                />
              </RadioGroup>
            </FormControl>
            {/* <hr /> */}
            {radioForOption == "video" && (
              <Box required sx={{ mt: 0.5, mb: 2 }}>
                <FormControl sx={{ mt: 0.5, mb: 0.5 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Choose
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={handleRadioToggle}
                  >
                    <FormControlLabel
                      value="upload"
                      control={
                        <Radio
                          icon={<UploadIcon />}
                          checkedIcon={<UploadIcon />}
                        />
                      }
                      label="Upload"
                    />
                    <FormControlLabel
                      value="url"
                      control={
                        <Radio icon={<UrlIcon />} checkedIcon={<UrlIcon />} />
                      }
                      label="Url"
                    />
                  </RadioGroup>
                </FormControl>

                {radiovalue == "upload" &&
                  (uploadBtnLoad ? (
                    <CircularProgress />
                  ) : (
                    <FormControl fullWidth margin="normal">
                      {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                      <input
                        accept="video/*"
                        style={{ display: "none" }}
                        id="video-file"
                        type="file"
                        onChange={handleFileChange}
                      />

                      <label htmlFor="video-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                      <Typography sx={{ color: "grey" }}>
                        Supported Type .mp4
                      </Typography>
                      {fileUrl && (
                        <Typography sx={{ color: "green" }}>
                          Uploaded
                        </Typography>
                      )}
                    </FormControl>
                  ))}

                {radiovalue == "url" && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="selectedFileUrl"
                    label="Enter video URL"
                    name="selectedFileUrl"
                    value={fileUrl}
                    // onChange={(e) => setFileUrl(e.target.value)}
                    onChange={handleUrlFileChange}
                  />
                )}
              </Box>
            )}

            {radioForOption == "file" &&
              (uploadBtnLoad ? (
                <CircularProgress />
              ) : (
                <FormControl
                  fullWidth
                  margin="normal"
                  required
                  sx={{ mt: 1, mb: 2 }}
                >
                  {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                  <input
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="pdf-file"
                    type="file"
                    onChange={handleFileChangeForFile}
                  />

                  <label htmlFor="pdf-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload File
                    </Button>
                  </label>
                  <Typography sx={{ color: "grey" }}>
                    Supported Type .pdf
                  </Typography>
                  {fileUrl && (
                    <a href={fileUrl} target="_blanck">
                      <CiLink />
                    </a>
                  )}
                </FormControl>
              ))}

            {load ? (
              <Button
                type="submit"
                fullWidth
                variant="secondary"
                sx={{background:"#f4eefe"}}
                // disabled={uploadBtnLoad}
              >
                <CircularProgress />
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={uploadBtnLoad}
              >
                Update
              </Button>
            )}
          </form>
        </Box>

        {/* <Box>
              <TiDeleteOutline
                onClick={handleHideUploadView}
                style={{ width: "30px", height: "30px" }}
              />
            </Box> */}
      </Box>
    </Box>
  );
}

export default UpdateChapter;
