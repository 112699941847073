import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import robot from "../../../../../assets/aibuffer.gif"

function CreatePrompts({ promptValue, setPromptvalue, setSelectedTab }) {
  const [topic, setTopic] = useState("");
  const [data, setData] = useState("");
  const [load, setLoad] = useState(false);

  const handleGenerate = () => {
    if (topic == "") {
      return alert("Please enter something");
    }
    setLoad(true);
    axios
      .post(`https://content-generator-l2w5.onrender.com/generate-content`, {
        input_text: topic,
      })
      .then((res) => {
        console.log(res.data, "prompt data");
        setData(res.data);

        setLoad(false);
      })
      .catch((err) => console.log(err));
  };

  const handleInsert = () => {
    if (data.response) {
      const formattedResponse = data.response
      .replace(/\*\s*(.+?)\s*\*/g, (match, p1) => `<strong>${p1.trim()}</strong><br/>`);
      
    setPromptvalue(formattedResponse);
      setSelectedTab(1);
    } else {
      alert("Kindly generate the response");
    }
  };

  const formatResponse = (text) => {
    return text.replace(/\*\s*(.+?)\s*\*/g, (match, p1) => `<strong>${p1.trim()}</strong><br/>`);
  };
  return (
    <Container>
      <Box sx={{ marginTop: 2 }}>
        <label>Topic</label>
        <Box
          sx={{
            display: "flex",
            marginBottom: 1,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <TextField
            label="Topic"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
            name="videourl"
            onChange={(e) => setTopic(e.target.value)}
          />
          {/* <FaArrowAltCircleUp
            onClick={handleGenerate}
            style={{
              width: "30px",
              height: "30px",
              margin: 10,
              cursor: "pointer",
            }}
          /> */}

          {/* <Button onClick={handleGenerate}>Generate</Button> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            maxHeight: "55vh",
            overflowY: "auto",
            height: "55vh",
          }}
        >
          {load ? (
     <img src={robot} width="400" height="350"/>
          ) : (
            <Typography sx={{ maxHeight: "55vh", overflowY: "auto" }}>
              {data.response && formatResponse(data.response)}
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Button
          sx={{
            border: "solid 1px grey",
            backgroundColor: "#37668F",
            "&:hover": {
              backgroundColor: "#37668F", // Prevent background color change on hover
            },
            color: "#FFFF",
            width: "100px",
            borderRadius: "5px",
          }}
          onClick={handleGenerate}
        >
          <b>Generate</b>
        </Button>

        <Button
          sx={{
            border: "solid 1px grey",
            backgroundColor: "#37668F",
            "&:hover": {
              backgroundColor: "#37668F", // Prevent background color change on hover
            },
            color: "#FFFF",
            width: "100px",
            borderRadius: "5px",
          }}
          onClick={handleInsert}
        >
          <b>Insert</b>
        </Button>
      </Box>
    </Container>
  );
}

export default CreatePrompts;
