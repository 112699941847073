import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

function ScoreAssessment({ id, setIdAccess, handleTriggerModule }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [questionId, setQuestionId] = useState([]);
  const [scoreCountData, setScoreCountData] = useState([]);
  const [passFailData, setPassFailData] = useState({}); // State to store pass/fail data

  useEffect(() => {
    // Get contribution by id
    axios
      .post(`/assessment/getbyid`, { id })
      .then((res) => {
        const emailsToFilter = res.data.access; // Array of emails like ["email1", "email2"]

        axios
          .post(`/course/allusers`, { companyid })
          .then((res1) => {
            const filteredData = res1.data.filter((elem) => {
              return emailsToFilter.includes(elem.email);
            });
            setData(filteredData); 

            const emails = filteredData.map((elem)=>{return elem.email})
            setSelectedEmail(emails)
          })
          .catch((err) => console.log(err));

        const parsedQuestions = res.data.questions.map((q) => JSON.parse(q).id);
        setQuestionId(parsedQuestions);
      })
      .catch((err) => console.log(err));
  }, [companyid, id]);

  useEffect(() => {
    // To calculate score
    if (questionId.length > 0 && selectedEmail.length > 0) {
      axios
        .post(`https://klms.vliv.app/assessment/scorecount`, {
          questionid: questionId,
          email: selectedEmail,
          companyid,
        })
        .then((res) => {
          setScoreCountData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [questionId, selectedEmail]);

  useEffect(() => {
    // Fetch pass/fail status for each user
    const fetchPassFailStatus = async () => {
      const passFailStatus = {};
      for (const row of data) {
        try {
          const res = await axios.post(`https://klms.vliv.app/assessment/getpassscore`, { assessmentid: id, userid: row.userid });
          passFailStatus[row.userid] = res.data[0].passfail;
        } catch (err) {
          console.log(err);
        }
      }
      setPassFailData(passFailStatus);
    };
    if (data.length > 0) {
      fetchPassFailStatus();
    }
  }, [data]);

  const handleSubmit = () => {
    // Placeholder for handleSubmit functionality
  };

  return (
    <>
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-1.5vw",
          }}
          onClick={handleTriggerModule}
        />
      </Box>
      <Box>
        <h2>Score Board</h2>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Score</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Result</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>
                    {
                      scoreCountData.filter(
                        (item) => item.email == row.email && item.answertype === "correct"
                      ).length
                    }
                    /{questionId.length}
                  </TableCell>
                  <TableCell>{passFailData[row.userid]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ScoreAssessment;
