import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaComments, FaFile } from "react-icons/fa";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CssBaseline,
  Typography,
  TableContainer,
  Paper,
  CircularProgress,
  Button,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  Box,
  Drawer,
} from "@mui/material";
import "./Module.css";
import AddModuleDrawer from "./AddModuleDrawer";
import Cookies from "js-cookie";
import EditModuleDrawer from "./EditModuleDrawer";
import { MdDeleteSweep, MdEdit } from "react-icons/md";
import { IoAccessibility, IoEyeOutline } from "react-icons/io5";
import AccessModuleDrawer from "./AccessModuleDrawer";
import ViewModuleWiseDrawer from "./ViewModuleWiseDrawer";

export default function ListTable() {
  const pdfRef = useRef(null);
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;
  // console.log(userrole, "userrole")

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openAddModule, setOpenAddModule] = useState(false);
  const [openEditModule, setOpenEditModule] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [accessId, setAccessId] = useState(null);
  const [openAccessModule, setOpenAccessModule] = useState(false);
  const [openViewContriDrawer, setOpenViewContriDrawer] = useState(false);
  const [moduleName, setModuleName] = useState("");

  //for for row selection multi delete start
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
  };

  const handleDelete = () => {
    if (userrole !== "masteradmin" && userrole != "HR" && userrole != "Manager") {
      return alert("You are not authorized to delete");
    }
    axios
      .post(`https://klms.vliv.app/repomodule/delete`, { ids: selectedRows })
      .then((res) => {
        // console.log(res.data, "deleted response");
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };
  //for for row selection multi delete end

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/repomodule/get`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        const filterData = res.data.filter((elem) => {
          return elem.department == department;
        });
        if (userrole == "masteradmin") {
          setData(res.data.length > 0 ? res.data : []);
        } else {
          setData(filterData);
        }

        console.log(res.data, "repo get");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const columns = [
    {
      field: "name",
      headerName: "Repo",
      flex: 1,
      
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
        <>
          <button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleViewContriution(params.row.name);
            }}
            style={{
              // height: "17px",
              background: "#37668F",
              marginRight: "5px",
              color: "white",
              padding: 1,
            }}
          >
            View
          </button>
          {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
            <button
              variant="contained"
              color="primary"
              onClick={(event) => {
                event.stopPropagation(); // Prevent row selection
                handleAccessButtonClick(params.row.id);
              }}
              style={{
                // height: "17px",
                background: "#37668F",
                marginRight: "5px",
                color: "white",
                padding: 1,
              }}
            >
              Access
            </button>
          )}
          {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
            <button
              variant="contained"
              color="primary"
              onClick={(event) => {
                event.stopPropagation(); // Prevent row selection
                handleEditButtonClick(params.row.id);
              }}
              style={{
                // height: "17px",
                background: "#37668F",
                marginRight: "5px",
                color: "white",
                padding: 1,
              }}
            >
              Update
            </button>
          )}
        </>
      ),
    },
    // {
    //   field: "access",
    //   headerName: "Access",
    //   flex: 1,
    //   
    //   renderCell: (params) => (
    //     <IoAccessibility
    //     variant="contained"
    //       color="primary"
    //       onClick={(event) => {
    //         event.stopPropagation(); // Prevent row selection
    //         handleAccessButtonClick(params.row.id);
    //       }} />
    //   ),
    // },
  ];

  const handleTriggerModule = () => {
    setOpenAddModule(!openAddModule);
  };

  const handleEditTriggerModule = () => {
    setOpenEditModule(!openEditModule);
  };

  const handleAccessTriggerModule = () => {
    setOpenAccessModule(!openAccessModule);
  };

  const handleEditButtonClick = (id) => {
    setSelectedModuleId(id);
    setOpenEditModule(true);
  };

  const handleAccessButtonClick = (id) => {
    setAccessId(id);
    setOpenAccessModule(true);
  };

  const handleViewContriution = (modulename) => {
    setModuleName(modulename);
    setOpenViewContriDrawer(!openViewContriDrawer);
    // console.log(modulename, "module name inside view contribution drawer")
  };

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // console.log(selectionModel, "selection model");

  return (
    <>
      <CssBaseline />

      <Typography variant="h6" gutterBottom>
        Repository
      </Typography>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ marginBottom: 2, width: 400 }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
          <Button
            sx={{
              backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
              color: "white",
              height: "37px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={handleTriggerModule}
          >
            Add Repo
          </Button>
        )}
        {selectedRows.length > 0 && (
          <MdDeleteSweep
            style={{ width: "25px", height: "25px", color: "red" }}
            onClick={handleDelete}
          />
        )}
      </div>

      <div ref={pdfRef}>
        <DataGrid
        // sx={{
        //   "& .MuiDataGrid-columnHeaders": {
        //     backgroundColor: "#F4FAFF ",
        //   },
        //   "& .MuiDataGrid-columnHeaderTitle": {
        //     color: "black",
        //     fontWeight: "bold",
        //   },
        //   "& .MuiDataGrid-columnHeader": {
        //     backgroundColor: "#F4FAFF",
        //   },
        //   "& .MuiDataGrid-columnHeader--sortable:hover": {
        //     backgroundColor: "#F4FAFF",
        //   },
        // }}
        autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            handleSelectionModelChange(newSelection);
          }}
        />
      </div>

      {/* For add module drawer */}
      <Drawer anchor="right" open={openAddModule} onClose={handleTriggerModule}>
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 500 }}>
          <AddModuleDrawer
            handleTriggerModule={handleTriggerModule}
            refresh={refresh}
            setRefresh={setRefresh}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* For Update module drawer */}

      <Drawer
        anchor="right"
        open={openEditModule}
        onClose={handleEditTriggerModule}
      >
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 500 }}>
          <EditModuleDrawer
            handleTriggerModule={handleEditTriggerModule}
            refresh={refresh}
            setRefresh={setRefresh}
            id={selectedModuleId}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* For access module drawer*/}
      <Drawer
        anchor="right"
        open={openAccessModule}
        onClose={handleAccessTriggerModule}
      >
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 500 }}>
          <AccessModuleDrawer
            handleTriggerModule={handleAccessTriggerModule}
            refresh={refresh}
            setRefresh={setRefresh}
            id={accessId}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* For view contributions based on the module (How many contributes are there in that particular contribution)*/}

      <Drawer
        anchor="right"
        open={openViewContriDrawer}
        onClose={handleViewContriution}
      >
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 700 }}>
          <ViewModuleWiseDrawer
            moduleName={moduleName}
            openViewContriDrawer={openViewContriDrawer}
            setOpenViewContriDrawer={setOpenViewContriDrawer}
          />
        </Container>
      </Drawer>
    </>
  );
}
