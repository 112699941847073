import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

function AssignUserDrawer({ id, setIdAccess, handleTriggerModule, refresh, setRefresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);

  useEffect(() => {
    axios
      .post(`/course/allusers`, { companyid })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));

    //Get contribution by id
    axios
      .post(`/assessment/getbyid`, { id })
      .then((res) => {
        //  console.log(res.data.access, "get by id cintributin data")

        setSelectedEmail(
          res.data && res.data.access != null ? res.data.access : []
        );
      })
      .catch((err) => console.log(err));
  }, [companyid, id]);
  // console.log(selectedEmail, 'selected email')

  const handleCheckboxChange = (email) => {
    setSelectedEmail((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((e) => e !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  const handleSubmit = () => {
    // console.log(selectedEmail, "selected email")
    // console.log(id, "id")
    axios
      .post(`https://klms.vliv.app/assessment/access`, {
        id,
        access: selectedEmail,
      })
      .then((res) => {
        alert(res.data.message);
        handleTriggerModule();
        setRefresh(!refresh)
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={handleTriggerModule}
        />
      </Box>
      <Box>
        <h2>Access Control</h2>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={
                        selectedEmail && selectedEmail.includes(row.email)
                      }
                      onChange={() => handleCheckboxChange(row.email)}
                    />
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.username}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 2, display: "block", mx: "auto" }}
        disabled={!selectedEmail}
      >
        Submit
      </Button> */}

        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
              color: "#FFFF",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={handleSubmit}
            disabled={!selectedEmail}
          >
            <b>Submit</b>
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "grey",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={handleTriggerModule}
          >
            <b>Cancel</b>
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default AssignUserDrawer;
