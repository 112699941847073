import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Cookies from "js-cookie";
import CertificateModal from "./CertificateModal"; // Import the modal component

export default function Certificate() {
  const pdfRef = useRef(null);
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const { companyid, email, username, userid, userrole, department } = userInfo;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openCertificateView, setOpenCertificateView] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/certificate/getall`, {
        companyid,
        userrole,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        console.log(res.data, "all assessment");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://klms.vliv.app/certificate/status`, {
        id,
        status: newStatus,
      })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const StatusCell = (params) => {
    const { id, status } = params.row;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={status === "active"}
            onChange={(e) => handleStatusChange(id, e.target.checked ? "active" : "inactive")}
          />
        }
        label={status === "active" ? "Active" : "Inactive"}
      />
    );
  };

  const columns = [
    {
      field: "username",
      headerName: "Name",
      flex: 1,
      
    },
    {
      field: "coursename",
      headerName: "Course name",
      flex: 1,
      
    },
    {
      field: "certificateid",
      headerName: "Certificate ID",
      flex: 1,
      
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      
      // valueGetter: (params) => formatDateToDDMMYYYY(params.row.createdAt),
    },
    (userrole === "masteradmin" ||  userrole == "HR" || userrole == "Manager") && {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => <StatusCell {...params} />,
    },
  ].filter(Boolean);

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value?.toString()?.toLowerCase()?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const handleRowClick = (params) => {
    setSelectedCertificate(params.row);
    setOpenCertificateView(true);
  };

  const filteredData = filterData();
  const rows = filteredData;

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography variant="h6" gutterBottom>
          Certificates
        </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div ref={pdfRef}>
          <DataGrid
           //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            // onSelectionModelChange={(newSelectionModel) => {
            //   setSelectionModel(newSelectionModel);
            // }}
            selectionModel={selectionModel}
            onRowClick={handleRowClick} // Add row click handler
          />
        </div>
        {selectedCertificate && (
          <CertificateModal
            open={openCertificateView}
            onClose={() => setOpenCertificateView(false)}
            certificate={selectedCertificate}
          />
        )}
      </Container>
    </>
  );
}
