import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import Cookies from "js-cookie";

function ChapterDrawer({ onChapterSubmit }) {

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;


  const [showUpload, setShowUpload] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadBtnLoad, setUploadBtnLoad] = useState(false);
  const [radiovalue, setRadiovalue] = useState("");
  const [radioForOption, setRadioForOption] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [description, setDescription] = useState("");

  //   Fetch videos
  //   useEffect(() => {
  //     axios
  //       .post(`/video/get`, { companyid: "x", userid: "x" })
  //       .then((res) => {
  //         setVideos(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }, [refresh]);

  const handleShowUploadView = () => {
    setShowUpload(true);
  };
  const handleHideUploadView = () => {
    setShowUpload(false);
  };
  const handleRadioToggle = (e) => {
    setRadiovalue(e.target.value);
  };
  const handleRadioFileVdoToggle = (e) => {
    setRadioForOption(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleFileChange = (event) => {
    setUploadBtnLoad(true);
    // setSelectedFile(e.target.files[0]);
    const file = event.target.files[0];
    if (file.size > 500 * 1024 * 1024) { // 500MB in bytes
      alert("File size exceeds 500MB. Please upload a file smaller than 500MB.");
      setUploadBtnLoad(false);
      return;
    }

    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    const formData = new FormData();
    formData.append("file", event.target.files[0]); // Assuming input type is file and accepts only one image

    axios
      .post("https://klms.vliv.app/video/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFileUrl(res.data.file);
        // console.log(res.data.file, "inside fileChange response");
        setUploadBtnLoad(false);

        // to store the file size inside storage table
        if (res.data.file) {
          axios
            .post(`https://klms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

    // To replace youtube url with embed
    const handleUrlFileChange = (e) => {
      const url = e.target.value;
      if (url.includes("watch?v=")) {
        let newUrl = url.replace("watch?v=", "embed/");
        const splitUrl = newUrl.split("=");
        newUrl = `${splitUrl[0]}`;
        setFileUrl(newUrl);
  
        console.log(newUrl, "new url");
      } else {
        setFileUrl(url);
      }
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(fileUrl == ""){

     return alert("kindly add video")
     }
    onChapterSubmit({
      chapterData: [
        {
          id: Math.random(),
          title,
          description,
          fileUrl,
          category,
          tags,
          itemdata: [],
        },
      ],
      
    });

    // axios
    //   .post(`/video/add`, {
    //     title: title,
    //     src: fileUrl,
    //     category: category,
    //     tag: tags,
    //     duration: "0",
    //     user: "x",
    //     userid: "x",
    //     company: "x",
    //     companyid: "x",
    //   })
    //   .then((res) => {
    //     console.log(res.data, "submit response");
    //     alert(res.data.message);
    //     setTitle("");
    //     setCategory("");
    //     setTags("");
    //     setFileUrl("");
    //     setRefresh(!refresh)
    //   })
    //   .catch((err) => console.log(err));
  };

  // console.log(fileUrl, "file url");

  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box>
          <Typography component="h1" variant="h5">
            Add Chapter
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={handleTitleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={description}
              onChange={handleDescriptionChange}
            />
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={category}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="category1">Category 1</MenuItem>
                <MenuItem value="category2">Category 2</MenuItem>
                <MenuItem value="category3">Category 3</MenuItem>
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="tag"
              label="Tag"
              name="tag"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyPress={handleTagInputKeyPress}
              helperText="Press 'Enter' or ',' to add a tag"
            />
            {radioForOption == "video" && (
              <Box>
                {radiovalue == "upload" &&
                  (uploadBtnLoad ? (
                    <CircularProgress />
                  ) : (
                    <FormControl fullWidth margin="normal" required>
                      {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                      <input
                        accept="video/*"
                        style={{ display: "none" }}
                        id="video-file"
                        type="file"
                        onChange={handleFileChange}
                      />

                      <label htmlFor="video-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </FormControl>
                  ))}

                {radiovalue == "url" && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="selectedFileUrl"
                    label="Enter video URL"
                    name="selectedFileUrl"
                    value={fileUrl}
                    onChange={handleUrlFileChange}
                  />
                )}

                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Choose
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    // value={value}
                    onChange={handleRadioToggle}
                  >
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      label="Upload"
                    />
                    <FormControlLabel
                      value="url"
                      control={<Radio />}
                      label="Url"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

            {radioForOption == "file" &&
              (uploadBtnLoad ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth margin="normal" required>
                  {/* <InputLabel htmlFor="video-file">Choose Video</InputLabel> */}

                  <input
                    // accept="video/*"
                    style={{ display: "none" }}
                    id="video-file"
                    type="file"
                    onChange={handleFileChange}
                  />

                  <label htmlFor="video-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload File
                    </Button>
                  </label>
                </FormControl>
              ))}

           
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              sx={{ marginBottom: "5px" }}
            >
              {tags.length > 0 &&
                tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={handleDeleteTag(tag)}
                    color="primary"
                    sx={{ margin: 0.5 }}
                  />
                ))}
            </Stack>
            <hr />

            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                onChange={handleRadioFileVdoToggle}
              >
                <FormControlLabel
                  value="video"
                  control={<Radio />}
                  label="Video"
                />
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="File"
                />
              </RadioGroup>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={uploadBtnLoad}
            >
              Submit
            </Button>
          </form>
        </Box>

        {/* <Box>
            <TiDeleteOutline
              onClick={handleHideUploadView}
              style={{ width: "30px", height: "30px" }}
            />
          </Box> */}
      </Box>
    </Container>
  );
}

export default ChapterDrawer;
