// File: /path_to_component/Repository.js

import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';

import { IoCloseCircleOutline } from 'react-icons/io5';
import ChapterDrawer from './ChapterDrawer';
import AddAssessment from './AddAssessment';


function AddChaptertabs({ courseid, refresh, setRefresh, onChapterSubmit, handleTriggerAddChapter}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [promptValue, setPromptvalue] = useState('')
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
           <Box sx={{ padding: "5px", display:"flex", justifyContent:"flex-end" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginRight: "-2vw",
            marginTop:"-2vw"
          }}
          onClick={onChapterSubmit}
        />
      </Box>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Chapter" />
        <Tab label="Assessment" />
      </Tabs>
      <Box sx={{ padding: 0.5, backgroundColor: "#f5f8fa", borderRadius:"5px", paddingTop:3 }}>
        {selectedTab === 0 && <ChapterDrawer onChapterSubmit={onChapterSubmit} setRefresh={setRefresh} refresh={refresh} handleTriggerAddChapter={handleTriggerAddChapter}/>}
        {selectedTab === 1 && <AddAssessment courseid={courseid} setRefresh={setRefresh} refresh={refresh} handleTriggerAddChapter={handleTriggerAddChapter}/>}
      </Box>
    </Box>
  );
}

export default AddChaptertabs;
