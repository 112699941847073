import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import CourseDrawer from "./CourseDrawer"; // Importing CourseDrawer component
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import { AiOutlineGlobal } from "react-icons/ai";
import { TiUserAdd } from "react-icons/ti";
import Cookies from "js-cookie";
import AssignCourseDrawer from "./AssignCourseDrawer";
import { LuVideo } from "react-icons/lu";
import { FaHeart, FaRegBookmark } from "react-icons/fa";
import {
  MdOutlineCreateNewFolder,
  MdOutlineSubscriptions,
} from "react-icons/md";
import { IoIosHeartEmpty } from "react-icons/io";

function CoursesUser() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const userid = userInfo.userid;
  const company = userInfo.companyname;
  const user = userInfo.username;
  // const userrole = userInfo.userrole
  // console.log(email, "email from user info");

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenForAccess, setIsDrawerOpenForAccess] = useState(false);

  const [isLayerOpen, setIsLayerOpen] = useState(false); // State to manage the visibility of the layer
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [id, setId] = useState(0);
  const [courseTrackData, setCourseTrackData] = useState([]);

  // Fetch videos
  useEffect(() => {
    axios
      .post(`/course/get`, { companyid: companyid.toString() })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    //to get the all course tracker data from the table
    axios
      .post(`/course/getalltrack`, { email, companyid: companyid.toString() })
      .then((res) => {
        setCourseTrackData(res.data.length > 0 && res.data);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleDrawerForAccess = (id) => {
    setIsDrawerOpenForAccess(!isDrawerOpenForAccess);
    setId(id);
  };

  const toggleLayer = () => {
    setIsLayerOpen(!isLayerOpen);
  };

  const handleViewCoursespage = (id) => {
    navigate(`/lms/view/courses/${id}`);
  };

  const handleDeleteCourse = (id) => {
    axios
      .post(`/course/delete`, { id })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const filteredData =
    userrole === "masteradmin"
      ? data
      : data.filter((item) => item.assign && item.assign.includes(email));

  // console.log(data, "da data")

  //To calculate the course completion percentage

  const getCompletionPercentage = (courseId, chapters) => {
    const courseTrack =
      courseTrackData &&
      courseTrackData.length > 0 &&
      courseTrackData.find((track) => track.courseid == courseId);
    if (!courseTrack) return 0;
    // console.log(courseTrack, "crs tracj")
    const completedChapters = courseTrack.chapter;
    const totalChapters = chapters.length;
    const completedCount = chapters.filter((ch) =>
      completedChapters.includes(ch.id.toString())
    ).length;

    // console.log(completedCount, "completedCount")

    return ((completedCount / totalChapters) * 100).toFixed(2);
  };

  //For favourite
  const handleAddFavourite = (id) => {
    axios
      .post(`https://klms.vliv.app/course/addfavourite`, {
        id,
        email,
        user,
        userid,
        company,
        companyid,
      })

      .then((res) => {
        setRefresh(!refresh);
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxWidth="100%">
      <Box sx={{ padding: "10px" }}>
        {userrole == "masteradmin" ? (
          <Box
            sx={{
              color: "white",
              marginBottom: "5px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "4px",
            }}
          >
            <Button
              sx={{ background: "#37668F", color: "white" }}
              onClick={toggleDrawer}
            >
              <MdOutlineCreateNewFolder
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "-3px",
                  marginRight: "2px",
                }}
              />
              <Typography>Course</Typography>
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              color: "white",
              marginBottom: "5px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "4px",
            }}
          >
            <Button></Button>
          </Box>
        )}

        {/* Layer to show all videos */}

        {loading ? (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            // spacing={{ xs: 1, sm: 2, md: 4 }}
            className="card-main-container"
          >
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <div className="card" style={{ width: "19%" }} key={id}>
                  <img
                    style={{ width: "100%", height: "190px" }}
                    src={item.thumbnail}
                    alt="Image not available"
                    className="courseImg"
                    onClick={() => handleViewCoursespage(item.id)}
                  />
                  <div className="card-details">
                    <div
                      className="card-title-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                      >
                        {item.coursename.length > 25
                          ? item.coursename.substring(0, 25) + "..."
                          : item.coursename}
                      </Typography>
                      <div
                        className="card-title-icon"
                        style={{ width: "10%", display: "flex" }}
                      >
                        {item &&
                        item.favourite &&
                        item.favourite.includes(email) ? (
                          <Box title="Make favourite">
                            <FaHeart
                              style={{
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                                color: "#eb4034",
                                borderRadius: 5,
                              }}
                              onClick={() => handleAddFavourite(item.id)}
                            />
                          </Box>
                        ) : (
                          <Box title="Make favourite">
                            <IoIosHeartEmpty
                              style={{
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                                color: "#2D3747",
                                borderRadius: 5,
                              }}
                              onClick={() => handleAddFavourite(item.id)}
                            />
                          </Box>
                        )}
                        {/* <span>{<MdOutlineSubscriptions />}</span> */}
                      </div>
                    </div>
                    <div className="card-username">
                      <span>{item.user}</span>
                    </div>
                  
                    <div className="card-details-container">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "grey",
                        }}
                      >
                        created at - {new Date(item.createdAt).toDateString()}
                      </Typography>
                      <span className="cardchapter">
                        <span
                          className="videoicon"
                          onClick={() => handleViewCoursespage(item.id)}
                        >
                          <LuVideo />
                        </span>
                        <span>
                          {item.chapter && item.chapter.length > 0
                            ? item.chapter.length
                            : 0}
                        </span>
                      </span>
                    </div>
                    {/* <b>{item.coursename}</b> */}
                    <Box sx={{mb:1}}>
                    <Typography>
                      Completion:{" "}
                      {getCompletionPercentage(item.id, item.chapter)}%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={getCompletionPercentage(item.id, item.chapter)}
                    />
                    </Box>
               
                  </div>
                </div>
              ))}
          </Stack>
        )}

        {/* Below the drawer will be open to create the course */}
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <Container sx={{ width: 750 }}>
            <CourseDrawer /> {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>

        {/* For access to the user scrren */}
        <Drawer anchor="right" open={id} onClose={() => setId(0)}>
          <Container sx={{ width: 500 }}>
            <AssignCourseDrawer id={id} />{" "}
            {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>
      </Box>
    </Container>
  );
}

export default CoursesUser;
