// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import Cookies from "js-cookie";
// import {
//   Container,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Button,
//   Typography,
//   Box,
//   Card,
// } from "@mui/material";
// import { CiSquareRemove } from "react-icons/ci";
// import { IoCloseCircleOutline } from "react-icons/io5";

// function AccessCourseDrawer({ id, setIdAccess }) {
//   const userInfo = JSON.parse(Cookies.get("userinfo"));
//   const companyid = userInfo.companyid;

//   const [data, setData] = useState([]);
//   const [courseData, setCourseData] = useState([]);
//   const [selectedEmail, setSelectedEmail] = useState(null);
//   const [refresh, setRefresh] = useState(true);

//   useEffect(() => {
//     axios
//       .post(`/course/allusers`, { companyid: companyid.toString() })
//       .then((res) => {
//         setData(res.data);
//       })
//       .catch((err) => console.log(err));

//     // To get course data
//     axios
//       .post(`/course/get`, { companyid: companyid.toString() })
//       .then((res) => {
//         setCourseData(res.data);
//       })
//       .catch((err) => console.log(err));
//   }, [companyid, refresh]);

//   const handleCheckboxChange = (email) => {
//     // Filter only that row which has this email exist in the access column

//     const rowData =
//       courseData &&
//       courseData.length > 0 &&
//       courseData.find(
//         (elem) => elem.assign && elem.id == id && elem.assign.includes(email)
//       );
//     console.log(rowData, "row data");
//     if (rowData == undefined) {
//       alert("First Assign this course to this user");
//       return;
//     }

//     if (selectedEmail === email) {
//       setSelectedEmail(null);
//     } else {
//       setSelectedEmail(email);
//     }
//   };

//   const handleSubmit = () => {
//     // console.log(selectedEmail, "selected email")
//     // console.log(id, "id")
//     axios
//       .post(`/course/accesscourse`, { id, email: selectedEmail })
//       .then((res) => {
//         alert(res.data.message);
//         setIdAccess(0);
//         setRefresh(!refresh);
//       })
//       .catch((err) => console.log(err));
//   };

//   // To remove access

//   const handleRemoveAccess = () => {
//     axios
//       .post(`/course/accesscourse`, { id, email: "" })
//       .then((res) => {
//         if (res.data.message) {
//           alert("Access Removed");
//         } else {
//           alert("Something went wrong");
//         }
//         setIdAccess(0);
//         setRefresh(!refresh);
//       })
//       .catch((err) => console.log(err));
//   };

//   return (
//     <Container>
//       <Box sx={{ padding: "5px" }}>
//         <IoCloseCircleOutline
//           style={{
//             width: "30px",
//             height: "30px",
//             color: "#f03a52",
//             marginLeft: "-3vw",
//           }}
//           onClick={() => setIdAccess(0)}
//         />
//       </Box>
//       <Box>
//         <h2>Access Control</h2>
//       </Box>
//       <Box sx={{ mt: 4 }}>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: "bold" }}></TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }}></TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {data.map((row, index) => (
//                 <TableRow key={index}>
//                   {courseData &&
//                   courseData.length > 0 &&
//                   courseData.find(
//                     (elem) =>
//                       elem.access &&
//                       elem.access[0] == row.email &&
//                       elem.id == id
//                   ) ? (
//                     <TableCell>
//                       <Typography
//                         sx={{
//                           color: "green",
//                           marginLeft: "10px",
//                         }}
//                       >
//                         ✔
//                       </Typography>
//                     </TableCell>
//                   ) : (
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedEmail === row.email}
//                         onChange={() => handleCheckboxChange(row.email)}
//                       />
//                     </TableCell>
//                   )}

//                   <TableCell>{row.email}</TableCell>
//                   <TableCell>{row.username}</TableCell>
//                   {/* This functionality basically check the same access email and course tid then show remove icon for the access */}
//                   {courseData &&
//                   courseData.length > 0 &&
//                   courseData.find(
//                     (elem) =>
//                       elem.access &&
//                       elem.access[0] == row.email &&
//                       elem.id == id
//                   ) ? (
//                     <TableCell>
//                       <CiSquareRemove
//                         style={{ width: "25px", height: "25px", color: "red" }}
//                         onClick={handleRemoveAccess}
//                       />
//                     </TableCell>
//                   ) : (
//                     <TableCell></TableCell>
//                   )}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         {/* <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSubmit}
//           sx={{ mt: 2, display: "block", mx: "auto" }}
//           disabled={!selectedEmail}
//         >
//           Submit
//         </Button> */}

//         <Box
//               sx={{
//                 display: "flex",
//                 gap: "5px",
//                 justifyContent: "flex-end",
//                 padding: "10px",
//               }}
//             >
//               <Button
//                 sx={{
//                   border: "solid 1px grey",
//                   backgroundColor: "#37668F",
//                   color: "#FFFF",
//                   width: "100px",
//                   borderRadius: "5px",
//                 }}
//                 onClick={handleSubmit}
//                 disabled={!selectedEmail}
//               >
//                 <b>Submit</b>
//               </Button>
//               <Button
//                 sx={{
//                   backgroundColor: "white",
//                   color: "grey",
//                   width: "100px",
//                   border: "solid 1px grey",
//                   borderRadius: "5px",
//                 }}
//                 onClick={()=>setIdAccess(0)}
//               >
//                 <b>Cancel</b>
//               </Button>
//             </Box>

//       </Box>
//     </Container>
//   );
// }

// export default AccessCourseDrawer;

// ##############################(23-07-2024) This above code is commented for now but not remove, the functionality of above code, for giving access to add chapter for single user at one time, ############## Do Not remove above code

import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { CiSquareRemove } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";

function AccessCourseDrawer({ id, setIdAccess }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    axios
      .post(`/course/allusers`, { companyid: companyid.toString() })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .post(`/course/get`, { companyid: companyid.toString() })
      .then(async (res) => {
        setCourseData(res.data);

        //filter by id
        const filteredData = await res.data.filter((elem) => {
          return elem.id == id;
        });
        // console.log(filteredData, "access filteredDaat")
        setSelectedEmails(filteredData[0].access || []);
      })
      .catch((err) => console.log(err));
  }, [companyid, refresh]);

  const handleCheckboxChange = (email) => {
    const rowData =
      courseData &&
      courseData.length > 0 &&
      courseData.find(
        (elem) => elem.assign && elem.id == id && elem.assign.includes(email)
      );
    if (rowData == undefined) {
      alert("First Assign this course to this user");
      return;
    }

    setSelectedEmails((prevSelectedEmails) =>
      prevSelectedEmails.includes(email)
        ? prevSelectedEmails.filter((e) => e !== email)
        : [...prevSelectedEmails, email]
    );
  };

  const handleSubmit = () => {
    axios
      .post(`/course/accesscourse`, { id, emails: selectedEmails })
      .then((res) => {
        alert(res.data.message);
        setIdAccess(0);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveAccess = () => {
    axios
      .post(`/course/accesscourse`, { id, email: "" })
      .then((res) => {
        if (res.data.message) {
          alert("Access Removed");
        } else {
          alert("Something went wrong");
        }
        setIdAccess(0);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  // To filter by searchValue for both username and email
  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? item.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.email?.toLowerCase().includes(searchValue.toLowerCase())
        : true
    );
  };

  const filteredData = filterData();

  return (
    <Container>
      <Box sx={{ padding: "5px" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginLeft: "-3vw",
          }}
          onClick={() => setIdAccess(0)}
        />
      </Box>
      <Box>
        <h2>Access Control</h2>
      </Box>

      <Box sx={{ mt: 4 }}>

        <Box sx={{display:"flex", justifyContent:"end"}}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400, marginRight: 2 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e3dcdd" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                {/* <TableCell sx={{ fontWeight: "bold" }}>Assigned</TableCell> */}
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 &&
                filteredData.map((row, index) => (
                  <TableRow key={index}>
                    {/* {courseData &&
                  courseData.length > 0 &&
                  courseData.find(
                    (elem) =>
                      elem.access &&
                      elem.access.includes(row.email) &&
                      elem.id == id
                  ) ? (
                    <TableCell>
                      <Typography
                        sx={{
                          color: "green",
                          marginLeft: "10px",
                        }}
                      >
                        ✔
                      </Typography>
                    </TableCell>
                  ) : ( */}
                    <TableCell>
                      <Checkbox
                        checked={
                          selectedEmails &&
                          selectedEmails.length > 0 &&
                          selectedEmails.includes(row.email)
                        }
                        onChange={() => handleCheckboxChange(row.email)}
                      />
                    </TableCell>
                    {/* // )} */}
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.username}</TableCell>
                    {/* <TableCell>
                    {courseData &&
                    courseData.length > 0 &&
                    courseData.find(
                      (elem) => elem.assign && elem.assign.includes(row.email)
                    )
                      ? "Assigned"
                      : "Not Assigned"}
                  </TableCell> */}
                    {/* {courseData &&
                  courseData.length > 0 &&
                  courseData.find(
                    (elem) =>
                      elem.access &&
                      elem.access.includes(row.email) &&
                      elem.id == id
                  ) ? (
                    <TableCell>
                      <CiSquareRemove
                        style={{ width: "25px", height: "25px", color: "red" }}
                        onClick={handleRemoveAccess}
                      />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )} */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#37668F",
              color: "#FFFF",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={handleSubmit}
            // disabled={selectedEmails.length === 0}
          >
            <b>Submit</b>
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "grey",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={() => setIdAccess(0)}
          >
            <b>Cancel</b>
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default AccessCourseDrawer;
