import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import vlivlogo from "../../assets/Vliv logo 1.png";
import noimage from "../../assets/no-profile.png";
import { ImProfile } from "react-icons/im";
import { FaPowerOff } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { notifySuccess, notifyWarning } from "../../Constrant/toastAlert";
import { HiOutlineDocument } from "react-icons/hi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineExplore } from "react-icons/md";
import { FiBookmark } from "react-icons/fi";
import { IoBookOutline } from "react-icons/io5";
import NavbarMenu from "./NavbarMenu";

let userInfo = null;
let userrole = null;

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

// const pages = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     // icon: <MdDashboard />,
//   },
//   {
//     path: "/explore",
//     name: "Explore",
//     // icon: <ExploreIcon />,
//   },
//   {
//     path: "/favourite",
//     name: "Favourite",
//     // icon: <FavoriteIcon />,
//   },
//   userrole == "masteradmin" ? {
//     path: "/admin/courses",
//     name: "Course",
//     // icon: <MdDashboard />,
//   } :
//     {
//       path: "/view/courses",
//       name: "Course",
//       // icon: <MdDashboard />,
//     }
//   ,
// ];

const pages = [
  {
    path: "/",
    name: "WELCOME",
    // icon: <MdDashboard />,
  },
  {
    path: "/kms",
    name: "KMS",
    // icon: <MdDashboard />,
    subRoutes: [
      {
        path: "/kms/myDocs",
        name: "MY DOCS",
        icon: <HiOutlineDocument />,
      },
      {
        path: "/kms/allDocs",
        name: "ALL DOCS",
        icon: <HiOutlineDocumentDuplicate />,
      },
    ],
  },
  {
    path: "/lms",
    name: "LMS",
    // icon: <ExploreIcon />,
    subRoutes: [
      {
        path: "/lms/dashboard",
        name: "DASHBOARD",
        icon: <RxDashboard />,
      },
      {
        path: "/lms/explore",
        name: "EXPLORE",
        icon: <MdOutlineExplore />,
      },
      {
        path: "/lms/favourite",
        name: "FAVOURITE",
        icon: <FiBookmark />,
      },

      userrole == "masteradmin"
        ? {
            path: "lms/admin/courses",
            name: "COURSE",
            // icon: <MdDashboard />,
          }
        : {
            path: "lms/view/courses",
            name: "COURSE",
            // icon: <MdDashboard />,
          },
    ],
  },
];

const settings = [
  {
    path: "/profile",
    name: "Profile",
    icon: <ImProfile />,
  },
  // {
  //   path: "/login",
  //   name: "Logout",
  //   icon: <FaPowerOff />,
  // }
];

function TabButtons({ children }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [themeMode, setThemeMode] = React.useState("dark");
  const [isOpen, setIsOpen] = React.useState(true);
  const [menuContainer, setMenuContainer] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuModal = (value) => {
    console.log("value", value);
    setMenuContainer(value);
  };

  const handleLogout = async () => {
    try {
      const res = await axios.post("/api/v1/logout");
      console.log("logout res", res);
      const clearAllCookies = () => {
        const allCookies = Cookies.get(); // get all cookies
        Object.keys(allCookies).forEach((cookieName) => {
          Cookies.remove(cookieName);
        });
      };

      if (res.data.message === "Loggedout Successfully") {
        setTimeout(() => {
          notifySuccess(res.data.message);
          clearAllCookies();
          navigate("/login");
        }, 1000);
      } else {
        notifyWarning(res.data.message);
      }
    } catch (error) {
      console.log(`Error while logout ${error}`);
    }
  };

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "dark"));
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
        },
      }),
    [themeMode]
  );

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-start",
            gap: "1rem",
          }}
        >
          {pages.map((page, index) => {
            if (page.subRoutes) {
              return (
                <NavbarMenu
                  setIsOpen={setIsOpen}
                  route={page}
                  // showAnimation={showAnimation}
                  isOpen={isOpen}
                  key={index}
                  handleMenuModal={handleMenuModal}
                />
              );
            }
            return (
              <NavLink
                to={page.path}
                key={index}
                className="linkpages"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, mx: 1, color: "black", display: "flex" }}
              >
                {page.name}
              </NavLink>
            );
          })}
        </Box>
      </Container>

      {/* <Container maxWidth="xl">
        <h1>sub headings</h1>
      </Container> */}
      <Container maxWidth="xl" className={menuContainer ? "main" : ""}>
        {children}
      </Container>
    </>
  );
}
export default TabButtons;
