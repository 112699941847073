import {
  Box,
  Card,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PiGraduationCapLight } from "react-icons/pi";
import { GiGraduateCap, GiProgression } from "react-icons/gi";

import ReactApexChart from "react-apexcharts";
import CanvasJSReact from "@canvasjs/react-charts";
import { MdOutlineAssessment, MdOutlineQuiz } from "react-icons/md";
import { AiOutlineDotChart } from "react-icons/ai";
import { FaRegBookmark, FaRegFileAlt } from "react-icons/fa";
import { FiUserPlus, FiUserX } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";
import { LuVideo } from "react-icons/lu";

// For cicular progress
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Blue from "../../../assets/Blue.png";
import Green from "../../../assets/Green.png";
import Purple from "../../../assets/Purple.png";
import Yellow from "../../../assets/Yellow.png";
import { TbReportAnalytics } from "react-icons/tb";
import { CgEye } from "react-icons/cg";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}

//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DashboardView() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const username = userInfo.username;

  const navigate = useNavigate();

  const [totalCourse, setTotalCourse] = useState("");
  const [learnersEmails, setLearnersEmails] = useState([]);
  const [totalQuiz, setTotalQuiz] = useState([]);
  const [totalAssessment, setTotalAssessment] = useState([]);
  const [totalAssigneAssessment, setTotalAssignAssement] = useState([]);
  const [totalAssignQuiz, setTotalAssignQuiz] = useState([]);

  const [monthlyData, setMonthlyData] = useState([]);
  const [courseCompletePercent, setCourseCompletePercent] = useState(0);
  const [allUsers, setAllUsers] = useState(0);

  const [greeting, setGreeting] = useState("");
  const [greetingColr, setGreetingClr] = useState("black");

  const [pass, setPass] = useState([]);
  const [fail, setFail] = useState([]);
  const [allPassFailData, setAllPassFailData] = useState([]);

  // From here user lms useState
  const [courseData, setCoursedata] = useState([]);
  const [allCourseData, setAllCoursedata] = useState([]);

  const [courseTrackData, setCourseTrackData] = useState([]);
  const [myAssessmentCompleted, setMyAssessmentCompleted] = useState([]);
  const [completedAssessment, setCompletedAssesssment] = useState([]);
  const [myContributionData, setMyContributionData] = useState([]);

  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good Morning!");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon!");
    } else {
      setGreeting("Good Evening!");
      setGreetingClr("white");
    }

    axios
      .post(`/course/get`, { companyid: companyid.toString() })
      .then((res) => {
        // This will filter the current users course based on the email to get their courses only
        const filteredData = res.data.filter(
          (item) => item.assign && item.assign.includes(email)
        );
        // This will be for my contribution where I have access to ad the chapters, those courses I will see

        const filteredMyContributionData = res.data.filter(
          (item) => item.access && item.access.includes(email)
        );
        console.log(filteredMyContributionData, "my cotribution courses");
        setMyContributionData(filteredMyContributionData);
        setCoursedata(filteredData);
        setAllCoursedata(res.data);
      })
      .catch((err) => console.log(err));

    //to get the all course tracker data from the table
    axios
      .post(`/course/getalltrack`, { email, companyid: companyid.toString() })
      .then((res) => {
        setCourseTrackData(res.data.length > 0 && res.data);
      })
      .catch((err) => console.log(err));

    //To get total assessment and quiz
    axios
      .post(`/assessment/get`, { companyid, userrole, email })
      .then((res) => {
        setCompletedAssesssment(res.data);

        const filteredData = res.data.filter(
          (item) => item.completedby && item.completedby.includes(email)
        );
        setMyAssessmentCompleted(filteredData);
        console.log(filteredData, "completed by data");
        //For assessment
        const assessmentData = res.data.filter((elem) => {
          return elem.type == "Assessment";
        });
        setTotalAssessment(assessmentData);

        //For quiz
        const quizData = res.data.filter((elem) => {
          return elem.type == "Quiz";
        });
        setTotalQuiz(quizData);
      })
      .catch((err) => console.log(err));

    // To get All users
    axios
      .post(`https://klms.vliv.app/course/allusers`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        setAllUsers(res.data.length);
      })
      .catch((err) => console.log(err));

    // Pass fail
    axios
      .post(`https://klms.vliv.app/assessment/getallpass`, { companyid })
      .then((res) => {
        const passData = res.data.filter((elem) => {
          return elem.passfail == "pass";
        });
        const failData = res.data.filter((elem) => {
          return elem.passfail == "fail";
        });

        setPass(passData);
        setFail(failData);
        setAllPassFailData(res.data);
        // console.log(res.data, "get all pass");
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  // Calculate the days function for the course created date
  function calculateDaysSince(createdAt) {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate - createdAtDate;
    const differenceInDays = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  //To calculate the course completion percentage

  const getCompletionPercentage = (courseId, chapters) => {
    const courseTrack =
      courseTrackData &&
      courseTrackData.length > 0 &&
      courseTrackData.find((track) => track.courseid == courseId);
    if (!courseTrack) return 0;
    // console.log(courseTrack, "crs tracj")
    const completedChapters = courseTrack.chapter;
    const totalChapters = chapters.length;
    const completedCount = chapters.filter((ch) =>
      completedChapters.includes(ch.id.toString())
    ).length;

    return ((completedCount / totalChapters) * 100).toFixed(2);
  };

  // New function to count 100% completed courses
  const countFullyCompletedCourses = () => {
    return courseData.filter(
      (course) => getCompletionPercentage(course.id, course.chapter) == "100.00"
    ).length;
  };

  // ChartJs for column chart
  useEffect(() => {
    const updatedMonthlyData = calculateMonthlyData(
      myAssessmentCompleted,
      myContributionData
    );
    setMonthlyData(updatedMonthlyData);
  }, [myAssessmentCompleted, myContributionData]);

  function calculateMonthlyData(assessments, contributions) {
    const data = {};

    const processEntries = (entries) => {
      entries.forEach((entry) => {
        const createdAt = new Date(entry.createdAt);
        const month = createdAt.getMonth() + 1;
        const year = createdAt.getFullYear();
        const key = `${year}-${month}`;

        if (!data[key]) {
          data[key] = { month: key, assessmentCount: 0, contributionCount: 0 };
        }

        if (entries === assessments) {
          data[key].assessmentCount += 1;
        } else {
          data[key].contributionCount += 1;
        }
      });
    };

    processEntries(assessments);
    processEntries(contributions);

    return Object.values(data);
  }

  const columnOption = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "column",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: monthlyData.map((data) => ({
          label: data.month,
          y: data.assessmentCount,
          color: "#7CD4FD",
        })),
      },
      {
        type: "column",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: monthlyData.map((data) => ({
          label: data.month,
          y: data.contributionCount,
          color: "#D6BBFB",
        })),
      },
    ],
  };
  //   For gauge chart
  const [chartOptionsForCourseCompletion, setChartOptionsForCourseCompletion] =
    useState({
      series: [0],
      options: {
        chart: {
          height: 350,
          type: "radialBar",
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0,
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  if(!val){
                    return 0
                  }
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
    });
  //Course Completion percentage
  useEffect(() => {
    setChartOptionsForCourseCompletion((prev) => ({
      ...prev,
      series: [+((countFullyCompletedCourses() / +courseData.length) * 100)],
    }));

    setChartOptionsForAssessment((prev) => ({
      ...prev,
      series: [
        +((myAssessmentCompleted.length / +completedAssessment.length) * 100),
      ],
    }));
  }, [courseData, myAssessmentCompleted]);

  const [chartOptionsForAssessment, setChartOptionsForAssessment] = useState({
    series: [75],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                if(!val){
                  return 0
                }
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
  });

  // console.log(myAssessment, "myAssessment")

  // To show dummy data if my course and my contributions are empty or less than 4
  const totalItems = 4;
  const dummyItem = {
    thumbnail:
      "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg",
    coursename: "Not Available",
    user: "Unknown",
    chapter: [],
    createdAt: new Date().toISOString(),
  };
  // Ensure there are at least 4 items for courseData
  const actualItemsForMyCourse = courseData.length;
  const dummyItemsCountMyCourse = totalItems - actualItemsForMyCourse;
  const limitedCourseData = courseData
    .concat(Array.from({ length: dummyItemsCountMyCourse }, () => dummyItem))
    .slice(0, totalItems);

  // Ensure there are at least 4 items for myContributionData
  const actualItemsMyContribution = myContributionData.length;
  const dummyItemsCountForMyContribution =
    totalItems - actualItemsMyContribution;
  const limitedMyContributionData = myContributionData
    .concat(
      Array.from({ length: dummyItemsCountForMyContribution }, () => dummyItem)
    )
    .slice(0, totalItems);

  // To view each course

  const handleViewCoursespage = (id) => {
    if (!id) {
      return alert("You dont have any course for this");
    }
    navigate(`/lms/view/courses/${id}`);
  };
  return (
    <Box sx={{ width: "195vh", height: "100%", padding: 2 }}>
      <Box sx={{ width: "100%", display: "flex", gap: "1%" }}>
      <Box sx={{ width: "70%", position: "relative" }}>
            <Box>
              {greeting == "Good Morning!" ? (
                <img
                  src={require("../../../assets/morning.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : greeting == "Good Afternoon!" ? (
                <img
                  src={require("../../../assets/afternoon.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : (
                <img
                  src={require("../../../assets/night.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "25px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 30,
                // left: "10vh",
                color: "#FFFF",
                fontWeight: "bold",
                zIndex: 1,
                background:"black",
                padding:"10px 10px 10px 50px",
                opacity:0.7,
                borderRadius:"10px"
              }}
            >
              <Typography
                variant="h4"
                sx={{
                //   position: "absolute",
                //   top: 30,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                //   zIndex: 1,
                }}
              >
                {greeting}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                //   position: "absolute",
                //   top: 60,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                  fontSize: "20px",
                //   zIndex: 1,
                }}
              >
                Howdy, {username}
              </Typography>
            </Box>
          </Box>
        <Box sx={{ width: "30%", height: "250px" }}>
          <Typography
            sx={{
              mt: 0,
              mb: 0,
              ml: 0.5,
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            {" "}
            <AiOutlineDotChart />
            <span> Monthly wise courses</span>
          </Typography>
          <CanvasJSChart
            options={columnOption}
            /* onRef={ref => this.chart = ref} */
            containerProps={{ width: "100%", height: "250px" }}
          />
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  background: "#D6BBFB",
                  width: "20px",
                  height: "13px",
                  margin: "4px",
                }}
              ></Card>{" "}
              My Contribution
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  background: "#7CD4FD",
                  width: "20px",
                  height: "13px",
                  margin: "4px",
                }}
              ></Card>{" "}
              Assessment & Quiz
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{ mt: 2, mb: 2, ml: 2, fontWeight: "bold", fontSize: "1.2rem" }}
        >
          <PiGraduationCapLight />
          <span> Courses</span>
        </Typography>
        <Box sx={{ width: "100%", display: "flex", gap: "1%" }}>
          <Box
            sx={{
              alignItems: "center",
              paddingLeft: 1,
              paddingRight: 1,
              borderRadius: 3,
              width: "70%",
              height: "30vh",
              display: "flex",
              gap: 1,
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            <Box
              sx={{
                background: "#F4EBFF",
                width: "40vh",
                height: "25vh",
                textAlign: "center",
                borderRadius: "15px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <GiGraduateCap
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "20%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "140px", // Adjust size as needed
                  opacity: 0.2, // Adjust opacity as needed
                }}
              />
              <img
                src={Purple}
                style={{
                  width: "300px",

                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0.8, // Adjust opacity as needed
                }}
              />
              <Typography sx={{ fontSize: "20px", mt: 1 }}>
                Total Courses
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                {+courseData.length}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#FEF0C7",
                width: "40vh",
                height: "25vh",
                textAlign: "center",
                borderRadius: "15px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <TbReportAnalytics

                style={{
                  position: "absolute",
                  top: "50%",
                  left: "20%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "140px", // Adjust size as needed
                  opacity: 0.2, // Adjust opacity as needed
                }}
              />
              <img
                src={Yellow}
                style={{
                  width: "300px",

                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0.8, // Adjust opacity as needed
                }}
              />
              <Typography sx={{ fontSize: "20px", mt: 1 }}>
                Assessment
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                {+totalAssessment.length}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#D1FADF",
                width: "40vh",
                height: "25vh",
                textAlign: "center",
                borderRadius: "15px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <MdOutlineQuiz

                style={{
                  position: "absolute",
                  top: "50%",
                  left: "20%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "140px", // Adjust size as needed
                  opacity: 0.2, // Adjust opacity as needed
                }}
              />
              <img
                src={Green}
                style={{
                  width: "300px",

                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0.8, // Adjust opacity as needed
                }}
              />
              <Typography sx={{ fontSize: "20px", mt: 1 }}>Quiz</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                {+totalQuiz.length}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#FEE2E2",
                width: "40vh",
                height: "25vh",
                textAlign: "center",
                borderRadius: "15px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <CgEye

                style={{
                  position: "absolute",
                  top: "50%",
                  left: "20%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "140px", // Adjust size as needed
                  opacity: 0.2, // Adjust opacity as needed
                }}
              />
              <img
                src={Purple}
                style={{
                  width: "300px",

                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0.8, // Adjust opacity as needed
                }}
              />
              <Typography sx={{ fontSize: "20px", mt: 1 }}>Viewed</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}>
                {+countFullyCompletedCourses()}
              </Typography>
            </Box>
          </Box>
          <Card sx={{ width: "30%", height: "30vh", overflow: "auto" }}>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
                ml: 2,
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              <GiProgression />
              <span> Completion Stats</span>
            </Typography>

            <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
              <Box sx={{ textAlign: "center" }}>
                <ReactApexChart
                  options={chartOptionsForCourseCompletion.options}
                  series={chartOptionsForCourseCompletion.series}
                  type="radialBar"
                  height={170}
                  width={200}
                />
                <Typography sx={{ mt: -2, fontWeight: "bold" }}>
                  My Course
                </Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <ReactApexChart
                  options={chartOptionsForAssessment.options}
                  series={chartOptionsForAssessment.series}
                  type="radialBar"
                  height={170}
                  width={200}
                />
                <Typography sx={{ mt: -2, fontWeight: "bold" }}>
                  Assessment
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box sx={{ width: "100%", display: "flex", gap: "1%", mt: 3 }}>
        <Box
          sx={{
            alignItems: "center",
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 3,
            width: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            background: "#F0F9FF",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ mt: 2, mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
            >
              <MdOutlineAssessment />
              <span> My Course</span>
            </Typography>{" "}
            <Typography
              sx={{
                mt: 2,
                mb: 2,
                fontWeight: "bold",
                color: "#026AA2",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/lms/view/courses`)}
            >
              <span> View All</span>
            </Typography>{" "}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              width: "100%",
              height: "44vh",
              overflow: "auto",
            }}
          >
            {courseData.slice(0,4)
              .map((item, index) => (
                <Box
                sx={{
                  width: "23%", // Ensures 4 items fit within 100%
                  textAlign: "center",
                  borderRadius: "15px",
                  padding: 1,
                  flexShrink: 0, // Prevents items from shrinking
                }}
                  key={index}
                >
                  <Box
                    sx={{
                      background: "#FFFF",
                      width: "100%",
                      // height: "35vh",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt="Image not available"
                      style={{
                        width: "100%",
                        height: "24vh",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleViewCoursespage(item.id)}
                    />
                    <Box sx={{}}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 5px 0 5px",
                          mt: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              textAlign: "start",
                            }}
                          >
                            {item.coursename.length > 25
                              ? item.coursename.substring(0, 25) + "..."
                              : item.coursename}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              color: "grey",
                              textAlign: "start",
                            }}
                          >
                            Createdby- {item.user}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            {" "}
                            <CircularProgressWithLabel
                              value={getCompletionPercentage(
                                item.id,
                                item.chapter
                              )}
                              sx={{
                                width: "25px",
                                height: "25px",
                                color:
                                  getCompletionPercentage(
                                    item.id,
                                    item.chapter
                                  ) < 50
                                    ? "orange"
                                    : getCompletionPercentage(
                                        item.id,
                                        item.chapter
                                      ) <= 50
                                    ? "teal"
                                    : "#12B76A",
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          {calculateDaysSince(item.createdAt)} day ago
                        </Typography>
                        <Typography>
                          <LuVideo
                            style={{
                              background: "#53389E",
                              color: "white",
                              width: "25px",
                              height: "25px",
                              borderRadius: "8px",
                              padding: 1,
                              marginBottom: -5,
                            }}
                          />
                          <span>
                            {" "}
                            {(item.chapter &&
                              item.chapter.length > 0 &&
                              item.chapter.length) ||
                              0}{" "}
                            Chapters
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>

      {/* For my contribution */}

      <Box sx={{ width: "100%", display: "flex", gap: "1%", mt: 3 }}>
        <Box
          sx={{
            alignItems: "center",
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 3,
            width: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            background: "#F0F9FF",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ mt: 2, mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
            >
              <MdOutlineAssessment />
              <span> My Contribution</span>{" "}
            </Typography>{" "}
            <Typography
              sx={{
                mt: 2,
                mb: 2,
                fontWeight: "bold",
                color: "#026AA2",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/lms/view/courses`)}
            >
              <span> View All</span>
            </Typography>{" "}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              width: "100%",
              height: "44vh",
              overflow: "auto",
            }}
          >
            { myContributionData.slice(0,4).map((item, index) => (
                <Box
                sx={{
                  width: "23%", // Ensures 4 items fit within 100%
                  textAlign: "center",
                  borderRadius: "15px",
                  padding: 1,
                  flexShrink: 0, // Prevents items from shrinking
                }}
                  key={index}
                >
                  <Box
                    sx={{
                      background: "#FFFF",
                      width: "100%",
                      // height: "35vh",
                      textAlign: "center",
                      borderRadius: "15px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt="Image not available"
                      style={{
                        width: "100%",
                        height: "24vh",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleViewCoursespage(item.id)}
                    />
                    <Box sx={{}}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 5px 0 5px",
                          mt: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              textAlign: "start",
                            }}
                          >
                            {item.coursename.length > 25
                              ? item.coursename.substring(0, 25) + "..."
                              : item.coursename}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              color: "grey",
                              textAlign: "start",
                            }}
                          >
                            Createdby- {item.user}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            {" "}
                            <CircularProgressWithLabel
                              value={getCompletionPercentage(
                                item.id,
                                item.chapter
                              )}
                              sx={{
                                width: "25px",
                                height: "25px",
                                color:
                                  getCompletionPercentage(
                                    item.id,
                                    item.chapter
                                  ) < 50
                                    ? "orange"
                                    : getCompletionPercentage(
                                        item.id,
                                        item.chapter
                                      ) <= 50
                                    ? "teal"
                                    : "#12B76A",
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          {calculateDaysSince(item.createdAt)} day ago
                        </Typography>
                        <Typography>
                          <LuVideo
                            style={{
                              background: "#53389E",
                              color: "white",
                              width: "25px",
                              height: "25px",
                              borderRadius: "8px",
                              padding: 1,
                              marginBottom: -5,
                            }}
                          />
                          <span> {item && item.chapter && item.chapter.length} Chapters</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>

      {/* For achievemetns */}

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: "1%",
          mt: 3,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 3,
            width: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            background: "#F0F9FF",
            height: "36vh",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ mt: 2, mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
            >
              <MdOutlineAssessment />
              <span> Achievements</span>{" "}
            </Typography>{" "}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              width: "100%",
            }}
          >
            {Array.from({ length: 1 }).map((_, index) => (
              <Box
                sx={{
                  // background: "#FFFF",
                  width: "20vw",
                  // height: "38vh",
                  textAlign: "center",
                  borderRadius: "15px",
                  padding: 1,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    borderRadius: "15px",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <img
                    src={require("../../../assets/achievement.png")}
                    alt="Image not available"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    onClick={() => navigate("/lms/certificate")}
                  />

                  <Typography
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      bottom: 0,
                      top: "15vh",
                      left: "-2vw",
                    }}
                  >
                    Certificate
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardView;
