import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaComments, FaFile } from "react-icons/fa";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import {
  Container,
  CssBaseline,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  Drawer,
  Box,
} from "@mui/material";
import Cookies from "js-cookie";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoAccessibility, IoCloseCircleOutline } from "react-icons/io5";

export default function ViewModuleWiseDrawer({
  moduleName,
  openViewContriDrawer,
  setOpenViewContriDrawer,
}) {
  const pdfRef = useRef(null);
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;

  // console.log(userInfo, "userinf")

  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openAddModule, setOpenAddModule] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [accessId, setAccessId] = useState(null);
  const [openAccessModule, setOpenAccessModule] = useState(false);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/getbymodule`, {
        companyid,
        modulename:moduleName
      })
      .then((res) => {
        const filteredData = res.data.filter((elem)=>{return elem.status == "Approved"})
        if(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager"){
          setData(res.data.length > 0 ? res.data : []);
        }else{
          setData(filteredData)
        }
        
        // console.log(filteredData, "approved data");
      })
      .catch((err) => console.log(err));
  }, [moduleName]);

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      
    },
    {
      field: "module",
      headerName: "Repo",
      flex: 1,
      
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      
    },
    {
      field: "username",
      headerName: "User name",
      flex: 1,
      
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      
      renderCell: (params) => {
        let color;
        let background;
        let borderRadius;
        let padding;
        switch (params.value) {
          case "Pending":
            color = "#B54706";
            background = "#FDB022";
            borderRadius = "5px";
            padding = "2px";
            break;
          case "Approved":
            color = "#05804C";
            background = "#32D583";
            borderRadius = "5px";
            padding = "2px";
            break;
          default:
            color = "#700110";
            background = "#D92D20";
            borderRadius = "5px";
            padding = "2px";
            break;
        }
        return (
          <span style={{ color, background, borderRadius, padding }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
        <>
          <MdOutlineRemoveRedEye
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleViewButton(params.row.id);
            }}
            style={{ width: "17px", height: "17px" }}
          />
        </>
      ),
    },
  ];

  const handleViewButton = (id) => {
    navigate(`/kms/contributions/view/${id}`);
  };

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  //To close view drawer
  const handleCloseDrawer = ()=>{
    setOpenViewContriDrawer(!openViewContriDrawer)
  }

//   console.log(moduleName, 'module name')

  return (
    <>
      <Box maxWidth="100%">
        <Box sx={{ padding: "5px" }}>
          <IoCloseCircleOutline
            style={{
              width: "30px",
              height: "30px",
              color: "#f03a52",
              marginLeft: "-1.5vw",
            }}
              onClick={handleCloseDrawer}
          />
        </Box>
        <Typography variant="h6" gutterBottom>
          Contributions
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div ref={pdfRef}>
          <DataGrid
           //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            // onSelectionModelChange={(newSelectionModel) => {
            //   setSelectionModel(newSelectionModel);
            // }}
            // selectionModel={selectionModel}
          />
        </div>
      </Box>
    </>
  );
}
