// File: /path_to_component/Repository.js

import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import CreatePrompts from './CreatePrompts';
import UpdateContribute from './UpdateContribute';
import { IoCloseCircleOutline } from 'react-icons/io5';


function ContributeModalTabsUpdate({handleTriggerUpdateContribute, setRefresh, refresh, id}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [promptValue, setPromptvalue] = useState('')
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
           <Box sx={{ padding: "5px", display:"flex", justifyContent:"flex-end" }}>
        <IoCloseCircleOutline
          style={{
            width: "30px",
            height: "30px",
            color: "#f03a52",
            marginRight: "-2vw",
            marginTop:"-2vw"
          }}
          onClick={handleTriggerUpdateContribute}
        />
      </Box>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Generate" />
        <Tab label="Update" />
      </Tabs>
      <Box sx={{ padding: 0.5, backgroundColor: "#FFFF", height:"75vh", borderRadius:"5px" }}>
        {selectedTab === 0 && <CreatePrompts promptValue={promptValue} setPromptvalue={setPromptvalue} setSelectedTab={setSelectedTab}  id={id} handleTriggerUpdateContribute={handleTriggerUpdateContribute} setRefresh={setRefresh} refresh={refresh} />}
        {selectedTab === 1 && <UpdateContribute promptValue={promptValue} setPromptvalue={setPromptvalue} id={id} handleTriggerUpdateContribute={handleTriggerUpdateContribute} setRefresh={setRefresh} refresh={refresh} />}
      </Box>
    </Box>
  );
}

export default ContributeModalTabsUpdate;
