import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import CourseUpdateDrawer from "../CourseUpdateDrawer";
import { FaExternalLinkAlt, FaPlus } from "react-icons/fa";
import ChapterDrawer from "../AddChapter/ChapterDrawer";
import { Document, Page } from "react-pdf";
import Cookies from "js-cookie";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChpaterItemDrawer from "../ChapterItems/ChpaterItemDrawer";
import ChapterItemViewDrawer from "../ChapterItems/ChapterItemViewDrawer";
import { CiClock2, CiVideoOn } from "react-icons/ci";
import { IoPeopleSharp } from "react-icons/io5";
import AddChaptertabs from "../AddChapter/AddChaptertabs";
import ViewAssessment from "../../Assessment/ViewAssessment/ViewAssessment";
import UpdateChapter from "../UpdateChapter/UpdateChapter";
import { LuPencil } from "react-icons/lu";

function ViewCourses() {
  // Cookies data start
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const userrole = userInfo.userrole;
  // console.log(userInfo, "user info");

  // Cookies data start

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [chapterData, setchapterData] = useState([]);
  const [bigScrnVideo, setBigScrnVideo] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);

  const [isItemViewDrawerOpen, setIsItemViewDrawerOpen] = useState(false);
  const [itemViewData, setItemViewData] = useState([]);
  const [itemId, setItemId] = useState(0);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [loading1, setLoading1] = useState(true); // State to track loading status
  const [next, setNext] = useState(0);
  const [refresh, setRefresh] = useState(false);
  // For assessment
  const [openViewAssessment, setOpenViewAssessment] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");
  const [chapterItem, setChapterItem] = useState([]);

  // For edit course name and description
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  // console.log(data, "data")

  //To disable the right click start
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  // desable right click end

  // This is for chapter drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleUpdateDrawer = (item) => {
    console.log(item, "update chapter");
    setChapterItem(item);
    setIsUpdateDrawerOpen(!isUpdateDrawerOpen);
  };
  // This is for chapter item drawer
  const toggleItemDrawer = (id) => {
    // setIsItemDrawerOpen(!isItemDrawerOpen);
    // console.log(id, "id for item drawer")
    setItemId(id);
  };
  const toggleItemViewDrawer = (itm) => {
    // setIsItemDrawerOpen(!isItemDrawerOpen);
    setIsItemViewDrawerOpen(!isItemViewDrawerOpen);
    setItemViewData(itm);
  };
  //   console.log(id, "id");

  useEffect(() => {
    axios
      .post(`/course/getbyid`, { id })
      .then((res) => {
        // console.log(res.data, "view courses screen");
        setData(res.data);
        setCourseName(res.data[0].coursename);
        setCourseDescription(res.data[0].coursedesction);
        setchapterData(res.data[0].chapter);
        setLoading(false);
        setLoading1(false);
      })
      .catch((err) => console.log(err));
  }, [loading, refresh]);

  const handleNext = () => {
    if (next < chapterData.length - 1) {
      setNext(next + 1);
    } else {
      alert("You don't have a next chapter");
    }
  };

  const handlePrevious = () => {
    if (next > 0) {
      setNext(next - 1);
    } else {
      alert("You don't have a previous chapter");
    }
  };

  const handleChapterData = (chapterData) => {
    axios
      .post(`/course/addchapter`, {
        id,
        chapterData: chapterData.chapterData[0],
      })
      .then((res) => {
        alert(res.data.message);
        console.log(res.data, "course added");
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };

  const handleChapterItemSubmit = (itemData) => {
    // console.log(itemData, itemId, "item data")
    axios
      .post(`/course/additem`, {
        id,
        itemData,
        itemid: itemId,
      })
      .then((res) => {
        // console.log(res.data);
        alert(res.data.message);
        setLoading(true);
      })
      .catch((err) => console.log(err));
    // alert('chapter item')
  };
  // For pdf view
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // console.log(data, "view data")

  const handleOpenAssessment = (id) => {
    setOpenViewAssessment(true);
    setAssessmentId(id);
  };

  // console.log(data, "data");

  const handleUpdateCourse = () => {
    axios
      .post(`/course/update`, {
        id,
        coursename: courseName,
        coursedesction: courseDescription,
      })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
        setIsInputOpen(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container
      maxWidth="100%"
      sx={{ padding: "10px", width: "100%", background: "#F0F9FF" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          gap: 3,
          paddingTop: "20px",
          height: "90vh",
          // position:"relative"
        }}
      >
        {loading1 ? (
          <Box sx={{ width: "70%" }}>
            {Array.from({ length: 1 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: "70%",
              flex: "1 1 auto",
              overflowY: "auto",
              paddingBottom: "10px",
              background: "#FDFDFD",
            }}
          >
            <Box
              sx={{
                // boxShadow:
                //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                borderRadius: "10px",
                flex: "1 1 auto",
                overflowY: "auto",
                paddingBottom: "10px",
                border: "solid 1px #d4d6d9",
              }}
            >
              {chapterData &&
              chapterData.length > 0 &&
              chapterData[next].fileUrl.includes(".pdf") ? (
                <Box
                  sx={{
                    flex: "1 1 auto",
                    overflowY: "auto",
                    paddingBottom: "10px",
                    maxHeight: "60vh", // Set the maximum height
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                  url={chapterData[next].fileUrl}
                  width="100%"
                >
                  {chapterData[next].fileUrl.includes("https://") ? (
                    <Document
                      style={{
                        padding: "15px",
                        borderRadius: "5px",
                        height: "50px",
                      }}
                      url={chapterData[next].fileUrl}
                      width="100%"
                      file={chapterData[next].fileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  ) : (
                    <Document
                      style={{
                        padding: "15px",
                        borderRadius: "5px",
                        height: "50px",
                      }}
                      url={chapterData[next].fileUrl}
                      width="100%"
                      file={`https://` + chapterData[next].fileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  )}
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                  <Box>
                    <Button onClick={() => setPageNumber(pageNumber - 1)}>
                      prev
                    </Button>
                    <Button onClick={() => setPageNumber(pageNumber + 1)}>
                      next
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    // Disable right click
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ padding: "15px", borderRadius: "5px" }}
                    url={
                      chapterData &&
                      chapterData.length > 0 &&
                      (chapterData[next].fileUrl.startsWith("http")
                        ? chapterData[next].fileUrl
                        : "https://" + chapterData[next].fileUrl)
                      //  if (!imgUrl.startsWith('http')) {
                      //   imgUrl = "https://"+imgUrl
                      // }
                    }
                    width="100%"
                    height="500px"
                    controls
                    // onClick={() => setBigScrnVideo(chapterData.fileUrl)}
                  />
                </Box>
              )}
            </Box>

            {chapterData && chapterData.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <Button
                  sx={{
                    border: "solid 1px grey",
                    backgroundColor: "#FFFF",
                    color: "#252729",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={handlePrevious}
                >
                  <b>Previous</b>
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#37668F",
                    "&:hover": {
                      backgroundColor: "#37668F", // Prevent background color change on hover
                    },
                    color: "white",
                    width: "100px",
                    border: "solid 1px grey",
                    borderRadius: "5px",
                  }}
                  onClick={handleNext}
                >
                  <b>Next</b>
                </Button>
              </Box>
            )}
            {chapterData && chapterData.length > 0 && (
              <Box
                sx={{
                  padding: "7px",
                }}
              >
                <h2>
                  Title:{" "}
                  {chapterData &&
                    chapterData.length > 0 &&
                    chapterData[next].title}
                </h2>
                <Typography>
                  Description:{" "}
                  {chapterData &&
                    chapterData.length > 0 &&
                    chapterData[next].description}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {loading ? (
          <Box
            sx={{
              width: "30%",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            {Array.from({ length: 1 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: "30%",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              padding: "10px 10px 0 10px",
              display: "flex",
              flexDirection: "column",
              height: "88vh",
              borderRadius: "10px",
              position: "relative",
              border: "solid 1px #d4d6d9",
              background: "#FDFDFD",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 1,
                right: 1,
                // border: "solid 1px",
              }}
            >
              <Button
                sx={{
                  // border: "solid 1px grey",
                  backgroundColor: "#FFFF",
                  "&:hover": {
                    backgroundColor: "#ebe6e6", // Prevent background color change on hover
                  },
                  color: "#252729",
                  width: "100px",
                  borderRadius: "5px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
                onClick={() => navigate(`/lms/preview/courses/${data[0].id}`)}
              >
                <b>Preview</b>
              </Button>
            </Box>
            <Box
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                paddingBottom: "10px",
              }}
            >
              {/* will remove in future by react video player */}
              <Card width="100%">
                <img
                  src={data.length > 0 && data[0].thumbnail}
                  style={{
                    width: "100%",
                    height: "300px", // Set a fixed height for the thumbnail
                    objectFit: "cover", // This will crop the image to fit the height
                  }}
                />
              </Card>
              {isInputOpen ? (
                <Box
                  sx={{
                    padding: "5px",
                    mt: 1,
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="coursename"
                    label="Course name"
                    name="coursename"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                    sx={{ marginBottom: "8px", background: "#FFFF" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="coursedesction"
                    label="Description"
                    name="coursedesction"
                    multiline
                    rows={4}
                    value={courseDescription}
                    onChange={(e) => setCourseDescription(e.target.value)}
                    sx={{ marginBottom: "8px", background: "#FFFF" }}
                  />

                  <Button
                    sx={{
                      border: "solid 1px grey",
                      backgroundColor: "#FFFF",
                      color: "#252729",
                      borderRadius: "5px",
                      mr: 1,
                    }}
                    onClick={() => setIsInputOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#37668F",
                      "&:hover": {
                        backgroundColor: "#37668F", // Prevent background color change on hover
                      },
                      color: "white",
                      border: "solid 1px grey",
                      borderRadius: "5px",
                    }}
                    onClick={handleUpdateCourse}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "5px",
                    mt: 1,
                  }}
                >
                  <h2>
                    {data.length > 0 && data[0].coursename}{" "}
                    <span onClick={() => setIsInputOpen(true)}>
                      <LuPencil />
                    </span>
                  </h2>
                  <Typography sx={{ mt: 0.3 }}>
                    {data.length > 0 && data[0].coursedesction}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px 5px 20px 5px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CiVideoOn
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#37668F",

                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {chapterData && chapterData.length} Chapters
                  </span>
                </Typography>

                {/* For duration commented */}
                {/* <Typography sx={{ display: "flex", gap: "5px", justifyContent:"center", alignItems:"center" }}>
                  <CiClock2
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "orange",
                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {data.length > 0 && data[0].couseduration}
                  </span>
                </Typography> */}
                <Typography
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IoPeopleSharp
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#db6618",
                      borderRadius: "10px",
                      padding: "4px",
                      color: "white",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {(data.length > 0 &&
                      data[0].assign &&
                      data[0].assign.length) ||
                      0}{" "}
                    Enrolled
                  </span>
                </Typography>
              </Box>
              <hr />

              <Box sx={{ marginTop: "10px" }}>
                {chapterData &&
                  chapterData.length > 0 &&
                  chapterData.map((item, index) => (
                    <Accordion key={index} sx={{ marginTop: "10px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "550",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Button onClick={() => toggleUpdateDrawer(item)}>
                          <LuPencil
                            style={{
                              width: "15px",
                              height: "15px",
                              color: "#000",
                            }}
                          />
                        </Button>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.itemdata.map((itm, index) => (
                          <Typography key={index}>
                            <Button onClick={() => toggleItemViewDrawer(itm)}>
                              {itm.title}
                            </Button>
                          </Typography>
                        ))}
                        {(userrole == "masteradmin" ||
                          userrole == "HR" ||
                          userrole == "Manager") && (
                          <Button
                            onClick={() => toggleItemDrawer(item.id)}
                            sx={{
                              backgroundColor: "#37668F",
                              "&:hover": {
                                backgroundColor: "#37668F", // Prevent background color change on hover
                              },
                              color: "white",
                              // width: "100px",
                              border: "solid 1px grey",
                              borderRadius: "5px",
                              // width: "50%",
                            }}
                          >
                            {" "}
                            <FaPlus />
                            Add Item
                          </Button>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {data[0].assessment &&
                  data[0].assessment.map((item, index) => (
                    <Box
                      sx={{
                        padding: "10px 10px 10px 15px",
                        marginTop: 1.5,
                        // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        borderBottom: "solid 1px #A8A8A8",
                      }}
                      key={index}
                    >
                      <Typography onClick={() => handleOpenAssessment(item)}>
                        Assessment{" "}
                        <FaExternalLinkAlt
                          style={{
                            color: "#37668F",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
            {(userrole == "masteradmin" ||
              userrole == "HR" ||
              userrole == "Manager") && (
              <Box
                sx={{
                  flexShrink: 0,
                  width: "100%",
                  background: "white",
                  borderTop: "1px solid #ccc",
                  padding: "10px 0",
                  textAlign: "center",
                }}
              >
                <Button
                  sx={{
                    backgroundColor:
                      chapterData && chapterData.length > 0
                        ? "#37668F"
                        : "#37668F", // Change color if no chapterData
                    animation:
                      chapterData && chapterData.length > 0
                        ? "none"
                        : "blinking 3s infinite", // Add blinking animation if no chapterData
                    "&:hover": {
                      backgroundColor:
                        chapterData && chapterData.length > 0
                          ? "#37668F"
                          : "#37668F", // Prevent background color change on hover
                    },
                    color: "white",
                    width: "100px",
                    border: "solid 1px grey",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  onClick={toggleDrawer}
                >
                  {" "}
                  <FaPlus />
                  Add Chapter
                </Button>

                <style jsx>{`
                  @keyframes blinking {
                    0% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                `}</style>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* Below the drawer will be open to add the new chapter */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <Container sx={{ width: 650 }}>
          <AddChaptertabs
            courseid={id}
            setRefresh={setRefresh}
            refresh={refresh}
            onChapterSubmit={handleChapterData}
            handleTriggerAddChapter={toggleDrawer}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* Below the drawer will be open to Update the chapter */}
      <Drawer
        anchor="right"
        open={isUpdateDrawerOpen}
        onClose={toggleUpdateDrawer}
      >
        <Container sx={{ width: 650 }}>
          <UpdateChapter
            courseid={id}
            chapterItem={chapterItem}
            setRefresh={setRefresh}
            refresh={refresh}
            // toggleUpdateDrawer={toggleUpdateDrawer}
            isUpdateDrawerOpen={isUpdateDrawerOpen}
            setIsUpdateDrawerOpen={setIsUpdateDrawerOpen}
          />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* This below drawer is for open the drawer item to add the new item */}

      <Drawer anchor="right" open={itemId} onClose={() => setItemId(0)}>
        <Container sx={{ width: 550 }}>
          <ChpaterItemDrawer onChapterItemSubmit={handleChapterItemSubmit} />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      <Drawer
        anchor="right"
        open={isItemViewDrawerOpen}
        onClose={toggleItemViewDrawer}
      >
        {/* This is for view item drawer screen */}
        <Container sx={{ width: 700 }}>
          <ChapterItemViewDrawer data={itemViewData} />{" "}
          {/* Rendering CourseDrawer component */}
        </Container>
      </Drawer>

      {/* For view assessment modal*/}
      <Modal
        open={openViewAssessment}
        onClose={() => setOpenViewAssessment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "100vh",
            height: "90vh",
          }}
        >
          <ViewAssessment
            handleTriggerViewAssessmnet={() => setOpenViewAssessment(false)}
            refresh={refresh}
            setRefresh={setRefresh}
            id={assessmentId}
          />{" "}
        </Box>
      </Modal>
    </Container>
  );
}

export default ViewCourses;
