// File: /path_to_component/Repository.js

import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import Module from './Module/Module';
import Category from './Category/Category';

function Repository() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Repo" />
        {/* <Tab label="Category" /> */}
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {selectedTab === 0 && <Module />}
        {selectedTab === 1 && <Category />}
      </Box>
    </Box>
  );
}

export default Repository;
