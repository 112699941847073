import {
  legacy_createStore,
  compose,
  applyMiddleware,
} from "redux";
import { thunk } from "redux-thunk";
import root_reducer from "./reducers";

//for redux devtool extension on chrome
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store
export const store = legacy_createStore(
  root_reducer,
  composeEnhancers(applyMiddleware(thunk))
);
