import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import CourseDrawer from "./CreateCourse/CourseDrawer"; // Importing CourseDrawer component
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import { AiOutlineGlobal } from "react-icons/ai";
import { TiUserAdd } from "react-icons/ti";
import Cookies from "js-cookie";
import AssignCourseDrawer from "./AssignCourse/AssignCourseDrawer";
import { FaHeart, FaLock, FaLockOpen, FaRegBookmark } from "react-icons/fa";
import {
  MdOutlineCreateNewFolder,
  MdOutlinePublic,
  MdOutlinePublicOff,
  MdOutlineSubscriptions,
} from "react-icons/md";
import { LuVideo } from "react-icons/lu";
import { IoAccessibility } from "react-icons/io5";
import AccessCourseDrawer from "./AccessCourse/AccessCourseDrawer";
import { red } from "@mui/material/colors";
import { IoIosHeartEmpty } from "react-icons/io";

function Courses() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const userid = userInfo.userid;
  const company = userInfo.companyname;
  const user = userInfo.username;
  // const userrole = userInfo.userrole
  // console.log(userInfo, "company from user info");

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenForAccess, setIsDrawerOpenForAccess] = useState(false);

  const [isLayerOpen, setIsLayerOpen] = useState(false); // State to manage the visibility of the layer
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [id, setId] = useState(0);
  const [idAccess, setIdAccess] = useState(0);

  // New states for dialogs
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPublicDialogOpen, setIsPublicDialogOpen] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState(null);

  // Fetch videos
  useEffect(() => {
    axios
      .post(`/course/get`, { companyid: companyid.toString() })
      .then((res) => {
        setData(res.data);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  //To disable the right click start
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  // desable right click end

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleDrawerForAssign = (id) => {
    setIsDrawerOpenForAccess(!isDrawerOpenForAccess);
    setId(id);
  };

  const toggleDrawerForAccess = (id) => {
    // alert("Will work soon")
    setIdAccess(id);
  };

  const toggleLayer = () => {
    setIsLayerOpen(!isLayerOpen);
  };

  const handleViewCoursespage = (id) => {
    navigate(`/lms/admin/courses/view/${id}`);
  };

  const handleDeleteCourse = (id) => {
    axios
      .post(`/course/delete`, { id })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const filteredData =
    userrole === "masteradmin"
      ? data
      :  userrole == "HR" || userrole == "Manager"
      ? data.filter(
          (item) =>
            item.userid == userid ||
            (item.assign && item.assign.includes(email))
        )
      : data.filter((item) => item.assign && item.assign.includes(email));
  // console.log(data, "da data")

  const handleMakePublic = (id) => {
    axios
      .post(`/course/public`, {id, user, companyid, email, userid, company })
      .then((res) => {
        setRefresh(!refresh);
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  //For favourite
  const handleAddFavourite = (id) => {
    axios
      .post(`https://klms.vliv.app/course/addfavourite`, {
        id,
        email,
        user,
        userid,
        company,
        companyid,
      })

      .then((res) => {
        setRefresh(!refresh);
        alert(res.data.message);
        
      })
      .catch((err) => console.log(err));
  };

  const openDeleteDialog = (id) => {
    setCurrentCourseId(id);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentCourseId(null);
  };

  const openPublicDialog = (id) => {
    setCurrentCourseId(id);
    setIsPublicDialogOpen(true);
  };

  const closePublicDialog = () => {
    setIsPublicDialogOpen(false);
    setCurrentCourseId(null);
  };

  const confirmDeleteCourse = () => {
    handleDeleteCourse(currentCourseId);
    closeDeleteDialog();
  };

  const confirmMakePublic = () => {
    handleMakePublic(currentCourseId);
    closePublicDialog();
  };

  return (
    <Container maxWidth="100%">
      <Box sx={{ padding: "10px" }}>
        {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
          <Box
            sx={{
              color: "white",
              marginBottom: "5px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "4px",
            }}
          >
            <Button
              sx={{
                background: "#37668F",
                "&:hover": {
                  backgroundColor: "#37668F", // Prevent background color change on hover
                },
                color: "white",
              }}
              onClick={toggleDrawer}
            >
              <MdOutlineCreateNewFolder
                style={{
                  width: "20px",
                  height: "30px",
                  marginTop: "-3px",
                  marginRight: "2px",
                }}
              />
              <Typography>Add Course</Typography>
            </Button>
          </Box>
        )}

        {/* Layer to show all videos */}

        {loading ? (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            // spacing={{ xs: 1, sm: 2, md: 4 }}
            className="card-main-container"
          >
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <div style={{ width: "19%" }}>
                  <div className="card" key={id}>
                    <div
                      style={{ position: "relative", borderRadius: "0.5rem" }}
                    >
                      <img
                        style={{ width: "100%", height: "190px" }}
                        src={item.thumbnail}
                        alt="Image not available"
                        className="courseImg"
                        onClick={() => handleViewCoursespage(item.id)}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          width: 30,
                          height: 30,
                          color: "red",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        title="Delete Course"
                      >
                        <CiTrash
                          onClick={() => openDeleteDialog(item.id)}
                          style={{
                            width: "22px",
                            height: "22px",
                            cursor: "pointer",

                            color: "#2D3747",
                            borderRadius: 5,
                          }}
                        />
                      </Box>
                    </div>
                    <div className="card-details">
                      <div className="card-title-container">
                        <span className="card-title">{item.title}</span>
                        <div className="card-title-icon">
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "1.1rem", display:"flex", justifyContent:"start", textAlign:"start" }}
                          >
                            {item.coursename.length > 25
                              ? item.coursename.substring(0, 20) + "..."
                              : item.coursename}
                          </Typography>

                          {(userrole == "masteradmin" ||
                             userrole == "HR" || userrole == "Manager") && (
                            <div
                              style={{
                                display: "flex",
                                gap: "0.2rem",
                                alignItems: "center",
                                marginTop:"3px"
                              }}
                            >
                              {/* Access course is now commented in future we will give */}
                              <Box title="Acess Course to the user">
                                <IoAccessibility
                                  onClick={() => toggleDrawerForAccess(item.id)}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",

                                    color: "#2D3747",
                                    borderRadius: 5,
                                  }}
                                />
                              </Box>
                          { item &&
                              item.public &&
                              item.public == true ?   <Box title="Public Course">
                                <MdOutlinePublic  
                                  onClick={() => openPublicDialog(item.id)}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",

                                    color: "#2D3747",
                                    borderRadius: 5,
                                  }}
                                />
                              </Box> :
                              <Box title="Private Course">
                              <MdOutlinePublicOff 
                                onClick={() => openPublicDialog(item.id)}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  cursor: "pointer",
                                 
                                  color: "#2D3747",
                                  borderRadius: 5,
                                }}
                              />
                            </Box>}
                              <Box title="Assing Course to the user">
                                <TiUserAdd
                                  onClick={() => toggleDrawerForAssign(item.id)}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",

                                    color: "#2D3747",
                                    borderRadius: 5,
                                  }}
                                />
                              </Box>
                              {item &&
                              item.favourite &&
                              item.favourite.includes(email) ? (
                                <Box title="Make favourite">
                                  <FaHeart 
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      cursor: "pointer",
                                      color: "#eb4034",
                                      borderRadius: 5,
                                    }}
                                    onClick={() => handleAddFavourite(item.id)}
                                  />
                                </Box>
                              ) : (
                                <Box title="Make favourite">
                                  <IoIosHeartEmpty
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      cursor: "pointer",
                                      color: "#2D3747",
                                      borderRadius: 5,
                                    }}
                                    onClick={() => handleAddFavourite(item.id)}
                                  />
                                </Box>
                              )}
                            </div>
                          )}

                          {/* <span>{<MdOutlineSubscriptions />}</span> */}
                        </div>
                      </div>
                      <div className="card-username">
                        <span>{`By ${item.user}`}</span>
                      </div>
              
                      <div className="card-details-container">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          created at - {new Date(item.createdAt).toDateString()}
                        </Typography>
                        <span className="cardchapter">
                          <span
                            className="videoicon"
                            onClick={() => handleViewCoursespage(item.id)}
                          >
                            <LuVideo />
                          </span>

                          <span>
                            {item.chapter && item.chapter.length > 0
                              ? item.chapter.length
                              : 0}
                          </span>
                        </span>
                      </div>
                      <Box sx={{mb:1}}>
                
                    </Box>
                    </div>
                  </div>
                </div>
              ))}
          </Stack>
        )}

        {/* Below the drawer will be open to create the course */}
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <Box sx={{ width: 650 }}>
            <CourseDrawer
              setRefresh={setRefresh}
              refresh={refresh}
              toggleDrawerC={toggleDrawer}
            />{" "}
            {/* Rendering CourseDrawer component */}
          </Box>
        </Drawer>

        {/* For assign to the user screen */}
        <Drawer anchor="right" open={id} onClose={() => setId(0)}>
          <Container sx={{ width: 750 }}>
            <AssignCourseDrawer id={id} setId={setId} />{" "}
            {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>

        {/* For assign to the user screen */}
        <Drawer anchor="right" open={idAccess} onClose={() => setIdAccess(0)}>
          <Container sx={{ width: 750 }}>
            <AccessCourseDrawer setIdAccess={setIdAccess} id={idAccess} />{" "}
            {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>

        {/* Dialog for delete confirmation */}
        <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this course?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog}>Cancel</Button>
            <Button onClick={confirmDeleteCourse} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for public confirmation */}
        <Dialog open={isPublicDialogOpen} onClose={closePublicDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to make changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closePublicDialog}>Cancel</Button>
            <Button onClick={confirmMakePublic} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default Courses;
