import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import pagenotfound from "../assets/404.webp";
const PageNotFound = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} md={12} sm={12}  sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "90vh",
          mt: -1,
          paddingTop: 4,
          paddingBottom: 2,
          gap: 0.5,
        }}>
   
      <Grid item xs={12} lg={12} md={12} sm={12} sx={{}}>
          <img
            src={pagenotfound}
            alt="No Gif"
            width="450"
            height="400"
            style={
              {
                // border: "solid 1px black"
              }
            }
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} sx={{ display: "flex", flexDirection: "column" , justifyContent:"center", alignItems:"center"}}>
            <Typography
              sx={{ fontSize: "22px", color: "#37668F", fontWeight: "600" }}
            >
              404 Page not found
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              Kindly go to correct path
            </Typography>
       </Grid>
      
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
