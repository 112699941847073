import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

function ProtectRoute() {
  const isLoggedIn = Cookies.get("companytoken");
  // console.log(isLoggedIn, "protected route");

  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  } else {
    return <Outlet />;
  }
}

export default ProtectRoute;


