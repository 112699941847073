// Initial state
const initialState = {
  data: [],
  pagination: {}
};

// Reducer function
const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "COURSE_SUCCCESS":
      return {
        ...state,
        data: payload.data,
        pagination: payload.pagination
      };

    case "COURSE_FAILED":
      return {
        ...state,
        data: [],
        pagination: {}
      };
    default:
      return state;
  }
};

export default dashboardReducer;
