import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaComments, FaFile } from "react-icons/fa";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CssBaseline,
  Typography,
  TableContainer,
  Paper,
  CircularProgress,
  Button,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  Box,
  Drawer,
} from "@mui/material";
// import "./Module.css";
import Cookies from "js-cookie";
import { MdDeleteSweep, MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import ContributeModalTabs from "./AddContribution/ContributeModalTabs";
import ContributeModalTabsUpdate from "./UpdateContribution/ContributeModalTabsUpdate";

export default function MyContributes() {
  const pdfRef = useRef(null);
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openAddModule, setOpenAddModule] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  //for for row selection multi delete start
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
  };

  const handleDelete = () => {
    // if (userrole !== "masteradmin" && userrole != "HR" && userrole != "Manager") {
    //   return alert("You are not authorized to delete");
    // }
    axios
      .post(`https://klms.vliv.app/contribution/delete`, { ids: selectedRows })
      .then((res) => {
        // console.log(res.data, "deleted response");
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };
  //for for row selection multi delete end

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/get`, {
        companyid,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      
    },
    {
      field: "module",
      headerName: "Repo",
      flex: 1,
      
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      
      renderCell: (params) => {
        let color;
        let background;
        let borderRadius;
        let padding;
        switch (params.value) {
          case "Pending":
            color = "#B54706";
            background = "#FDB022";
            borderRadius = "5px";
            padding = "2px";
            break;
          case "Approved":
            color = "#05804C";
            background = "#32D583";
            borderRadius = "5px";
            padding = "2px";
            break;
          default:
            color = "#700110";
            background = "#D92D20";
            borderRadius = "5px";
            padding = "2px";
            break;
        }
        return (
          <span style={{ color, background, borderRadius, padding }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
        <>
          <button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleViewButton(params.row.id);
            }}
            style={{
              // height: "17px",
              padding: 1,
              background: "#37668F",
              marginRight: "5px",
              color: "white",
            }}
          >
            View
          </button>

          <button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditButtonClick(params.row.id);
            }}
            style={{
              // height: "17px",
              background: "#37668F",
              marginRight: "5px",
              color: "white",
              padding: 1,
            }}
          >
            Update
          </button>
        </>
      ),
    },
  ];

  const handleTriggerCreateContribute = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleTriggerUpdateContribute = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleEditButtonClick = (id) => {
    setSelectedModuleId(id);
    setOpenEditModal(true);
  };

  const handleViewButton = (id) => {
    navigate(`/kms/contributions/view/${id}`);
  };

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <CssBaseline />
      <>
        <Typography variant="h6" gutterBottom>
          My Contributions
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            sx={{
              backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
              color: "white",
              height: "37px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={handleTriggerCreateContribute}
          >
            Create
          </Button>

          {selectedRows.length > 0 && (
            <MdDeleteSweep
              style={{ width: "25px", height: "25px", color: "red" }}
              onClick={handleDelete}
            />
          )}
        </div>

        <div ref={pdfRef}>
          <DataGrid
           //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onRowClick={handleRowClick}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              handleSelectionModelChange(newSelection);
            }}
          />
        </div>
      </>

      {/* For Create modal */}
      <Modal
        open={isOpenModal}
        onClose={handleTriggerCreateContribute}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            // bgcolor: "background.paper",
            backgroundColor: "#f5f8fa",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <ContributeModalTabs
            refresh={refresh}
            setRefresh={setRefresh}
            handleTriggerCreateContribute={handleTriggerCreateContribute}
          />
        </Box>
      </Modal>

      {/* For Update modal */}
      <Modal
        open={openEditModal}
        onClose={handleTriggerUpdateContribute}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            // bgcolor: "background.paper",
            backgroundColor: "#f5f8fa",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <ContributeModalTabsUpdate
            id={selectedModuleId}
            refresh={refresh}
            setRefresh={setRefresh}
            handleTriggerUpdateContribute={handleTriggerUpdateContribute}
          />
        </Box>
      </Modal>
    </>
  );
}
