import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaComments, FaFile } from "react-icons/fa";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import {
  Container,
  CssBaseline,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  Drawer,
  Box,
} from "@mui/material";
import Cookies from "js-cookie";
import {
  MdDeleteSweep,
  MdEdit,
  MdOutlineRemoveRedEye,
  MdOutlineSportsScore,
} from "react-icons/md";
import { IoAccessibility } from "react-icons/io5";
import AssignUserDrawer from "./AssignUserDrawer";
import AddAssessmentTab from "./AddAssessment/AddAssessmentTab";
import ViewAssessment from "./ViewAssessment/ViewAssessment";
import ScoreAssessment from "./ScoreAssessment/ScoreAssessment";

export default function Assessment() {
  const pdfRef = useRef(null);
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;

  // console.log(userInfo, "userinf")

  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openAddModule, setOpenAddModule] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [accessId, setAccessId] = useState(null);
  const [openAccessModule, setOpenAccessModule] = useState(false);
  const [openAddAssessment, setOpenAddAssessment] = useState(false);
  const [openViewAssessment, setOpenViewAssessment] = useState(false);
  const [openScore, setOpenScore] = useState(false);

  //for for row selection multi delete start
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
  };

  const handleDelete = () => {
    if (userrole !== "masteradmin" && userrole != "HR" && userrole != "Manager") {
      return alert("You are not authorized to delete");
    }
    axios
      .post(`https://klms.vliv.app/assessment/delete`, { ids: selectedRows })
      .then((res) => {
        // console.log(res.data, "deleted response");
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };
  //for for row selection multi delete end

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/assessment/get`, {
        companyid,
        userrole,
        email,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        console.log(res.data, "all assessment");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  //   const handleStatusChange = (id, newStatus) => {
  //     axios
  //       .post(`https://klms.vliv.app/contribution/status`, {
  //         id,
  //         status: newStatus,
  //       })
  //       .then((res) => {
  //         alert(res.data.message);
  //         setRefresh(!refresh);
  //       })
  //       .catch((err) => console.log(err));
  // console.log(id, newStatus, "status on change")
  //   };

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      
    },
    (userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && {
      field: "access",
      headerName: "Assign user in no.",
      flex: 1,
      
      renderCell: (params) => (
        <Box sx={{ marginLeft: "50px", alignItems: "center" }}>
          {params.row.access && params.row.access.length}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      
      renderCell: (params) => (
        <>
          <button
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleViewAssessTrigger(params.row.id);
            }}
            style={{
              // height: "17px",
              background: "#37668F",
              marginRight: "5px",
              color: "white",
              padding: 1,
            }}
          >
            View
          </button>

          {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
            <button
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleAccessTriggerModule(params.row.id);
            }}
            style={{
              // height: "17px",
              background: "#37668F",
              marginRight: "5px",
              color: "white",
              padding: 1,
            }}
          >
            Access
          </button>
          )}
          {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
            <button
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleScoreTrigger(params.row.id);
            }}
            style={{
              // height: "17px",
              background: "#37668F",
              marginRight: "5px",
              color: "white",
              padding: 1,
            }}
          >
            Score
          </button>
          )}
        </>
      ),
    },
  ];

  const handleAddAssessTrigger = () => {
    setOpenAddAssessment(!openAddAssessment);
  };

  const handleViewAssessTrigger = (id) => {
    setAccessId(id);
    setOpenViewAssessment(!openViewAssessment);
    // console.log(id, "inside view")
  };

  const handleAccessTriggerModule = (id) => {
    setAccessId(id);
    setOpenAccessModule(!openAccessModule);
  };

  const handleScoreTrigger = (id) => {
    setAccessId(id);
    setOpenScore(!openScore);
  };

  const handleTriggerCreateContribute = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleTriggerUpdateContribute = () => {
    setOpenEditModal(!openEditModal);
  };

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography variant="h6" gutterBottom>
          Assessment
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {(userrole == "masteradmin" ||  userrole == "HR" || userrole == "Manager") && (
            <Button
              sx={{
                backgroundColor: "#37668F",
                "&:hover": {
                  backgroundColor: "#37668F", // Prevent background color change on hover
                },
                color: "white",
                height: "37px",
                border: "solid 1px grey",
                borderRadius: "5px",
              }}
              onClick={handleAddAssessTrigger}
            >
              Add
            </Button>
          )}
          {selectedRows.length > 0 && (
            <MdDeleteSweep
              style={{ width: "25px", height: "25px", color: "red" }}
              onClick={handleDelete}
            />
          )}
        </div>

        <div ref={pdfRef}>
          <DataGrid
           //  sx={{
              //         "& .MuiDataGrid-columnHeaders": {
              //           backgroundColor: "#F4FAFF ",
              //         },
              //         "& .MuiDataGrid-columnHeaderTitle": {
              //           color: "black",
              //           fontWeight: "bold",
              //         },
              //         "& .MuiDataGrid-columnHeader": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //         "& .MuiDataGrid-columnHeader--sortable:hover": {
              //           backgroundColor: "#F4FAFF",
              //         },
              //       }}
                    autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onRowClick={handleRowClick}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              handleSelectionModelChange(newSelection);
            }}
          />
        </div>

        {/* For add assessment modal*/}
        <Modal
          open={openAddAssessment}
          onClose={handleAddAssessTrigger}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 300,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: "90vh",
              height: "90vh",
            }}
          >
            <AddAssessmentTab
              handleTriggerAssessmnet={handleAddAssessTrigger}
              refresh={refresh}
              setRefresh={setRefresh}
              id={accessId}
            />{" "}
          </Box>
        </Modal>

        {/* For view assessment modal*/}
        <Modal
          open={openViewAssessment}
          onClose={handleViewAssessTrigger}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: "100vh",
              height: "90vh",
            }}
          >
            <ViewAssessment
              handleTriggerViewAssessmnet={handleViewAssessTrigger}
              refresh={refresh}
              setRefresh={setRefresh}
              id={accessId}
            />{" "}
          </Box>
        </Modal>

        {/* For access assessment drawer*/}
        <Drawer
          anchor="right"
          open={openAccessModule}
          onClose={handleAccessTriggerModule}
        >
          {/* This is for view item drawer screen */}
          <Container sx={{ width: 650 }}>
            <AssignUserDrawer
              handleTriggerModule={handleAccessTriggerModule}
              refresh={refresh}
              setRefresh={setRefresh}
              id={accessId}
            />{" "}
            {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>

        {/* For Score assessment drawer*/}
        <Drawer anchor="right" open={openScore} onClose={handleScoreTrigger}>
          {/* This is for view item drawer screen */}
          <Container sx={{ width: 600 }}>
            <ScoreAssessment
              handleTriggerModule={handleScoreTrigger}
              refresh={refresh}
              setRefresh={setRefresh}
              id={accessId}
            />{" "}
            {/* Rendering CourseDrawer component */}
          </Container>
        </Drawer>
      </Container>
    </>
  );
}
