import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { CiCirclePlus } from "react-icons/ci";

function AddManualAssessment({ handleTriggerAssessmnet, setRefresh, refresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;

  const [data, setData] = useState({
    topic: "",
    topicid: Math.random(),
    type: "",
    questions: [],
    status: "Active",
    companyid,
    userid,
    username,
    email,
    passingpercent: "",
  });

  const [numQuestions, setNumQuestions] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "passingpercent") {
      if (value === "" || /^[0-9\b]+$/.test(value)) {
        setData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleQuestionInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = data.questions.map((question, i) => {
      if (i === index) {
        return {
          ...question,
          [name]: value,
        };
      }
      return question;
    });
    setData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
  };

  const handleRadioChange = (e, index) => {
    const updatedQuestions = data.questions.map((question, i) => {
      if (i === index) {
        return {
          ...question,
          correctans: e.target.value,
        };
      }
      return question;
    });
    setData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
  };

  const handleCreateQuestions = () => {
    const newQuestions = Array.from({ length: numQuestions }, () => ({
      id: Math.random(),
      question: "",
      ansa: "",
      ansb: "",
      ansc: "",
      ansd: "",
      correctans: "",
    }));
    setData((prevData) => ({
      ...prevData,
      questions: newQuestions,
    }));
  };

  const handleSubmit = () => {
    //   console.log(data, "data");
    if (
      data.type === "" ||
      data.topic === "" ||
      data.questions.length <= 0 ||
      data.questions.some(
        (question) =>
          question.question === "" ||
          question.ansa === "" ||
          question.ansb === "" ||
          question.ansc === "" ||
          question.ansd === "" ||
          question.correctans === ""
      )
    ) {
      return alert("All fields are mandatory. Kindly fill all");
    }
    axios
      .post(`https://klms.vliv.app/assessment/add`, data)
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
        handleTriggerAssessmnet();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container sx={{ width: "100%" }}>
      <Box
        sx={{
          marginTop: 1,
          maxHeight: "65vh",
          overflowY: "auto",
          height: "65vh",
        }}
      >
        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label style={{ marginBottom: 2 }}>Type</label> <br />
          <select
            name="type"
            value={data.type}
            onChange={handleInputChange}
            style={{
              width: "100%",
              height: "40px",
              marginTop: 2,
              border: "1px #cdcfd1 solid",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            <option value="" style={{ fontSize: "18px" }}>
              Select
            </option>
            <option value="Quiz" style={{ fontSize: "16px" }}>
              Quiz
            </option>
            <option value="Assessment" style={{ fontSize: "16px" }}>
              Assessment
            </option>
          </select>
        </Box>

        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label>Topic</label>
          <br />
          <TextField
            placeholder="Topic"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
            name="topic"
            multiline
            value={data.topic}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label>Passing percentage</label>
          <br />
          <TextField
            placeholder="passing percentage"
            variant="outlined"
            size="small"
            type="number"
            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
            name="passingpercent"
            multiline
            value={data.passingpercent}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ marginBottom: 1, width: "100%" }}>
          <label>Create multiple Question fields</label> <br />
          <Box sx={{ display: "flex", gap: "4px" }}>
            <TextField
              // placeholder="Add numbers"
              type="number"
              variant="outlined"
              size="small"
              sx={{ width: "100%", background: "#ffff" }}
              value={numQuestions}
              onChange={(e) => setNumQuestions(parseInt(e.target.value))}
            />
            {/* <Button onClick={handleCreateQuestions}>Create</Button> */}
          </Box>
          <Box sx={{ mt: 1.5, width: "100%", display:"flex", justifyContent:"center", alignItems:"center" }}>
            <Button
              sx={{
                width: "50%",
                height:"35px",
                background: "#37668F",
                "&:hover": {
                  backgroundColor: "#37668F",
                },
                color:"white"
              }}
              onClick={handleCreateQuestions}
            >
              <CiCirclePlus
                style={{
                  width: "25px",
                  height: "25px",
                  // background: "#37668F",
                  color: "white",
                  borderRadius: 5,
                }}
                
              />
              Add Questions
            </Button>
          </Box>
        </Box>

        {data.questions.map((question, index) => (
          <Box key={index} sx={{ marginBottom: 2, mt:2 }}>
            <hr
              style={{ width: "100%", height: "2px", background: "grey", marginBottom:2 }}
            ></hr>
            <Box sx={{ marginBottom: 1, width: "100%", mt:2 }}>
              <label>Question {index + 1}</label> <br />
              <TextField
                placeholder="Question"
                variant="outlined"
                size="small"
                sx={{
                  marginBottom: 2,
                  width: "100%",
                  mt: 1,
                  background: "#ffff",
                }}
                name="question"
                multiline
                value={question.question}
                onChange={(e) => handleQuestionInputChange(e, index)}
              />
            </Box>
            <h3 style={{ marginTop: 4, marginBottom: 8 }}>
              Put the options below
            </h3>
            <Box sx={{ marginBottom: 1, width: "100%" }}>
              <label>Option A</label> <br />
              <TextField
                placeholder="Option A"
                variant="outlined"
                   size="small"
                sx={{
                  marginBottom: 2,
                  width: "100%",
                  mt: 1,
                  background: "#ffff",
                }}
                name="ansa"
                multiline
                value={question.ansa}
                onChange={(e) => handleQuestionInputChange(e, index)}
              />
            </Box>
            <Box sx={{ marginBottom: 1, width: "100%" }}>
              <label>Option B</label> <br />
              <TextField
                placeholder="Option B"
                variant="outlined"
                size="small"
                sx={{
                  marginBottom: 2,
                  width: "100%",
                  mt: 1,
                  background: "#ffff",
                }}
                name="ansb"
                multiline
                value={question.ansb}
                onChange={(e) => handleQuestionInputChange(e, index)}
              />
            </Box>
            <Box sx={{ marginBottom: 1, width: "100%" }}>
              <label>Option C</label> <br />
              <TextField
                placeholder="Option C"
                variant="outlined"
                  size="small"
                sx={{
                  marginBottom: 2,
                  width: "100%",
                  mt: 1,
                  background: "#ffff",
                }}
                name="ansc"
                multiline
                value={question.ansc}
                onChange={(e) => handleQuestionInputChange(e, index)}
              />
            </Box>
            <Box sx={{ marginBottom: 1, width: "100%" }}>
              <label>Option D</label> <br />
              <TextField
                placeholder="Option D"
                variant="outlined"
                   size="small"
                sx={{
                  marginBottom: 2,
                  width: "100%",
                  mt: 1,
                  background: "#ffff",
                }}
                name="ansd"
                multiline
                value={question.ansd}
                onChange={(e) => handleQuestionInputChange(e, index)}
              />
            </Box>
            <FormControl>
              <h3>Choose the correct option</h3>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={question.correctans}
                onChange={(e) => handleRadioChange(e, index)}
              >
                <FormControlLabel
                  value="ansa"
                  control={<Radio />}
                  label="Option A"
                />
                <FormControlLabel
                  value="ansb"
                  control={<Radio />}
                  label="Option B"
                />
                <FormControlLabel
                  value="ansc"
                  control={<Radio />}
                  label="Option C"
                />
                <FormControlLabel
                  value="ansd"
                  control={<Radio />}
                  label="Option D"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "white",
            color: "grey",
            width: "100px",
            border: "solid 1px grey",
            borderRadius: "5px",
          }}
          onClick={handleTriggerAssessmnet}
        >
          <b>Cancel</b>
        </Button>

        <Button
          sx={{
            border: "solid 1px grey",
            backgroundColor: "#37668F",
            "&:hover": {
              backgroundColor: "#37668F", // Prevent background color change on hover
            },
            color: "#FFFF",
            width: "100px",
            borderRadius: "5px",
          }}
          onClick={handleSubmit}
        >
          <b>Submit</b>
        </Button>
      </Box>
    </Container>
  );
}

export default AddManualAssessment;
