import React from "react";
import "./App.css";
import "./styles/layout.css";
import "./styles/module.css";
import "./styles/theme.css";
import {
  Box,
  Button,
  Container,
  Link,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { routes1, otherroutes } from "./Constrant/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AllRoutes from "./Routes/AllRoutes";
import Navbar from "./Components/Navbar/Navbar";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { MdGroups } from "react-icons/md";
import { TbBulb } from "react-icons/tb";
import { IoBookSharp } from "react-icons/io5";
import SettingsIcon from "@mui/icons-material/Settings";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const segments = currentPath.split("/");
  const kmsorlms = segments[1];

  console.log(kmsorlms, "url");

  const [theme, setTheme] = useState(false);
  const [selectedMainRoute, setSelectedMainRoute] = useState(currentPath);
  const [showButtons, setShowButtons] = useState(true);

  const toggleTheme = () => {
    setTheme((prevTheme) => !prevTheme);
  };
  const toggleButtons = () => {
    setShowButtons((prevShowButtons) => !prevShowButtons);
  };

  const darkTheme = createTheme({
    palette: {
      mode: theme ? "dark" : "light",
    },
  });

  const isActive = (path) => {
    return currentPath === path;
  };

  const handleMainRouteClick = (path) => {
    setSelectedMainRoute(path);
    navigate(path);
  };

  const renderSubRoutes = (subRoutes) => {
    return subRoutes.map((subRoute, ind) => (
      <Button
        key={ind}
        startIcon={subRoute.iconComponent}
        className={
          currentPath.includes(subRoute.path)
            ? "subnav-active-btn"
            : "sub-nav-btn"
        }
        onClick={() => navigate(subRoute.path)}
      >
        {subRoute.name}
      </Button>
    ));
  };

  const handleKMSLMSClick = (route) => {
    navigate(`/${route}/dashboard`);
  };

  const hiddenNavPaths = ["/signin", "/forgotpassword", "/resetpassword"];
  const shouldHideNav = hiddenNavPaths.includes(currentPath);

  console.log(currentPath, "current path");
  console.log(selectedMainRoute, "selectec main route");
  return (
    <>
      {!hiddenNavPaths.includes(currentPath) && (
        <Box
          sx={{
            minWidth: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
          }}
        >
          <Navbar theme={theme} toggleTheme={toggleTheme} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          // marginTop: currentPath != "/signin" ? "10px" : "0", // Offset for fixed navbar
          minHeight: hiddenNavPaths.includes(currentPath) ? "90vh" : "auto",
          marginTop: !hiddenNavPaths.includes(currentPath) ? "10px" : "0", // Offset for fixed navbar
          width: hiddenNavPaths.includes(currentPath) ? "100vw" : "auto", // Full width for signin
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          {currentPath != "/signin" && (
            <Box
              sx={{
                width: showButtons ? "70px" : "40px",
                position: "fixed",
                left: 0,
                top: "64px", // Offset for fixed navbar
                bottom: 0,
                bgcolor: "#F4FAFF",
                transition: "width 0.3s ease-in-out",
                zIndex: 999,
                boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
                // border: "solid 1px #dbdbd9",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // ml: "-10px",
                  padding: 0.5,
                  mt: 2.2,
                  alignItems: "center",
                }}
              >
                {showButtons ? (
                  <AiOutlineMenuFold
                    style={{ width: "24px", height: "24px" }}
                    onClick={() => setShowButtons(false)}
                  />
                ) : (
                  <AiOutlineMenuUnfold
                    style={{ width: "24px", height: "24px" }}
                    onClick={() => setShowButtons(true)}
                  />
                )}
              </Typography>
              {showButtons && (
                <Box
                  className={showButtons ? "fade-in" : "fade-out"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1.4,
                    gap: 1,
                    padding: 0.4,
                  }}
                >
                  <Box className="second-nav">
                    {routes1.map((route, ind) => (
                      <Tooltip
                        key={ind}
                        title={route.iconComponent?.props?.title || ""}
                      >
                        <span>
                          <Button
                            sx={{
                              // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
                              color: "black",
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              // mb: 0.6,
                            }}
                            onClick={() => {
                               if(route.path == '/hrms'){
                                return(
                                  <Link href="https://hrms.vliv.app"></Link>
                                )
                               }else{
                                handleMainRouteClick(route.path);
                               }
                            }}
                            disabled={!!route.iconComponent?.props?.title}
                          >
                            <Typography>
                              {route.name === "KMS" ? (
                                <Typography
                                  sx={{
                                    background:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "#37668F"
                                        : "#FBFBFB",
                                    padding: 1,
                                    borderRadius: 2,
                                    width: "40px",
                                    height: "40px",
                                    mb: "4px",
                                    boxShadow:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                                        : "",
                                  }}
                                >
                                  <TbBulb
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      color:
                                        currentPath.includes(route.path) ||
                                        selectedMainRoute === route.path ||
                                        kmsorlms === route.path.split("/")[1]
                                          ? "#FFFF"
                                          : "grey",
                                      // border:"solid 1px black"
                                    }}
                                  />
                                </Typography>
                              ) : route.name === "LMS" ? (
                                <Typography
                                  sx={{
                                    background:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "#37668F"
                                        : "#FBFBFB",
                                    padding: 1,
                                    borderRadius: 2,
                                    width: "40px",
                                    height: "40px",
                                    mb: "4px",
                                    boxShadow:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                                        : "",
                                  }}
                                >
                                  <IoBookSharp
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      color:
                                        currentPath.includes(route.path) ||
                                        selectedMainRoute === route.path ||
                                        kmsorlms === route.path.split("/")[1]
                                          ? "#FFFF"
                                          : "grey",
                                    }}
                                  />
                                </Typography>
                              ) : route.name === "Settings" ? (
                                <Typography
                                  sx={{
                                    background:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "#37668F"
                                        : "#FBFBFB",
                                    padding: 1,
                                    borderRadius: 2,
                                    width: "40px",
                                    height: "40px",
                                    mb: "4px",
                                    boxShadow:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                                        : "",
                                  }}
                                >
                                  {" "}
                                  <SettingsIcon
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      color:
                                        currentPath.includes(route.path) ||
                                        selectedMainRoute === route.path ||
                                        kmsorlms === route.path.split("/")[1]
                                          ? "#FFFF"
                                          : "grey",
                                    }}
                                  />
                                </Typography>
                              ) : (
                                // This is for HRMS button
                                <Typography
                                  sx={{
                                    background:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "#37668F"
                                        : "#FBFBFB",
                                    padding: 1,
                                    borderRadius: 2,
                                    width: "40px",
                                    height: "40px",
                                    mb: "4px",
                                    boxShadow:
                                      currentPath.includes(route.path) ||
                                      selectedMainRoute === route.path ||
                                      kmsorlms === route.path.split("/")[1]
                                        ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                                        : "",
                                  }}
                                >
                                  {" "}
                                  <Link href="https://hrms.vliv.app">
                                    <MdGroups
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        color:
                                          currentPath.includes(route.path) ||
                                          selectedMainRoute === route.path ||
                                          kmsorlms === route.path.split("/")[1]
                                            ? "#FFFF"
                                            : "grey",
                                      }}
                                    />
                                  </Link>
                                </Typography>
                              )}

                              <Typography
                                style={{
                                  mt: "4px",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color:
                                    currentPath.includes(route.path) ||
                                    selectedMainRoute === route.path ||
                                    kmsorlms === route.path.split("/")[1]
                                      ? "#37668F"
                                      : "grey",
                                }}
                              >
                                {route.name == "Settings" ? "" : route.name}
                              </Typography>
                            </Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    ))}
                  </Box>

                  {/* <Button
                    sx={{
                      // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    href="https://hrms.vliv.app"
                  >
                    <Typography sx={{ padding: 0.5, borderRadius: 2 }}>
                      <MdGroups
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "grey",
                        }}
                      />
                    </Typography>
                    <Typography
                      style={{
                        color: "grey",
                        mt: "4px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      HRM
                    </Typography>
                  </Button> */}
                </Box>
              )}
            </Box>
          )}

          <Box
            sx={{
              flexGrow: 1,
              marginBottom: "0px",
              boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
              marginLeft:
                currentPath != "/signin"
                  ? showButtons
                    ? "70px"
                    : "40px"
                  : "0",
              marginTop: !hiddenNavPaths.includes(currentPath) ? "64px" : "0",
              transition: "margin-left 0.3s ease-in-out",
              position: "relative",
            }}
          >
            {!shouldHideNav && currentPath != "/signin" && (
              <>
                <Box className="second-nav1 third-nav">
                  {selectedMainRoute &&
                    routes1
                      .find((route) => route.path.split("/")[1] === kmsorlms)
                      ?.subRoutes?.map((subRoute, ind) => (
                        <Tooltip
                          key={ind}
                          title={subRoute.iconComponent?.props?.title || ""}
                        >
                          <span>
                            <Button
                              startIcon={subRoute.iconComponent}
                              className={
                                currentPath.includes(subRoute.path)
                                  ? "secondnav-active-btn1"
                                  : "second-nav-btn1"
                              }
                              onClick={() => {
                                if (!subRoute.iconComponent?.props?.title) {
                                  navigate(subRoute.path);
                                }
                              }}
                              disabled={!!subRoute.iconComponent?.props?.title}
                            >
                              {subRoute.name}
                            </Button>
                          </span>
                        </Tooltip>
                      ))}
                </Box>
              </>
            )}
            <Box>
              <AllRoutes />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default App;
