import { Typography } from '@mui/material'
import React from 'react'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a color="inherit" href="http://206.189.128.35:6001" target="_blank" className='linktext'>
        Intra Management System
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright