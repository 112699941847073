import { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Card,
  Skeleton,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import SendIcon from "@mui/icons-material/Send";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { FaRegBell, FaRegCommentDots } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { GrUploadOption } from "react-icons/gr";
import { MdCancel } from "react-icons/md";
import ReactPlayer from "react-player";
import io from "socket.io-client";
import { notifySuccess } from "../../Constrant/toastAlert";

const socket = io("https://klms.vliv.app");

const Notification = ({ open, handleClose }) => {
  const inputRef = useRef(null);
  const commentInputRef = useRef(null);

  const companyInfoCookie = Cookies.get("userinfo");
  const companyInfo = companyInfoCookie ? JSON.parse(companyInfoCookie) : {};
  const companyid = companyInfo.companyid;
  const userid = companyInfo.userid;
  const username = companyInfo.username;
  const company = companyInfo.company;
  const companyemail = companyInfo.companyemail;

  //Socket io code start
  const [notifications, setNotifications] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const toggleRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  useEffect(() => {
    // For addcourse socket
    socket.on("courseAdded", (data) => {
      notifySuccess(data.message);
      toggleRefresh();
    });

    // For Compliace socket
    socket.on("complianceAdded", (data) => {
      notifySuccess(data.message);
      toggleRefresh();
    });

    return () => {
      socket.off("courseAdded");
      socket.off("complianceAdded");
    };
  }, []);

  useEffect(() => {
    //Get notification api
    axios
      .post(`https://klms.vliv.app/notification/get`, { companyid })
      .then((res) => {
        setNotificationData(res.data);
        // console.log(res.data, "get notification data");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  // useEffect(()=>{
  //   notifySuccess(notifications)
  // },[notifications])
  //Socket io code end

  // console.log(Notification, "socket io notification");
  // For image upload input ref

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{ width: "100%" }}
    >
      <Container sx={{ width: 400 }}>
        <MdCancel
          onClick={handleClose}
          style={{
            marginLeft: "-20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "18px", fontWeight: "bold" }}
        >
          Notification
        </Typography>
        <Box sx={{ padding: "5px", marginTop: "3rem" }}>
          {notificationData.length > 0 &&
            notificationData.map((notification, index) => (
              <Tooltip
                title={notification.message}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "1.25rem", // Adjust the font size as needed
                    },
                  },
                }}
                key={index}
              >
                <Box
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    height: "5vh",
                    alignItems: "center",
                    gap: 1,
                    padding: "8px",
                    borderRadius: "6px",
                    mt: 2,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography>
                    <FaRegBell
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "5px",
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "",
                      textAlign: "center",
                    }}
                  >
                    {notification.message}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        </Box>
      </Container>
    </Drawer>
  );
};

export default Notification;
