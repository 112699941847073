import { Box, Button, Container, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Favourite() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/course/getfavourite`, { email })
      .then((res) => {
        setData(res.data);
        // console.log(res.data, "favourite data")
      })
      .catch((err) => console.log(err));
  }, []);

  const handleViewCoursespage = (id) => {
    navigate(`/lms/view/courses/${id}`);
  };
  return (
    <Container maxWidth="100%">
      <Box sx={{ padding: "10px" }}>
        {/* This below box is just given to give margin same as course in course we 
        have button so we can see the gap between navbar and videos dashboard */}
        <Box
          sx={{
            color: "white",
            marginBottom: "5px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "4px",
          }}
        >
          <Button></Button>
        </Box>

        {loading ? (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ padding: "10px", borderRadius: "4px" }}>
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            {data.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.4}
                key={index}
                sx={{ position: "relative" }}
              >
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "15px",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <img
                    src={item.thumbnail}
                    alt="Thumbnail"
                    style={{
                      width: "100%",
                      height: "24vh",
                      borderRadius: "10px",
                    }}
                    onClick={() => handleViewCoursespage(item.id)}
                  />
                  <Box sx={{padding:1}}>
                    <Box
                      sx={{
                        width: "16vw",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 0px 5px 5px",
                        mt: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                        >
                          {item.coursename.length > 25
                            ? item.coursename.substring(0, 25) + "..."
                            : item.coursename}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 0 3px 3px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "grey",
                        }}
                      >
                        created at -{new Date(item.createdAt).toDateString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
}

export default Favourite;
