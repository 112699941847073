import { combineReducers } from "redux"
import loadReducer from "./load.reducer"
import loginReducer from "./login.reducer"
import dashboardReducer from "./dashboard.reducer";
// import { userReducer } from "./companyUser.reducer"

 const root_reducer = combineReducers({
    loadReducer,
    loginReducer,
    dashboardReducer
})

export default root_reducer;