import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PiGraduationCapLight } from "react-icons/pi";
import { GiGraduateCap, GiProgression } from "react-icons/gi";

import ReactApexChart from "react-apexcharts";
import CanvasJSReact from "@canvasjs/react-charts";
import { MdGroups, MdOutlineAssessment, MdOutlineQuiz } from "react-icons/md";
import { AiOutlineDotChart } from "react-icons/ai";
import { FaRegFileAlt, FaRegHandshake } from "react-icons/fa";
import { FiUserPlus, FiUserX } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";
import { VscMilestone } from "react-icons/vsc";
import { IoBookOutline } from "react-icons/io5";
import { TiGroupOutline } from "react-icons/ti";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { FcFaq } from "react-icons/fc";
import { TbReportAnalytics } from "react-icons/tb";

//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DashboardView() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const username = userInfo.username;

  const [totalCourse, setTotalCourse] = useState("");
  const [learnersEmails, setLearnersEmails] = useState([]);
  const [totalQuiz, setTotalQuiz] = useState([]);
  const [totalAssessment, setTotalAssessment] = useState([]);
  const [totalAssigneAssessment, setTotalAssignAssement] = useState([]);
  const [totalAssignQuiz, setTotalAssignQuiz] = useState([]);

  const [monthlyData, setMonthlyData] = useState([]);
  const [courseCompletePercent, setCourseCompletePercent] = useState(0);

  const [greeting, setGreeting] = useState("");

  const [pass, setPass] = useState([]);
  const [fail, setFail] = useState([]);

  // For kms use states
  const [allContributions, setAllContributions] = useState([]);
  const [allSop, setAllSop] = useState([]);
  const [allFaq, setAllFaq] = useState([]);
  const [allGuides, setAllGuides] = useState([]);
  const [allUsers, setAllUsers] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);
// console.log(allContributions, "allContributions")
  const [uniqueUsersForSop, setUniqueUsersForSop] = useState(new Set());
  const [uniqueUsersForFaq, setUniqueUsersForFaq] = useState(new Set());
  const [uniqueUsersForGuide, setUniqueUsersForGuide] = useState(new Set());

  // const [monthlyUniqueUsersForSop, setMonthlyUniqueUsersForSop] = useState([]);
  // const [monthlyUniqueUsersForFaq, setMonthlyUniqueUsersForFaq] = useState([]);
  // const [monthlyUniqueUsersForGuide, setMonthlyUniqueUsersForGuide] = useState(
  //   []
  // );

  const [allDepartments, setAllDepartments] = useState([]);
  const [departmentContribution, setDepartmentContribution] = useState([]);
  const [departmentContributionData, setDepartmentContributionData] = useState(
    []
  );

  // For bar chart for SOP, FAQ and GUIDES start

  const [monthlyUniqueUsersForSop, setMonthlyUniqueUsersForSop] = useState([]);
  const [monthlyUniqueUsersForFaq, setMonthlyUniqueUsersForFaq] = useState([]);
  const [monthlyUniqueUsersForGuide, setMonthlyUniqueUsersForGuide] = useState(
    []
  );

  const [selectedYearSop, setSelectedYearSop] = useState(
    new Date().getFullYear()
  );
  const [selectedYearFaq, setSelectedYearFaq] = useState(
    new Date().getFullYear()
  );
  const [selectedYearGuide, setSelectedYearGuide] = useState(
    new Date().getFullYear()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          `https://klms.vliv.app/contribution/getlist`,
          {
            companyid,
            userrole,
            department: "",
            email,
          }
        );

        const filterByYear = (data, year) =>
          data.filter(
            (elem) => new Date(elem.createdAt).getFullYear() === year
          );

        const sopData = filterByYear(res.data, selectedYearSop).filter(
          (elem) => elem.category === "SOP"
        );
        const faqData = filterByYear(res.data, selectedYearFaq).filter(
          (elem) => elem.category === "FAQ"
        );
        const guideData = filterByYear(res.data, selectedYearGuide).filter(
          (elem) => elem.category === "GUIDES"
        );

        const calculateMonthlyUniqueUsers = (data) => {
          const monthlyUniqueUsersMap = Array.from(
            { length: 12 },
            () => new Set()
          );

          data.forEach((item) => {
            const monthIndex = new Date(item.createdAt).getMonth();
            if (item.access && item.access.length > 0) {
              item.access.forEach((user) => {
                monthlyUniqueUsersMap[monthIndex].add(user);
              });
            }
          });

          return monthlyUniqueUsersMap.map((set, index) => ({
            month: new Date(0, index).toLocaleString("default", {
              month: "long",
            }),
            userCount: set.size,
          }));
        };

        setMonthlyUniqueUsersForSop(calculateMonthlyUniqueUsers(sopData));
        setMonthlyUniqueUsersForFaq(calculateMonthlyUniqueUsers(faqData));
        setMonthlyUniqueUsersForGuide(calculateMonthlyUniqueUsers(guideData));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [
    selectedYearSop,
    selectedYearFaq,
    selectedYearGuide,
    companyid,
    userrole,
    email,
  ]);

  const handleYearChange = (chartType, direction) => {
    if (chartType === "SOP") {
      setSelectedYearSop((prev) => prev + direction);
    } else if (chartType === "FAQ") {
      setSelectedYearFaq((prev) => prev + direction);
    } else if (chartType === "GUIDES") {
      setSelectedYearGuide((prev) => prev + direction);
    }
  };

  const getBarOption = (monthlyUniqueUsers) => ({
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: { includeZero: true },
    data: [
      {
        type: "bar",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: monthlyUniqueUsers.map((entry) => ({
          label: entry.month,
          y: entry.userCount,
          color: "#D6BBFB",
        })),
      },
    ],
  });

  const getBarOptionFaq = (monthlyUniqueUsers) => ({
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: { includeZero: true },
    data: [
      {
        type: "bar",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: monthlyUniqueUsers.map((entry) => ({
          label: entry.month,
          y: entry.userCount,
          color: "#B9E6FE",
        })),
      },
    ],
  });

  // For bar chart for SOP, FAQ and GUIDES end

  useEffect(() => {
    axios
      .post(`https://klms.vliv.app/contribution/getlist`, {
        companyid,
        userrole,
        department: "",
        email,
      })
      .then((res) => {
        setAllContributions(res.data.length > 0 ? res.data : []);
        const sopData = res.data.filter((elem) => {
          return elem.category == "SOP";
        });
        const faqData = res.data.filter((elem) => {
          return elem.category == "FAQ";
        });
        const guideData = res.data.filter((elem) => {
          return elem.category == "GUIDES";
        });
        const approveData = res.data.filter((elem) => {
          return elem.status == "Approved";
        });
        const pendingData = res.data.filter((elem) => {
          return elem.status == "Pending";
        });
        const rejectedData = res.data.filter((elem) => {
          return elem.status == "Rejected";
        });

        // console.log(sopData, "Sop filtered data")
        setAllSop(sopData);
        setAllFaq(faqData);
        setAllGuides(guideData);

        // To get Unique users for Sop that how many users has this Sop assigned
        const usersSop = new Set();
        sopData.forEach((entry) => {
          if (entry.access && entry.access.length > 0) {
            entry.access.forEach((user) => {
              usersSop.add(user);
            });
          }
        });
        setUniqueUsersForSop(usersSop);

        // To get Unique users for Faq that how many users has this Faq assigned
        const usersFaq = new Set();
        faqData.forEach((entry) => {
          if (entry.access && entry.access.length > 0) {
            entry.access.forEach((user) => {
              usersFaq.add(user);
            });
          }
        });
        setUniqueUsersForFaq(usersFaq);

        // To get Unique users for Guides that how many users has this Guides assigned
        const usersGuide = new Set();
        guideData.forEach((entry) => {
          if (entry.access && entry.access.length > 0) {
            entry.access.forEach((user) => {
              usersGuide.add(user);
            });
          }
        });
        setUniqueUsersForGuide(usersGuide);

        const approvePercentage =
          res.data.length > 0
            ? (approveData.length / res.data.length) * 100
            : 0;
        const pendingPercentage =
          res.data.length > 0
            ? (pendingData.length / res.data.length) * 100
            : 0;
        const rejectPercentage =
          res.data.length > 0
            ? (rejectedData.length / res.data.length) * 100
            : 0;

        setChartOptionsForApprove((prev) => ({
          ...prev,
          series: [approvePercentage.toFixed(2)],
        }));

        setChartOptionsForPending((prev) => ({
          ...prev,
          series: [pendingPercentage.toFixed(2)],
        }));
        setChartOptionsForReject((prev) => ({
          ...prev,
          series: [rejectPercentage.toFixed(2)],
        }));
        // console.log(res.data, "all data");

        // Department-wise Contribution Calculation
        const departmentContributions = {};
        res.data.forEach((entry) => {
          const department = entry.department;
          if (!departmentContributions[department]) {
            departmentContributions[department] = 0;
          }
          departmentContributions[department]++;
        });

        // Transform to Data Points for Pie Chart
        const departmentContributionArray = Object.keys(
          departmentContributions
        ).map((department) => ({
          label: department,
          y: departmentContributions[department],
        }));

        setDepartmentContributionData(departmentContributionArray);
      })
      .catch((err) => console.log(err));

    // To get All users
    axios
      .post(`https://klms.vliv.app/course/allusers`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        setAllUsers(res.data.length);

        const uniqueDepartment = new Set();

        const departmentData = res.data.map((elem) => {
          return uniqueDepartment.add(elem.department);
        });
        // console.log(uniqueDepartment, "unique department")
        setAllDepartments(Array.from(uniqueDepartment));

        const departmentContributionData = res.data.filter((elem) => {
          return Array.from(uniqueDepartment).includes(elem.department);
        });
        console.log(departmentContributionData, "departmentContributionData");
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  // console.log(allDepartments, "all departments");

  // ChartJs for Pie chart
  // Pie chart options
  const pieOption = {
    animationEnabled: true,
    legend: {
      horizontalAlign: "right",
      verticalAlign: "center",
    },
    data: [
      {
        type: "pie",
        startAngle: 240,
        explodeOnClick: true,
        indexLabel: "{label} {y}",
        dataPoints: departmentContributionData,
      },
    ],
  };

  //   For gauge chart
  const [chartOptionsForApprove, setChartOptionsForApprove] = useState({
    series: [0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
  });

  const [chartOptionsForPending, setChartOptionsForPending] = useState({
    series: [0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
  });

  const [chartOptionsForReject, setChartOptionsForReject] = useState({
    series: [75],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
  });

  // Tab view for users bar chart faq, sop, guides start
  const [activeTab, setActiveTab] = useState(0);

  const charts = [
    {
      title: "SOP",
      data: monthlyUniqueUsersForSop,
      getOption: getBarOption,
      year: selectedYearSop,
    },
    {
      title: "FAQ",
      data: monthlyUniqueUsersForFaq,
      getOption: getBarOptionFaq,
      year: selectedYearFaq,
    },
    {
      title: "GUIDES",
      data: monthlyUniqueUsersForGuide,
      getOption: getBarOption,
      year: selectedYearGuide,
    },
  ];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderChart = (chart) => (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      p={1}
      sx={{
        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: "10px",
      }}
      key={chart.title}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            mb: 1,
            ml: 1,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          <AiOutlineDotChart />
          <span> {chart.title}</span>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
          <IconButton onClick={() => handleYearChange(chart.title, -1)}>
            <ArrowBackIos />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {chart.year}
          </Typography>
          <IconButton onClick={() => handleYearChange(chart.title, 1)}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      <CanvasJSChart
        options={chart.getOption(chart.data)}
        containerProps={{ width: "100%", height: "400px" }}
      />
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Users
        </Typography>
      </Box>
    </Grid>
  );
  // Tab view for users bar chart faq, sop, guides end

  //===================Top 5 conribution chart code start=================
  const [topSopContributors, setTopSopContributors] = useState([]);
  const [topFaqContributors, setTopFaqContributors] = useState([]);
  const [topGuideContributors, setTopGuideContributors] = useState([]);
  
  // const [selectedYearSop, setSelectedYearSop] = useState(new Date().getFullYear());
  // const [selectedYearFaq, setSelectedYearFaq] = useState(new Date().getFullYear());
  // const [selectedYearGuide, setSelectedYearGuide] = useState(new Date().getFullYear());
  
  const [activeTabTopFive, setActiveTabTopFive] = useState(0);
  
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // +1 because months are 0-indexed
  
  // Modify the fetchTopContributors function
  const fetchTopContributors = (data, category, year, month) => {
    const filteredData = data
      .filter(
        (elem) =>
          elem.category === category &&
          new Date(elem.createdAt).getFullYear() === year &&
          new Date(elem.createdAt).getMonth() + 1 === month && // Month filter
          elem.status === "Approved"
      )
      .reduce((acc, curr) => {
        const contributor = curr.username || "Unknown"; // Handle undefined contributor
        if (contributor in acc) {
          acc[contributor]++;
        } else {
          acc[contributor] = 1;
        }
        return acc;
      }, {});
  
    const sortedContributors = Object.entries(filteredData)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([contributor, count]) => ({
        contributor,
        count,
      }));
  
    return sortedContributors;
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`https://klms.vliv.app/contribution/getlist`, {
          companyid,
          userrole,
          department: "",
          email,
        });
  
        setTopSopContributors(fetchTopContributors(res.data, "SOP", selectedYear, selectedMonth));
        setTopFaqContributors(fetchTopContributors(res.data, "FAQ", selectedYear, selectedMonth));
        setTopGuideContributors(fetchTopContributors(res.data, "GUIDES", selectedYear, selectedMonth));
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchData();
  }, [selectedYear, selectedMonth]);
  
  // Adjust the handleMonthChange function
  const handleMonthChange = (direction) => {
    setSelectedMonth((prev) => {
      let newMonth = prev + direction;
      let newYear = selectedYear;
  
      if (newMonth > 12) {
        newMonth = 1;
        newYear++;
      } else if (newMonth < 1) {
        newMonth = 12;
        newYear--;
      }
  
      setSelectedYear(newYear);
      return newMonth;
    });
  };
  
  const getBarOptionTopFive = (data, color) => ({
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: { includeZero: true },
    data: [
      {
        type: "bar",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: data.map((entry) => ({
          label: entry.contributor,
          y: entry.count,
          color,
        })),
      },
    ],
  });
  
  // const handleYearChange = (chartType, direction) => {
  //   if (chartType === "SOP") {
  //     setSelectedYearSop((prev) => prev + direction);
  //   } else if (chartType === "FAQ") {
  //     setSelectedYearFaq((prev) => prev + direction);
  //   } else if (chartType === "GUIDES") {
  //     setSelectedYearGuide((prev) => prev + direction);
  //   }
  // };
  
  const topContributorsCharts = [
    {
      title: "SOP",
      data: topSopContributors,
      color: "#D6BBFB",
      year: selectedYearSop,
    },
    {
      title: "FAQ",
      data: topFaqContributors,
      color: "#B9E6FE",
      year: selectedYearFaq,
    },
    {
      title: "GUIDES",
      data: topGuideContributors,
      color: "#FFD700",
      year: selectedYearGuide,
    },
  ];
  
  const handleChangeTopFive = (event, newValue) => {
    setActiveTabTopFive(newValue);
  };
  
  const renderTopContributorsChart = (chart) => (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      p={1}
      key={chart.title}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            mb: 1,
            ml: 1,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          <AiOutlineDotChart />
          <span> Top 5 {chart.title} Contributors</span>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
          <IconButton onClick={() => handleMonthChange(-1)}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
            {new Date(selectedYear, selectedMonth - 1).toLocaleString("default", { month: "long" })} {selectedYear}
          </Typography>
          <IconButton onClick={() => handleMonthChange(1)}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      <CanvasJSChart
        options={getBarOptionTopFive(chart.data, chart.color)}
        containerProps={{ width: "100%", height: "400px" }}
      />
    </Grid>
  );
  // ==================Top 5 Contribution chart code End =================
  return (
    <>
     <Box sx={{ transform: 'scale(0.9)', transformOrigin: 'top left', width: '111%' }}>
      <Grid container sx={{background:"#F0F9FF"}}>
        <Grid item lg={8} md={8} sm={8} xs={8} p={1}>
          <Grid container gap={0} sx={{justifyContent:"space-between", }}>
            <Grid
              item
              lg={5.9}
              md={5.9}
              sm={12}
              xs={12}
              p={1}
              sx={{
                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                borderRadius: "10px",
                background:"#FDFDFD",
                // border:"solid 1px black"
                border:"solid 1px #dbdbd9"
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  ml: 1,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                <PiGraduationCapLight />
                <span> Contents</span>
              </Typography>
              <Grid container gap={0.6} sx={{justifyContent:"space-around", alignItems:"center"}}>
                <Grid
                  item
                  lg={5.9}
                  md={5.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#7CD4FD",

                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <FaRegHandshake
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "12%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "100px", // Adjust size as needed
                      opacity: 0.2, // Adjust opacity as needed
                    }}
                  />
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Total Contributions
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                  >
                    {+allContributions.length}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={5.9}
                  md={5.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#E9D7FE",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <VscMilestone
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "12%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "100px", // Adjust size as needed
                      opacity: 0.2, // Adjust opacity as needed
                    }}
                  />
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>SOP</Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                  >
                    {+allSop.length}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={5.9}
                  md={5.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#A6F4C5",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <FcFaq
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "12%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "100px", // Adjust size as needed
                      opacity: 0.3, // Adjust opacity as needed
                    }}
                  />
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>FAQ</Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                  >
                    {+allFaq.length}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={5.9}
                  md={5.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#B9E6FE",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <TbReportAnalytics
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "12%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "100px", // Adjust size as needed
                      opacity: 0.2, // Adjust opacity as needed
                    }}
                  />
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    GUIDES
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                  >
                    {+allGuides.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5.9}
              md={5.9}
              sm={12}
              xs={12}
              p={1}
              sx={{ 
                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                borderRadius: "10px",
                background:"#FDFDFD",
                // border:"solid 1px black"
                border:"solid 1px #dbdbd9"
               }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  ml: 1,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                <PiGraduationCapLight />
                <span> User Access</span>
              </Typography>
              <Grid container gap={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#7CD4FD",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                       <MdGroups 
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "12%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "100px", // Adjust size as needed
                      opacity: 0.2, // Adjust opacity as needed
                    }}
                  />
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Total Users
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                  >
                    {+allUsers}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={5.5}
                  md={5.5}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#FFFF",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>SOP</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    {/* For Assigned */}
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserPlus color="green" opacity={0.6} />
                      <span style={{ color: "grey" }}> Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+uniqueUsersForSop.size}
                    </Typography>
                  </Box>
                  {/* For not assigned */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserX color="red" opacity={0.6} />
                      <span style={{ color: "grey" }}> Not Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+allUsers - uniqueUsersForSop.size}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#FFFF",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>FAQ</Typography>

                  {/* For Assigned */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserPlus color="green" opacity={0.6} />
                      <span style={{ color: "grey" }}> Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+uniqueUsersForFaq.size}
                    </Typography>
                  </Box>
                  {/* For not assigned */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserX color="red" opacity={0.6} />
                      <span style={{ color: "grey" }}> Not Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+allUsers - uniqueUsersForFaq.size}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={5.5}
                  md={5.5}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    background: "#FFFF",
                    textAlign: "center",
                    borderRadius: "15px",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    GUIDES
                  </Typography>

                  {/* For Assigned */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserPlus color="green" opacity={0.6} />
                      <span style={{ color: "grey" }}> Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+uniqueUsersForGuide.size}
                    </Typography>
                  </Box>
                  {/* For not assigned */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      <FiUserX color="red" opacity={0.6} />
                      <span style={{ color: "grey" }}> Not Assigned</span>
                    </Typography>{" "}
                    <Typography sx={{ fontSize: "20px" }}>
                      {+allUsers - uniqueUsersForGuide.size}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

           <Grid item lg={4} md={4} sm={4} xs={4} p={1}>
            <Grid item lg={12} md={12} sm={12} xs={12} p={1} sx={{ background:"#FDFDFD", borderRadius: "10px", border:"solid 1px #dbdbd9"}}>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  ml: 2,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                <GiProgression />
                <span> Status</span>
              </Typography>

              <Grid
                container
                sx={{
                  textAlign: "center",
                  // border:"solid 1px black",
                  // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 3,
                  paddingButtom: 1,
                  alignItems:"center",
                  height:270
                }}
                gap={0.4}
                justifyContent={"center"}
              >
                <Grid
                  item
                  lg={3.9}
                  md={3.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    textAlign: "center",
                    // border:"solid 1px black",
                     border:"solid 1px #dbdbd9",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    borderRadius: 3,
                    padding: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height:270
                  }}
                >
                  <ReactApexChart
                    options={chartOptionsForApprove.options}
                    series={chartOptionsForApprove.series}
                    type="radialBar"
                    height={170}
                    width={150}
                  />
                  <Typography
                    sx={{ mt: -2, fontWeight: "bold", textAlign: "center", fontSize:"15px", color:"grey" }}
                  >
                    Approved
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={3.9}
                  md={3.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    textAlign: "center",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    border:"solid 1px #dbdbd9",
                    borderRadius: 3,
                    padding: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // border:"solid 1px black",
                    height:270
                  }}
                >
                  <ReactApexChart
                    options={chartOptionsForPending.options}
                    series={chartOptionsForPending.series}
                    type="radialBar"
                    height={170}
                    width={150}
                  />
                  <Typography
                    sx={{ mt: -2, fontWeight: "bold", textAlign: "center", fontSize:"15px", color:"grey" }}
                  >
                    Pending
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3.9}
                  md={3.9}
                  sm={12}
                  xs={12}
                  p={1}
                  sx={{
                    textAlign: "center",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    border:"solid 1px #dbdbd9",
                    borderRadius: 3,
                    padding: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // border:"solid 1px black",
                    height:270
                  }}
                >
                  <ReactApexChart
                    options={chartOptionsForReject.options}
                    series={chartOptionsForReject.series}
                    type="radialBar"
                    height={170}
                    width={150}
                  />
                  <Typography sx={{ mt: -2, fontWeight: "bold", fontSize:"15px", color:"grey" }}>
                    Rejected
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
       
       
        </Grid>

      <Grid container sx={{justifyContent:"space-around", }}>
      <Grid item xs={12} md={3.9} p={1} sx={{  background:"#FDFDFD", border:"solid 1px #dbdbd9", borderRadius:"10px", }}>
      <Grid item lg={12} md={12} sm={12} xs={12} p={1}>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  ml: 2,
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                <GiProgression />
                <span> Contribution</span>
              </Typography>
                  <CanvasJSChart
                    options={pieOption}
                    /* onRef={ref => this.chart = ref} */
                    // containerProps={{ width: "20vw", height: "30vh" }}
                  />
            </Grid>
  </Grid>
  <Grid item xs={12} md={3.9} p={1} sx={{  background:"#FDFDFD", border:"solid 1px #dbdbd9", borderRadius:"10px"}} >
    <Grid container>
    <Grid item xs={12}>
  <Tabs
    value={activeTab}
    onChange={handleChange}
    TabIndicatorProps={{ style: { display: 'none' } }} // Removes the underline
    sx={{
      borderRadius: '8px', // Apply border-radius to the entire Tabs container
      backgroundColor: '#FDFDFD', // Light grey background for the Tabs container
      p: 1, // Padding for the Tabs container
    }}
  >
    <Tab
      label="SOP"
      sx={{
        backgroundColor: activeTab === 0 ? '#37668F' : '#e0e0e0', // Active and inactive colors
        color: activeTab === 0 ? 'white' : 'black', // Ensure text color changes for the active tab
        fontSize: '1.2rem',
        boxShadow: activeTab === 0 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none', // Shadow on active tab
        borderRadius: '8px', // Border-radius for the tab
        mx: 0.5, // Margin between tabs
        minWidth: '120px', // Ensures tabs have a minimum width
        '&.Mui-selected': {
          backgroundColor: '#37668F', // Ensures the selected tab has the correct background color
          color: 'white', // Ensures the selected tab has the correct text color
        },
      }}
    />
    <Tab
      label="FAQ"
      sx={{
        backgroundColor: activeTab === 1 ? '#37668F' : '#e0e0e0',
        color: activeTab === 1 ? 'white' : 'black',
        fontSize: '1.2rem',
        boxShadow: activeTab === 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
        borderRadius: '8px',
        mx: 0.5,
        minWidth: '120px',
        '&.Mui-selected': {
          backgroundColor: '#37668F',
          color: 'white',
        },
      }}
    />
    <Tab
      label="GUIDES"
      sx={{
        backgroundColor: activeTab === 2 ? '#37668F' : '#e0e0e0',
        color: activeTab === 2 ? 'white' : 'black',
        fontSize: '1.2rem',
        boxShadow: activeTab === 2 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
        borderRadius: '8px',
        mx: 0.5,
        minWidth: '120px',
        '&.Mui-selected': {
          backgroundColor: '#37668F',
          color: 'white',
        },
      }}
    />
  </Tabs>
</Grid>


      <Grid item xs={12}>
        {renderChart(charts[activeTab])}
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12} md={3.9} p={1} sx={{ background:"#FDFDFD", border:"solid 1px #dbdbd9", borderRadius:"10px"}}>
    <Grid container>
    <Grid item xs={12}>
  <Tabs
    value={activeTabTopFive}
    onChange={handleChangeTopFive}
    TabIndicatorProps={{ style: { display: 'none' } }} // Removes the underline
    sx={{
      borderRadius: '8px', // Apply border-radius to the entire Tabs container
      backgroundColor: '#FDFDFD', // Light grey background for the Tabs container
      p: 1, // Padding for the Tabs container
    }}
  >
    <Tab
      label="Top SOP"
      sx={{
        backgroundColor: activeTabTopFive === 0 ? '#37668F' : '#e0e0e0', // Active and inactive colors
        color: activeTabTopFive === 0 ? 'white' : 'black', // Text color based on active state
        fontSize: '1.2rem',
        boxShadow: activeTabTopFive === 0 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none', // Shadow on active tab
        borderRadius: '8px', // Border-radius for the tab
        mx: 0.5, // Margin between tabs
        minWidth: '120px', // Ensures tabs have a minimum width
        '&.Mui-selected': {
          backgroundColor: '#37668F', // Ensures the selected tab has the correct background color
          color: 'white', // Ensures the selected tab has the correct text color
        },
      }}
    />
    <Tab
      label="Top FAQ"
      sx={{
        backgroundColor: activeTabTopFive === 1 ? '#37668F' : '#e0e0e0',
        color: activeTabTopFive === 1 ? 'white' : 'black',
        fontSize: '1.2rem',
        boxShadow: activeTabTopFive === 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
        borderRadius: '8px',
        mx: 0.5,
        minWidth: '120px',
        '&.Mui-selected': {
          backgroundColor: '#37668F',
          color: 'white',
        },
      }}
    />
    <Tab
      label="Top GUIDES"
      sx={{
        backgroundColor: activeTabTopFive === 2 ? '#37668F' : '#e0e0e0',
        color: activeTabTopFive === 2 ? 'white' : 'black',
        fontSize: '1.2rem',
        boxShadow: activeTabTopFive === 2 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
        borderRadius: '8px',
        mx: 0.5,
        minWidth: '120px',
        '&.Mui-selected': {
          backgroundColor: '#37668F',
          color: 'white',
        },
      }}
    />
  </Tabs>
</Grid>

      <Grid item xs={12}>
        {renderTopContributorsChart(topContributorsCharts[activeTabTopFive])}
      </Grid>
    </Grid>
  </Grid>
</Grid>
      </Grid>

  

      </Box>
    </>
  );
}

export default DashboardView;
